import React from "react";
import { Form, Input, Button } from "antd";

import { toast } from "react-toastify";
const RejectRequestModal = (props) => {
  const onFinish = (values) => {
    props.setContentModal(false);
    toast.success("Request has been requested!", {
      position: "bottom-right",
    });
  };
  return (
    <>
      <Form
        onFinish={onFinish}
        initialValues={{
          planDescription:
            "lorem ipsum dolor sit amet, lorem ipsum dolor sit amet, consectetur",
        }}
      >
        <Form.Item
          label="Description"
          name="planDescription"
          labelCol={{ span: 24 }}
          wrapperCol={{ span: 24 }}
          rules={[
            {
              required: false,
              message: "Please input your Description!",
            },
          ]}
        >
          <Input.TextArea rows={4} cols={4} />
        </Form.Item>

        <Form.Item className="d-flex justify-content-end">
          <Button type="primary" htmlType="submit" shape="round" size="large">
            Send
          </Button>
        </Form.Item>
      </Form>
    </>
  );
};

export default RejectRequestModal;
