import React from 'react'
import TreatmentPlanContent from '../../components/TreatmentPlans/TreatmentPlanContent';
import TreatmentPlansFilterBar from '../../components/TreatmentPlans/TreatmentPlansFilterBar';

const TreatmentPlanPage = () => {
  return (
    <>
      <TreatmentPlansFilterBar />
      <TreatmentPlanContent />
    </>
  )
}

export default TreatmentPlanPage