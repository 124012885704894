import React, { useState } from 'react';
import { Form, Input, Radio, DatePicker, Select, Button } from "antd";
import { Row, Col } from 'react-bootstrap';
import { ArrowDown, Close } from '../../../constants/svg';
import { toast, ToastContainer } from "react-toastify";

const { Option } = Select;

const DetailSelectEmailToSend = (props) => {
    const [selectedTags, setSelectedTags] = useState([]);
    const onFinish = (values, props) => {
        console.log("Selected Emails: ", values);
        toast.success("Plan has been sent");
        setTimeout(() => {
            props.setOpen(false)
        }, 1000)
    };

    const handleChange = (value) => {
        console.log(`selected ${value}`);
        setSelectedTags(value);
    };

    const options = [
        { label: "John Doe", value: "John Doe" },
        { label: "Jane Smith", value: "Jane Smith" },
        { label: "Bob Johnson", value: "Bob Johnson" },
        { label: "Emily Davis", value: "Emily Davis" },
        { label: "David Lee", value: "David Lee" },
        { label: "Lisa Chen", value: "Lisa Chen" },
        { label: "Tom Wilson", value: "Tom Wilson" },
        { label: "Sara Garcia", value: "Sara Garcia" },
        { label: "Mike Brown", value: "Mike Brown" },
        { label: "Karen Kim", value: "Karen Kim" },
        { label: "Peter Nguyen", value: "Peter Nguyen" },
        { label: "Maria Hernandez", value: "Maria Hernandez" },
        { label: "Steven Kim", value: "Steven Kim" },
        { label: "Rachel Miller", value: "Rachel Miller" },
        { label: "Eric Rodriguez", value: "Eric Rodriguez" },
        { label: "Linda Jackson", value: "Linda Jackson" },
        { label: "Alex Davis", value: "Alex Davis" },
        { label: "Tina Martinez", value: "Tina Martinez" },
        { label: "Chris Thompson", value: "Chris Thompson" },
        { label: "Sarah Park", value: "Sarah Park" },
        { label: "Kevin Chen", value: "Kevin Chen" },
        { label: "Jenny Kim", value: "Jenny Kim" },
        { label: "Brian Lee", value: "Brian Lee" },
        { label: "Michelle Rodriguez", value: "Michelle Rodriguez" },
        { label: "Andrew Lee", value: "Andrew Lee" },
        { label: "Amanda Brown", value: "Amanda Brown" },
        { label: "Mark Davis", value: "Mark Davis" },
        { label: "Ashley Johnson", value: "Ashley Johnson" },
        { label: "Brandon Wilson", value: "Brandon Wilson" },
        { label: "Jessica Martinez", value: "Jessica Martinez" }
    ];

    return (
        <>
            <Form onFinish={onFinish}
                initialValues={{
                    services: ["Bob Johnson", "Jessica Martinez", "Brandon Wilson"]
                }}>
                <Row>
                    <Col lg={12} >
                        <Form.Item
                            label="Patients"
                            name="patients"
                            rules={[
                                {
                                    required: false,
                                    message: "Please input patients",
                                },
                            ]}
                            labelCol={{ span: 24 }}
                            wrapperCol={{ span: 24 }}
                        >
                            <Select
                                mode="tags"
                                style={{
                                    width: '100%',
                                }}
                                placeholder="Select Patients"
                                onChange={handleChange}
                                options={options}
                                suffixIcon={<ArrowDown />}
                            />
                        </Form.Item>
                    </Col>

                    <Form.Item className='d-flex justify-content-end'>
                        <Button type="primary" htmlType="submit" shape="round" size='medium'>
                            Send
                        </Button>
                    </Form.Item>
                </Row>
            </Form>
        </>
    )
}

export default DetailSelectEmailToSend