import { React } from "react";
import { Row, Col } from "react-bootstrap";
import LineGraph from "../components/dashboard/LineGraph";
import Statistics from "../components/dashboard/Statistics";
import { ReactSVG } from 'react-svg';
import nodata from '../constants/nodata.svg'
import FilterBar from "../components/dashboard/FilterBar";

const Dashboard = () => {
  return (
    <>
      <FilterBar />
      <Statistics />
      <Row>
        <Col lg={8}>
          <LineGraph />
        </Col>
        <Col lg={4}>
          <div className="stats-box treatment-wrapper m-0">
            <h3 className='fw-bold'>Terminate Treatment Request</h3>
            <div className="terminate-box">
              <ReactSVG src={nodata} />
              <p className="mt-3 greyColor text-capitalize">No Termination Request!</p>
            </div>
          </div>
        </Col>
      </Row>
    </>
  );
};

export default Dashboard;
