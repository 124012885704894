import React, { useState } from 'react'
import { Form, Button, Input, Select } from 'antd';
import { Row, Col } from 'react-bootstrap';
import { ArrowDown, PlusIcon } from '../../constants/svg';
import { toast, ToastContainer } from "react-toastify";



const EditSubscriptionForm = (props) => {
    const onFinish = (values) => {
        console.log('Success:', values);
        console.log("props", props)
        toast.success("Subscription plan updated", {
            position: "bottom-right",
        });
        props.setContentModal(false)
    };
    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };
    const [fields, setFields] = useState([
        { name: 'feature1', value: '1 Location' },
        { name: 'feature2', value: 'Card processing fees' },
        { name: 'feature3', value: 'EzPay Module: Contactless payments via SMS and mobile app' },
    ]);

    const handleAddField = () => {
        setFields([
            ...fields,
            { name: `duration-${fields.length + 1}`, value: '' },
        ]);
    };

    const handleFieldChange = (index, event) => {
        const newFields = [...fields];
        newFields[index].value = event.target.value;
        setFields(newFields);
    };
    return (
        <>
            <ToastContainer />
            <Form
                onFinish={onFinish}
                size={"large"}
                initialValues={{
                    planName: "Starter",
                    duration: "1 Month",
                    price: "$99"
                }}
            >
                <Row>
                    <Col lg={12} sm={12}>
                        <Form.Item
                            label="Plan Name"
                            name="planName"
                            rules={[
                                {
                                    required: true,
                                    message: "Please enter your plan name",
                                },
                            ]}
                            labelCol={{ span: 24 }}
                            wrapperCol={{ span: 24 }}

                        >
                            <Input placeholder="Plan Name" />
                        </Form.Item>
                    </Col>
                    <Col lg={12} sm={12} className="position-relative">
                        <div className="addNewBtn" onClick={handleAddField}>
                            <PlusIcon /> <p className='m-0 blueColor'>Add New</p>
                        </div>
                        {fields.map((field, index) => (
                            <Form.Item
                                key={field.name}
                                label={index === 0 ? 'Features' : ''}
                                name={field.name}
                                initialValue={field.value}
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please enter your feature',
                                    },
                                ]}
                                labelCol={{ span: 24 }}
                                wrapperCol={{ span: 24 }}
                            >
                                <Input
                                    placeholder="Enter Feature"
                                    onChange={(event) => handleFieldChange(index, event)}
                                />
                            </Form.Item>
                        ))}

                    </Col>
                    <Col lg={12} sm={12}>
                        <Form.Item
                            label="Duration"
                            name="duration"
                            rules={[
                                {
                                    required: true,
                                    message: "Please enter your duration",
                                },
                            ]}
                            labelCol={{ span: 24 }}
                            wrapperCol={{ span: 24 }}

                        >
                            <Input placeholder="Enter Duration" />
                        </Form.Item>
                    </Col>
                    <Col lg={12} sm={12}>
                        <Form.Item
                            label="Price"
                            name="price"
                            rules={[
                                {
                                    required: true,
                                    message: "Please enter your price",
                                },
                            ]}
                            labelCol={{ span: 24 }}
                            wrapperCol={{ span: 24 }}

                        >
                            <Input placeholder="Enter Price" />
                        </Form.Item>
                    </Col>
                    <Col lg={12} sm={12}>
                        <Form.Item className='d-flex justify-content-end'>
                            <Button type="primary" htmlType="submit" shape="round" size='large'>
                                Update
                            </Button>
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </>
    )
}

export default EditSubscriptionForm