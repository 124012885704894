import React from 'react'
import BillingHistoryForm from './BillingHistoryForm'
import BillingProgressSection from './BillingProgressSection'
import PaymentScheduleTable from './PaymentScheduleTable'

const BillingHistorySection = () => {
    return (
        <>
            <BillingHistoryForm />
            <BillingProgressSection />
            <PaymentScheduleTable />
        </>
    )
}

export default BillingHistorySection