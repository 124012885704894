import React, { useState } from 'react';
import { Table, Avatar, Button, Dropdown, Tag, Checkbox, Form, Input, Select, TimePicker, Switch } from 'antd';
import { Assets } from "../../constants/images";
import { UserIcon } from '../../constants/svg';


//Action dropdown menu list 
const items = [
    {
        key: '1',
        label: (
            <p className='m-0'>
                1st menu item
            </p>

        ),
    },
    {
        key: '2',
        label: (
            <p className='m-0'>
                2nd menu item
            </p>
        ),
    },
    {
        key: '3',
        label: (
            <p className='m-0' target="_blank" rel="noopener noreferrer" href="https://www.luohanacademy.com">
                3rd menu item
            </p>
        ),
    },
];

const columns = [
    {
        title: '',
        width: 70,
        dataIndex: 'avatar',
        key: 'avatar',
        fixed: 'left',
        align: 'center',
        render: (avatarUrl) => (
            <Avatar src={avatarUrl} />
        ),
    },
    {
        title: 'Client Name',
        width: 100,
        dataIndex: 'clientName',
        key: 'clientName',
    },
    {
        title: 'Treatment Plan',
        dataIndex: 'treatmentPlan',
        key: 'treatmentPlan',
        width: 150,
        sorter: (a, b) => a.treatmentPlan.localeCompare(b.treatmentPlan),
    },
    {
        title: 'Email ID',
        dataIndex: 'email',
        key: 'email',
        width: 180,
        sorter: (a, b) => a.email.localeCompare(b.email),
    },
    {
        title: 'Financing Plan',
        dataIndex: 'financingPlan',
        key: 'financingPlan',
        width: 150,
        sorter: (a, b) => a.financingPlan.localeCompare(b.financingPlan),
    },
    {
        title: 'Payment Plan',
        dataIndex: 'paymentPlan',
        key: 'paymentPlan',
        width: 150,
        sorter: (a, b) => a.paymentPlan.localeCompare(b.paymentPlan),
    },
    {
        title: 'No. of Session',
        dataIndex: 'noOfSession',
        key: 'noOfSession',
        width: 150,
        sorter: (a, b) => a.noOfSession - b.noOfSession,
    },
    {
        title: 'Duration',
        dataIndex: 'duration',
        key: 'duration',
        width: 150,
        sorter: (a, b) => a.duration - b.duration,
    },
    {
        title: 'Interest Rate',
        dataIndex: 'interestRate',
        key: 'interestRate',
        width: 150,
        sorter: (a, b) => a.interestRate - b.interestRate,
    },
    {
        title: 'Total Fees',
        dataIndex: 'totalFees',
        key: 'totalFees',
        width: 150,
        sorter: (a, b) => a.totalFees - b.totalFees,

    },
    {
        title: 'Proposal Status',
        dataIndex: 'proposalStatus',
        key: 'proposalStatus',
        width: 150,
        sorter: (a, b) => a.financingPlan.localeCompare(b.financingPlan),
        render: (proposalStatus) => (
            <span>
                {proposalStatus.map((tag) => {
                    let color = tag.toLowerCase() === 'sent' ? 'blue' :
                        tag.toLowerCase() === 'created' ? 'orange' :
                            tag.toLowerCase() === 'sales rep created' ? 'geekblue' :
                                tag.toLowerCase() === 'lead converted' ? 'green' :
                                    tag.toLowerCase() === 'signed' ? 'cyan' :
                                        tag.toLowerCase() === 'accepted' ? 'purple' :
                                            tag.toLowerCase() === 'seen' ? 'magenta' : 'blue';
                    return (
                        <Tag color={color} key={tag} className='mb-2 text-capitalize fw-bold'>
                            {tag}
                        </Tag>
                    );
                })}
            </span>
        ),
    },
    {
        title: 'Action',
        key: 'operation',
        fixed: 'right',
        width: 90,
        align: 'center',
        render: () =>
            <Dropdown
                menu={{
                    items,
                }}
                trigger={['click']}
                placement="bottomRight"
            >
                <Button className='dotted-btn'><UserIcon /></Button>
            </Dropdown>
    },
    ,
];
const data = [
    {
        key: '1',
        avatar: Assets.PatientImg,
        address: 'New York No. 1 Lake Park',
        clientName: 'Client Name',
        treatmentPlan: 'Treatment Plan',
        email: 'efpyi@example.com',
        financingPlan: 'Financing Plan',
        paymentPlan: 'Payment Plan',
        noOfSession: '1',
        duration: '1',
        interestRate: '1',
        totalFees: '1',
        proposalStatus: ['Created', 'Sent', 'Sales Rep Created', 'Lead Converted', 'Signed', 'Accepted', 'Seen'],
        operation: <a>action</a>,
    },
    {
        key: '2',
        avatar: Assets.AvatarTwo,
        address: 'New York No. 1 Lake Park',
        clientName: 'Client Name',
        treatmentPlan: 'abc',
        email: 'efpyi@example.com',
        financingPlan: 'Financing Plan',
        paymentPlan: 'Payment Plan',
        noOfSession: '4',
        duration: '5',
        interestRate: '18',
        totalFees: '1',
        proposalStatus: ['Created'],
        operation: <a>action</a>,
    },
    {
        key: '3',
        avatar: Assets.AvatarOne,
        address: 'New York No. 1 Lake Park',
        clientName: 'Client Name',
        treatmentPlan: 'Treatment Plan',
        email: 'efpyi@example.com',
        financingPlan: 'Financing Plan',
        paymentPlan: 'Payment Plan',
        noOfSession: '10',
        duration: '9',
        interestRate: '13',
        totalFees: '15',
        proposalStatus: ['crea', 'created', 'Nabeel'],
        operation: <a>action</a>,
    }
];

const onFinish = (values) => {
    console.log('Success:', values);
};
const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
};

const validateZip = (rule, value, callback) => {
    const regex = /^[0-9]+$/;
    if (regex.test(value)) {
        callback();
    } else {
        callback("Please enter a numerical value");
    }
};

const validatePhoneNumber = (rule, value, callback) => {
    const regex = /^\s*(?:\+?(\d{1,3}))?[- (]*(\d{3})[- )]*(\d{3})[- ]*(\d{4})(?: *[x/#]{1}(\d+))?\s*$/;
    if (regex.test(value)) {
        callback();
    } else {
        callback("Please enter a valid phone number!");
    }
};

const { Option } = Select;


const Test = () => {
    const [selectedTeamMembers, setSelectedTeamMembers] = useState([]);
    const handleTeamMemberChange = (value) => {
        setSelectedTeamMembers(value);
    };

    return (
        <>

            <Table
                rowSelection={{
                    type: 'checkbox',
                }}
                // size={'medium'}
                columns={columns}
                dataSource={data}
                scroll={{
                    x: 1500,
                    y: 300
                }}
                bordered={false}
                pagination={{ pageSize: 8 }}
            />
            <div className="container">
                <Form
                    onFinish={onFinish}
                    size={"large"}
                >
                    <Form.Item
                        label="Location Name"
                        name="locationName"
                        rules={[
                            {
                                required: true,
                                message: "Please input location name!",
                            },
                        ]}
                        labelCol={{ span: 24 }}
                        wrapperCol={{ span: 24 }}
                    >
                        <Input />
                    </Form.Item>

                    <Form.Item
                        label="Address"
                        name="address"
                        rules={[
                            {
                                required: true,
                                message: "Please input address!",
                            },
                        ]}
                        labelCol={{ span: 24 }}
                        wrapperCol={{ span: 24 }}
                    >
                        <Input />
                    </Form.Item>

                    <Form.Item
                        label="Zip"
                        name="zip"
                        rules={[
                            {
                                required: true,
                                message: "",
                            },
                            {
                                validator: validateZip,
                            },
                        ]}
                        labelCol={{ span: 24 }}
                        wrapperCol={{ span: 24 }}
                    >
                        <Input />
                    </Form.Item>

                    <Form.Item
                        label="City"
                        name="city"
                        rules={[
                            {
                                required: true,
                                message: "Please input city name!",
                            },
                        ]}
                        labelCol={{ span: 24 }}
                        wrapperCol={{ span: 24 }}
                    >
                        <Input />
                    </Form.Item>

                    <Form.Item
                        label="State"
                        name="state"
                        rules={[
                            {
                                required: true,
                                message: "Please input state name!",
                            },
                        ]}
                        labelCol={{ span: 24 }}
                        wrapperCol={{ span: 24 }}
                    >
                        <Input />
                    </Form.Item>

                    <Form.Item
                        label="Country"
                        name="country"
                        rules={[
                            {
                                required: true,
                                message: "Please input country name!",
                            },
                        ]}
                        labelCol={{ span: 24 }}
                        wrapperCol={{ span: 24 }}
                    >
                        <Input />
                    </Form.Item>

                    <Form.Item
                        label="Phone Number"
                        name="phoneNumber"
                        rules={[
                            {
                                required: true,
                                message: "",
                            },
                            {
                                validator: validatePhoneNumber,
                            },
                        ]}
                        labelCol={{ span: 24 }}
                        wrapperCol={{ span: 24 }}
                    >
                        <Input />
                    </Form.Item>

                    <Form.Item
                        label="Select Team Members"
                        name="teamMembers"
                        rules={[
                            {
                                required: true,
                                message: "Please select team members!",
                            },
                        ]}
                        labelCol={{ span: 24 }}
                        wrapperCol={{ span: 24 }}
                    >
                        <Select
                            mode="multiple"
                            style={{ width: "100%" }}
                            placeholder="Please select team members"
                            onChange={handleTeamMemberChange}
                            value={selectedTeamMembers}
                        >
                            <Option value="teamMember1">Team Member 1</Option>
                            <Option value="teamMember2">Team Member 2</Option>
                            <Option value="teamMember3">Team Member 3</Option>
                        </Select>
                    </Form.Item>

                    <Form.Item
                        label="Start Time"
                        name="startTime"
                        labelCol={{ span: 24 }}
                        wrapperCol={{ span: 24 }}
                    >
                        <TimePicker use12Hours format="h:mm a" />
                    </Form.Item>

                    <Form.Item
                        label="End Time"
                        name="endTime"
                        labelCol={{ span: 24 }}
                        wrapperCol={{ span: 24 }}
                    >
                        <TimePicker use12Hours format="h:mm a" />
                    </Form.Item>

                    <Form.Item
                        label="Weekly Time"
                        name="weeklyTime"
                        valuePropName="checked"
                        labelCol={{ span: 24 }}
                        wrapperCol={{ span: 24 }}
                    >
                        <Switch />
                    </Form.Item>


                    <Form.Item>
                        <Button type="primary" htmlType="submit">
                            Create Location
                        </Button>
                    </Form.Item>
                </Form>
            </div>
        </>
    )
}

export default Test