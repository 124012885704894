import React from "react";
import {
  BrowserRouter,
  Routes,
  Route,
} from "react-router-dom";
import Dashboard from "../pages/Dashboard";
import NewComponent from "../auth/components/NewComponent";
import Signin from "../auth/components/NewComponents/Signin";
import ForgotPass from "../auth/components/NewComponents/ForgotPass";
import Accountotp from "../auth/components/NewComponents/Accountotp";
import Passwordconfirm from "../auth/components/NewComponents/Passwordconfirm";
import Settings from "../pages/Settings/Settings";
import MyProfile from "../components/Settings/MyProfile";
import ChangePassword from "../components/Settings/ChangePassword";
import DeleteAccount from "../components/Settings/DeleteAccount";
import DashboardLayout from "../pages/DashboardLayout";
import UserManagement from "../pages/UserManagement/UserManagement";
import AppointmentPage from "../pages/Appointment/AppointmentPage";
import RoleManagement from "../pages/RoleManagement/RoleManagement";


import MarketingPage from "../pages/Marketing/MarketingPage";
import FinancingPage from "../pages/Financing/FinancingPage";
import Administration from "../pages/Administration/Administration.jsx";
import Content from "../pages/ContentManagement/Content";
import TermsandCondition from "../components/contents/TermsandCondition";
import ContentManagement from "../pages/ContentManagement/Content";
import PrivacyPolicy from "../components/contents/PrivacyPolicy";
import Faq from "../components/contents/Faq";



import ProposalProfile from "../pages/Marketing/ProposalProfile";

import Search from "../pages/search/Search"
import Test from "../pages/Test/Test";
import UserAndLocationPage from "../pages/UserAndLocation/UserAndLocationPage";
import PatientDetailPage from "../pages/UserAndLocation/PatientDetail/PatientDetailPage";
import LocationDetailPage from "../pages/UserAndLocation/LocationDetail/LocationDetailPage";
import { DiscountOffer } from "../constants/sidebarIcons";
import DiscountOfferPage from "../pages/DiscountOffers/DiscountOffersPage";
import OfferDetailPage from "../pages/DiscountOffers/DiscountOfferDetail/OfferDetailPage";
import AgreementPage from "../pages/Agreement/AgreementPage";
import FinancingPlanPage from "../pages/FinancingPlan/FinancingPlanPage";
import SubscriptionPlansPage from "../pages/SubscriptionPlans/SubscriptionPlansPage";
import PaymentPlanPage from "../pages/PaymentPlan/PaymentPlanPage";
import TreatmentPlanPage from "../pages/TreatmentPlan/TreatmentPlanPage";
import PatientPage from "../pages/Patients/PatientPage";
import PatientInfoDetailPage from "../pages/Patients/PatientInfoDetail/PatientInfoDetailPage";
import AddMemeberPage from "../pages/Patients/PatientInfoDetail/AddMemeberPage";
import PayersPage from "../pages/EzPay/PayersPage";
import CreateProposal from "../pages/Marketing/CreateProposal";
import TreatmentPlanTemplate from "../pages/TreatmentPlan/TreatmentPlanTemplate";
import SignUpStepForm from "../auth/SignUp/SignUpStepForm";
import LeadsPage from "../pages/Leads/LeadsPage";
import ThankyouPage from "../pages/ThankyouPage/ThankyouPage";
import LocationPage from "../pages/Locations/LocationPage";
import LocationsDetailPage from "../pages/Locations/LocationsDetailPage";
import MembershipPlanPage from "../pages/MembershipPlan/MembershipPlanPage";
import MembershipPlanDetailPage from "../pages/MembershipPlanDetail/MembershipPlanDetailPage";

const Navigation = () => {
  return (
    <BrowserRouter>
      <Routes>
        {/* SignIn Flow */}
        <Route path="/" element={<NewComponent heading="Sign in" description="Let's get you in to the app"><Signin /></NewComponent>} />
        <Route path="/forgotpassword" element={<NewComponent heading="Forgot Password" description="Enter email address to receive a 4 digit code for verification"><ForgotPass /></NewComponent>} />
        <Route path="/verifyaccount" element={<NewComponent heading="Account Verification" description="Please enter the OTP you received <br /> to sam.marsh@gmail.com"><Accountotp /></NewComponent>} />
        <Route path='/passwordconfirm' element={<NewComponent heading='Create New Password' description='Your new password must be different from the<br /> previously used passwords.'><Passwordconfirm /></NewComponent>} />
        {/* SignIn Flow End */}

        {/* SignUp Flow Start */}
        <Route path="/signup" exact element={<SignUpStepForm />} />
        {/* SignUp Flow End */}



        {/* Dashboard Layout */}
        <Route path="/dashboard" element={<DashboardLayout pagetitle="Dashboard"><Dashboard /></DashboardLayout>} />
        <Route path="/marketing" element={<DashboardLayout><MarketingPage /></DashboardLayout>} />
        <Route path="/treatment-template" element={<DashboardLayout><TreatmentPlanTemplate /></DashboardLayout>} />
        <Route path="/marketing/create-proposal" element={<DashboardLayout><CreateProposal /></DashboardLayout>} />
        <Route path="/agreement" element={<DashboardLayout><AgreementPage /></DashboardLayout>} />
        <Route path="/discount-offers" element={<DashboardLayout><DiscountOfferPage /></DashboardLayout>} />
        <Route path="/discount-offers/offer-detail" element={<DashboardLayout><OfferDetailPage /></DashboardLayout>} />
        <Route path="/user-and-location" element={<DashboardLayout><UserAndLocationPage /></DashboardLayout>} />
        <Route path="/user-and-location/patient-detail" element={<DashboardLayout><PatientDetailPage /></DashboardLayout>} />
        <Route path="/user-and-location/location-detail" element={<DashboardLayout><LocationDetailPage /></DashboardLayout>} />
        <Route path="/marketing/proposalprofile" element={<DashboardLayout><ProposalProfile /></DashboardLayout>} />
        <Route path="/patients" element={<DashboardLayout><PatientPage /></DashboardLayout>} />
        <Route path="/patients/patient-detail" element={<DashboardLayout><PatientInfoDetailPage /></DashboardLayout>} />
        <Route path="/patients/patient-detail/add-member" element={<DashboardLayout><AddMemeberPage /></DashboardLayout>} />
        <Route path="/membership-plan" element={<DashboardLayout><MembershipPlanPage /></DashboardLayout>} />
        <Route path="/membership-detail" element={<DashboardLayout><MembershipPlanDetailPage /></DashboardLayout>} />
        {/* Plans Tabs Start */}
        <Route path="/financing-plans" element={<DashboardLayout><FinancingPlanPage /></DashboardLayout>} />
        <Route path="/subscription-plans" element={<DashboardLayout><SubscriptionPlansPage /></DashboardLayout>} />
        <Route path="/payment-plans" element={<DashboardLayout><PaymentPlanPage /></DashboardLayout>} />
        <Route path="/treatment-plans" element={<DashboardLayout><TreatmentPlanPage /></DashboardLayout>} />
        <Route path="/leads" element={<DashboardLayout><LeadsPage /></DashboardLayout>} />
        <Route path="/location" element={<DashboardLayout><LocationPage /></DashboardLayout>} />
        <Route path="/locations/location-detail" element={<DashboardLayout><LocationsDetailPage /></DashboardLayout>} />
        {/* Plans Tabs Ends */}
        {/* EzPay Tab Starts */}
        <Route path="/ezpay/payers" element={<DashboardLayout><PayersPage /></DashboardLayout>} />
        {/* EzPay Tab End */}
        <Route path="/test" element={<DashboardLayout pagetitle="Test"><Test /></DashboardLayout>} />
        <Route path="/settings/myprofile" element={<DashboardLayout pagetitle="Settings"><Settings><MyProfile /></Settings></DashboardLayout>} />
        <Route path="/settings/changepassword" element={<DashboardLayout pagetitle="Settings"><Settings><ChangePassword /></Settings></DashboardLayout>} />
        <Route path="/settings/deleteaccount" element={<DashboardLayout pagetitle="Settings"><Settings><DeleteAccount /></Settings></DashboardLayout>} />
        <Route path="/usermanagement" element={<DashboardLayout><UserManagement /></DashboardLayout>} />
        <Route path="/appointment" element={<DashboardLayout><AppointmentPage /></DashboardLayout>} />
        <Route path="/rolemanagement" element={<DashboardLayout><RoleManagement /></DashboardLayout>} />
        {/* <Route path="/paymentplan" element={<DashboardLayout><PaymentPlan /></DashboardLayout>} /> */}

        <Route path="/Financing" element={<DashboardLayout><FinancingPage /></DashboardLayout>} />
        <Route path="/administration" element={<DashboardLayout><Administration /></DashboardLayout>} />
        <Route path="/search" element={<DashboardLayout><Search /></DashboardLayout>} />
        <Route path="/content/privacypolicy" element={<DashboardLayout pagetitle="Content Management"><Content><PrivacyPolicy /></Content></DashboardLayout>} />

        {/* Thankyou Page  */}
        <Route path="/thanks" exact element={<ThankyouPage />} />
      </Routes>
    </BrowserRouter>
  )

};

export default Navigation;
