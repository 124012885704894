import { useState } from "react";
import dayjs from 'dayjs';
import { Form, Input, Radio, DatePicker, Select, Button } from "antd";
import { Row, Col } from "react-bootstrap";
import { ArrowDown, CameraIcon, Close, GreyCalendar } from "../../../../constants/svg";
import { Assets } from "../../../../constants/images";
import 'dayjs/locale/en';


const { Option } = Select;

const dateFormat = 'DD/MM/YYYY';

const validateZip = (rule, value, callback) => {
    const regex = /^[0-9]+$/;
    if (regex.test(value)) {
        callback();
    } else {
        callback("Please enter a numerical value");
    }
};

const validatePhoneNumber = (rule, value, callback) => {
    const regex = /^\s*(?:\+?(\d{1,3}))?[- (]*(\d{3})[- )]*(\d{3})[- ]*(\d{4})(?: *[x/#]{1}(\d+))?\s*$/;
    if (regex.test(value)) {
        callback();
    } else {
        callback("Please enter a valid phone number!");
    }
};


const AddMemberForm = () => {
    const [patientData, setPatientData] = useState({});
    const [image, setImage] = useState('');

    const handleImageUpload = (event) => {
        const file = event.target.files[0];
        const reader = new FileReader();

        reader.onload = () => {
            setImage(reader.result);
        };

        reader.readAsDataURL(file);
    };
    const onFinish = (values) => {
        setPatientData(values);
        console.log("Patient Data: ", values);
    };

    const [selectedState, setselectedState] = useState('New York');
    const handleStateChange = (value) => {
        setselectedState(value);
    };

    return (
        <Form onFinish={onFinish}
            initialValues={{
                patientId: '01',
                gender: 'male',
                email: "joedoe@gmail.com",
                dob: dayjs('26/03/2021', dateFormat),
                patientName: 'Paul Doe',
                height: '165',
                occupation: 'Designer',
                weight: '65',
                phoneNumber: '+01-34885485',
                address: 'Street 2, New york city',
                zip: '0000',
                city: 'New York',
                state: 'New York',
                country: 'United States',
                fullName: 'John Doe',
                mobile: '+01-34885485'
            }}>
            <Row>
                <Col lg={12} >
                    <Form.Item>
                        <div className='profile-title'>
                            <label htmlFor='image-upload' className='image-holder'>
                                <div className="upload-img-box">
                                    {image ? (
                                        <img src={image} alt='Profile' />
                                    ) : (
                                        <img src={Assets.AvatarSetting} alt='Profile' />
                                    )}
                                    <div className="cameraIcon">
                                        <CameraIcon />
                                    </div>
                                </div>
                            </label>
                            <b className='profile-name ms-2'>Alex Joe</b>
                            <input
                                id='image-upload'
                                type='file'
                                accept='image/*'
                                style={{ display: 'none' }}
                                onChange={handleImageUpload}
                            />
                        </div>
                    </Form.Item>
                </Col>
                <Col lg={6} sm={12}>
                    <Form.Item
                        label="Patient ID"
                        name="patientId"
                        rules={[
                            {
                                required: true,
                                message: "Please input patient ID",
                            },
                        ]}
                        labelCol={{ span: 24 }}
                        wrapperCol={{ span: 24 }}
                    >
                        <Input />
                    </Form.Item>
                </Col>
                <Col lg={6} sm={12}>
                    <Form.Item
                        label="Gender"
                        name="gender"
                        rules={[
                            {
                                required: true,
                                message: "Please select patient gender",
                            },
                        ]}
                        labelCol={{ span: 24 }}
                        wrapperCol={{ span: 24 }}
                    >
                        <Radio.Group>
                            <Radio value="male">Male</Radio>
                            <Radio value="female">Female</Radio>
                            <Radio value="others">Others</Radio>
                        </Radio.Group>
                    </Form.Item>
                </Col>
                <Col lg={6} sm={12}>
                    <Form.Item
                        label="Patient Email ID"
                        name="email"
                        rules={[
                            {
                                required: true,
                                message: "Please input patient email ID",
                            },
                            {

                                type: "email",
                                message: "Please input valid email ID",
                            },
                        ]}
                        labelCol={{ span: 24 }}
                        wrapperCol={{ span: 24 }}
                    >
                        <Input />
                    </Form.Item>
                </Col>
                <Col lg={6} sm={12}>
                    <Form.Item
                        label="Date Of Birth"
                        name="dob"
                        labelCol={{ span: 24 }}
                        wrapperCol={{ span: 24 }}
                        rules={[
                            {
                                required: true,
                                message: "Please select date of birth",
                            },
                        ]}
                    >
                        <DatePicker
                            format={dateFormat}
                            disabledDate={(current) => current && current < dayjs('1900-01-01')}
                            suffixIcon={<GreyCalendar />}
                            iconRender={(current) => (current ? <GreyCalendar /> : '')}
                        />
                    </Form.Item>
                </Col>
                <Col lg={6} sm={12}>
                    <Form.Item
                        label="Patient Name"
                        name="patientName"
                        rules={[
                            {
                                required: true,
                                message: "Please input patient name",
                            },
                        ]}
                        labelCol={{ span: 24 }}
                        wrapperCol={{ span: 24 }}
                    >
                        <Input />
                    </Form.Item>
                </Col>
                <Col lg={6} sm={12}>
                    <Form.Item
                        label="Height (cm)"
                        name="height"
                        rules={[
                            {
                                required: true,
                                message: "Please input patient height",
                            },
                        ]}
                        labelCol={{ span: 24 }}
                        wrapperCol={{ span: 24 }}
                    >
                        <Input type="number" />
                    </Form.Item>
                </Col>
                <Col lg={6} sm={12}>
                    <Form.Item
                        label="Occupation"
                        name="occupation"
                        rules={[
                            {
                                required: true,
                                message: "Please input patient occupation",
                            },
                        ]}
                        labelCol={{ span: 24 }}
                        wrapperCol={{ span: 24 }}
                    >
                        <Input />
                    </Form.Item>
                </Col>
                <Col lg={6} sm={12}>
                    <Form.Item
                        label="Weight (kg)"
                        name="weight"
                        rules={[
                            {
                                required: true,
                                message: "Please input patient weight",
                            },
                        ]}
                        labelCol={{ span: 24 }}
                        wrapperCol={{ span: 24 }}
                    >
                        <Input type="number" />
                    </Form.Item>
                </Col>
                <Col lg={6} sm={12}>
                    <Form.Item
                        label="Contact Phone"
                        name="phoneNumber"
                        rules={[
                            {
                                required: true,
                                message: "",
                            },
                            {
                                validator: validatePhoneNumber,
                            },
                        ]}
                        labelCol={{ span: 24 }}
                        wrapperCol={{ span: 24 }}
                    >
                        <Input placeholder='Enter Phone' />
                    </Form.Item>
                </Col>
                <Col lg={6} sm={12}>
                    <Form.Item
                        label="Home Address"
                        name="address"
                        rules={[
                            {
                                required: true,
                                message: "Please input address!",
                            },
                        ]}
                        labelCol={{ span: 24 }}
                        wrapperCol={{ span: 24 }}
                    >
                        <Input placeholder="Enter Address" />
                    </Form.Item>
                </Col>
                <Col lg={6} sm={12}>
                    <Form.Item
                        label="Zip"
                        name="zip"
                        rules={[
                            {
                                required: true,
                                message: "",
                            },
                            {
                                validator: validateZip,
                            },
                        ]}
                        labelCol={{ span: 24 }}
                        wrapperCol={{ span: 24 }}
                    >
                        <Input placeholder="Enter ZipCode" />
                    </Form.Item>
                </Col>
                <Col lg={6} sm={12}>
                    <Form.Item
                        label="City"
                        name="city"
                        rules={[
                            {
                                required: true,
                                message: "Please input city name!",
                            },
                        ]}
                        labelCol={{ span: 24 }}
                        wrapperCol={{ span: 24 }}
                    >
                        <Input placeholder="Enter City" />
                    </Form.Item>
                </Col>
                <Col lg={6} sm={12}>
                    <Form.Item
                        label="State"
                        name="state"
                        rules={[
                            {
                                required: true,
                                message: "Please select state!",
                            },
                        ]}
                        labelCol={{ span: 24 }}
                        wrapperCol={{ span: 24 }}
                    >
                        <Select
                            style={{ width: "100%" }}
                            placeholder="Please select state"
                            onChange={handleStateChange}
                            value={selectedState}
                            suffixIcon={<ArrowDown />}
                            removeIcon={<Close />}
                            className={"form-dropdown"}
                        >
                            <Option value="newyork">New York</Option>
                            <Option value="2">State 2</Option>
                            <Option value="3">State 3</Option>
                        </Select>
                    </Form.Item>
                </Col>
                <Col lg={6} sm={12}>
                    <Form.Item
                        label="Country"
                        name="country"
                        rules={[
                            {
                                required: true,
                                message: 'Please select your country!',
                            },
                        ]}

                        labelCol={{ span: 24 }}
                        wrapperCol={{ span: 24 }}
                    >
                        <Select
                            placeholder="Select Country"
                            suffixIcon={<ArrowDown />}
                            removeIcon={<Close />}
                            className={"form-dropdown"}
                        >
                            <Option value="Pakistan">Pakistan</Option>
                            <Option value="India">India</Option>
                            <Option value="USA">USA</Option>
                            <Option value="UK">UK</Option>
                        </Select>
                    </Form.Item>
                </Col>
                <h2 className='fw-bold mb-3'>Emergency Contact</h2>
                <Col lg={6} sm={12}>
                    <Form.Item
                        label="Full Name"
                        name="fullName"
                        rules={[
                            {
                                required: true,
                                message: "Please input full name",
                            },
                        ]}
                        labelCol={{ span: 24 }}
                        wrapperCol={{ span: 24 }}
                    >
                        <Input />
                    </Form.Item>
                </Col>
                <Col lg={6} sm={12}>
                    <Form.Item
                        label="Mobile"
                        name="mobile"
                        rules={[
                            {
                                required: true,
                                message: "",
                            },
                            {
                                validator: validatePhoneNumber,
                            },
                        ]}
                        labelCol={{ span: 24 }}
                        wrapperCol={{ span: 24 }}
                    >
                        <Input placeholder='Enter mobile number' />
                    </Form.Item>
                </Col>

                <Form.Item className='d-flex justify-content-end'>
                    <Button type="primary" htmlType="submit" shape="round" size='large'>
                        Add Member
                    </Button>
                </Form.Item>
            </Row>
        </Form >
    )
}

export default AddMemberForm;