import React, { useState } from 'react'
import { Tabs } from 'antd';
import AgreementFilterBar from '../../components/Agreement/AgreementFilterBar';
import SentAgreementTable from '../../components/Agreement/SentAgreementTable';
import AgreementTable from '../../components/Agreement/AgreementTable';
import ContentModal from '../../components/ContentModal';
import AgreementForm from '../../components/Agreement/AgreementForm';


const AgreementPage = () => {
    const [activeKey, setActiveKey] = useState('1');
    const [contentModal, setContentModal] = useState(false);
    console.log(contentModal, "content modal state")

    const onChange = (key) => {
        setActiveKey(key);
    };

    const items = [
        {
            key: '1',
            label: `Agreement`,
            children: <AgreementTable setContentModal={setContentModal} />,
        },
        {
            key: '2',
            label: `Sent Agreements`,
            children: <SentAgreementTable />,
        }
    ];
    return (
        <>
            <AgreementFilterBar />
            <Tabs defaultActiveKey="1" items={items} onChange={onChange} />
            <ContentModal
                title={"Send To"}
                content={<AgreementForm setContentModal={setContentModal} />}
                blueText={"Send"}
                show={contentModal}
                onHide={() => setContentModal(false)}
            />

        </>
    )
}

export default AgreementPage