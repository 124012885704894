import { React, useState } from "react";
import {
  Container,
  Button,
  Row,
  Col,
  FormControl,
  InputGroup,
} from "react-bootstrap";
import { Assets } from "../../../constants/images";
import Form from "react-bootstrap/Form";
import { NavLink } from "react-router-dom";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router";
// import {FaEye, FaEyeSlash} from "react-icons/fa";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { Eye, EyeClose, Tick } from "../../../constants/svg";

const ForgotPass = () => {
  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors, isValid },
  } = useForm({
    mode: "onChange",
  });
  const onSubmit = async (data) => {
    setTimeout(() => {
      navigate("/verifyaccount");
    }, 1500);
  }


  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <Form.Group controlId="formBasicEmail" className="input_main">
        <Form.Label>
          Email Address <span className="form_star">*</span>
        </Form.Label>
        <InputGroup>
          <Form.Control
            className={errors.email ? "onboarding-placeholders hasError" : "onboarding-placeholders"}
            type="email"
            placeholder="Email address"
            id={"formSignInEmail"}
            {...register("email", {
              required: {
                value: "required",
                message: "Email is required",
              },
              maxLength: {
                value: 255,
                message: "Max length is 255",
              },
              pattern: {
                value: /\S+@\S+\.\S+/,
                message: "Entered value does not match email format",
              },
            })}
          />
          {isValid && <InputGroup.Text id="basic-addon2">
            <Tick />
          </InputGroup.Text>}
        </InputGroup>

        {errors.email && (
          <span className={"validation-error pr-5"} role="alert">
            {errors.email.message}
          </span>
        )}
      </Form.Group>

      <div className="main_btn">
        <Button className="main_form_btn" type="submit">
          Continue
        </Button>
      </div>
    </Form>
  );

}

export default ForgotPass;
