import React, { useState } from 'react';
import { Breadcrumb, BreadcrumbItem } from 'react-bootstrap';
import { NavLink } from "react-router-dom";
import { NoSearchFound, ArrowRight } from '../../constants/svg';


const Search = () => {
    const [searchFound, setSearchFound] = useState(
        [{
            searchlink: "/dashboard",
            breadcrumblink: [
                {
                    name: "Dashboard"
                },
                {
                    name: "Reports"
                },
            ],
            pagetitle: "Report",
            pagePara: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Repudiandae, illum."
        },
        {
            searchlink: "/dashboard",
            breadcrumblink: [
                {
                    name: "Dashboard"
                },
                {
                    name: "Report"
                },
                {
                    name: "Mix and Chocolate Mix"
                }
            ],
            pagetitle: "Mix and Chocolate Mix",
            pagePara: "Lorem ipsum dolor sit amet, consectetur adipiscing elit."
        },
        {
            searchlink: "/dashboard",
            breadcrumblink: [
                {
                    name: "Dashboard"
                },
                {
                    name: "Employees"
                }
            ],
            pagetitle: "Employees",
            pagePara: "Lorem ipsum dolor sit amet, consectetur adipiscing elit."
        },
        {
            searchlink: "/dashboard",
            breadcrumblink: [
                {
                    name: "Dashboard"
                },
                {
                    name: "Training"
                }
            ],
            pagetitle: "Training",
            pagePara: "Lorem ipsum dolor sit amet, consectetur adipiscing elit."
        }
        ])
    return (
        <>
            <div className="fullpageWrapper">
                <div className="searchFoundWrapper">
                    <div className="SearchFoundList">
                        {console.log(searchFound)}
                        {searchFound.length > 0 && searchFound?.map((v, i) => {
                            return <div className="searchFounditem" key={i}>
                                <NavLink to={v?.searchlink}>
                                    <Breadcrumb>
                                        {v?.breadcrumblink?.map((d, k) => {
                                            return <BreadcrumbItem active>
                                                {d.name}
                                            </BreadcrumbItem>
                                        })}
                                    </Breadcrumb>
                                    <p className='searhPageTitle'>{v?.pagetitle}</p>
                                    <p className='searchPagePara'>{v?.pagePara}</p>
                                    <div className="arrowRightSvg">
                                        <ArrowRight />
                                    </div>
                                </NavLink>
                            </div>
                        })
                        }

                    </div>
                    {searchFound.length === 0 && <div className='nosearchResult'>
                        <div className="noSearchImg">
                            <NoSearchFound />
                        </div>
                        <p className='text-center'>No Search Found</p>
                    </div>}
                </div>
            </div>

        </>
    )
}

export default Search