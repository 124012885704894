import React, { useState } from 'react';
import { Row, Col } from 'react-bootstrap';
import { Button, Form, Input, Select, TimePicker, Switch } from 'antd';
import { ArrowDown, Close } from '../../constants/svg';
import dayjs from 'dayjs';


const onFinish = (values) => {
    console.log('Success:', values);
};


const validateZip = (rule, value, callback) => {
    const regex = /^[0-9]+$/;
    if (regex.test(value)) {
        callback();
    } else {
        callback("Please enter a numerical value");
    }
};

const validatePhoneNumber = (rule, value, callback) => {
    const regex = /^\s*(?:\+?(\d{1,3}))?[- (]*(\d{3})[- )]*(\d{3})[- ]*(\d{4})(?: *[x/#]{1}(\d+))?\s*$/;
    if (regex.test(value)) {
        callback();
    } else {
        callback("Please enter a valid phone number!");
    }
};

const { Option } = Select;

const EditLocationForm = () => {
    const [selectedTeamMembers, setSelectedTeamMembers] = useState([]);
    const handleTeamMemberChange = (value) => {
        setSelectedTeamMembers(value);
    };
    return (
        <>
            <Form
                onFinish={onFinish}
                size={"large"}
                initialValues={{
                    locationName: "My Business",
                    address: "123 Main St",
                    zip: "12345",
                    city: "Anytown",
                    state: "CA",
                    country: "USA",
                    phoneNumber: "555-555-5555",
                    teamMembers: ["Dr. Anurang", "Dr. Abhijeet"],
                }}
            >
                <Row>
                    <Col lg={6} sm={12}>
                        <Form.Item
                            label="Location Name"
                            name="locationName"
                            rules={[
                                {
                                    required: true,
                                    message: "Please input location name!",
                                },
                            ]}
                            labelCol={{ span: 24 }}
                            wrapperCol={{ span: 24 }}

                        >
                            <Input placeholder="Enter location" />
                        </Form.Item>
                    </Col>
                    <Col lg={6} sm={12}>
                        <Form.Item
                            label="Address"
                            name="address"
                            rules={[
                                {
                                    required: true,
                                    message: "Please input address!",
                                },
                            ]}
                            labelCol={{ span: 24 }}
                            wrapperCol={{ span: 24 }}
                        >
                            <Input placeholder="Enter Address" />
                        </Form.Item>
                    </Col>
                    <Col lg={6} sm={12}>
                        <Form.Item
                            label="Zip"
                            name="zip"
                            rules={[
                                {
                                    required: true,
                                    message: "",
                                },
                                {
                                    validator: validateZip,
                                },
                            ]}
                            labelCol={{ span: 24 }}
                            wrapperCol={{ span: 24 }}
                        >
                            <Input placeholder="Enter ZipCode" />
                        </Form.Item>
                    </Col>
                    <Col lg={6} sm={12}>
                        <Form.Item
                            label="City"
                            name="city"
                            rules={[
                                {
                                    required: true,
                                    message: "Please input city name!",
                                },
                            ]}
                            labelCol={{ span: 24 }}
                            wrapperCol={{ span: 24 }}
                        >
                            <Input placeholder="Enter City" />
                        </Form.Item>
                    </Col>
                    <Col lg={6} sm={12}>
                        <Form.Item
                            label="State"
                            name="state"
                            rules={[
                                {
                                    required: true,
                                    message: "Please input state name!",
                                },
                            ]}
                            labelCol={{ span: 24 }}
                            wrapperCol={{ span: 24 }}
                        >
                            <Input placeholder="Enter State" />
                        </Form.Item>
                    </Col>
                    <Col lg={6} sm={12}>
                        <Form.Item
                            label="Country"
                            name="country"
                            rules={[
                                {
                                    required: true,
                                    message: "Please input country name!",
                                },
                            ]}
                            labelCol={{ span: 24 }}
                            wrapperCol={{ span: 24 }}
                        >
                            <Input placeholder="Enter Country" />
                        </Form.Item>
                    </Col>
                    <Col lg={6} sm={12}>
                        <h2 className='fw-bold mb-3'>Contact No *</h2>
                        <Form.Item
                            label="Phone Number"
                            name="phoneNumber"
                            rules={[
                                {
                                    required: true,
                                    message: "",
                                },
                                {
                                    validator: validatePhoneNumber,
                                },
                            ]}
                            labelCol={{ span: 24 }}
                            wrapperCol={{ span: 24 }}
                        >
                            <Input placeholder='Enter Phone' />
                        </Form.Item>
                    </Col>
                    <Col lg={12} sm={12}>
                        <Form.Item
                            label="Select Team Members"
                            name="teamMembers"
                            rules={[
                                {
                                    required: true,
                                    message: "Please select team members!",
                                },
                            ]}
                            labelCol={{ span: 24 }}
                            wrapperCol={{ span: 24 }}
                        >
                            <Select
                                mode="multiple"
                                style={{ width: "100%" }}
                                placeholder="Please select team members"
                                onChange={handleTeamMemberChange}
                                value={selectedTeamMembers}
                                suffixIcon={<ArrowDown />}
                                removeIcon={<Close />}
                                className={"form-dropdown"}
                            >
                                <Option value="Dr. Anurang">Dr. Anurang</Option>
                                <Option value="Dr. Abhijeet">Dr. Abhijeet</Option>
                                <Option value="teamMember3">Team Member 3</Option>
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col lg={6} sm={12}>
                        <h2 className='fw-bold mb-3'>Add Bussiness Hours *</h2>
                    </Col>
                    <Row className='mb-4 align-items-center'>
                        <Col lg={3} sm={12}>
                            <h6 className='m-0 lightGrey'>Day</h6>
                        </Col>
                        <Col lg={3} sm={12}>
                            <h6 className='m-0 lightGrey'>Start Date</h6>
                        </Col>
                        <Col lg={3} sm={12}>
                            <h6 className='m-0 lightGrey'>Last Date</h6>
                        </Col>
                        <Col lg={3} sm={12}>
                            <h6 className='m-0 lightGrey'>Weekly Time</h6>
                        </Col>
                    </Row>
                    <Row className="mb-4 align-items-center">
                        <Col lg={3} sm={12}>All</Col>
                        <Col lg={3} sm={12}>
                            <TimePicker use12Hours format="h:mm a" defaultValue={dayjs('12:08:am', 'h:mm a')} /></Col>
                        <Col lg={3} sm={12}>
                            <TimePicker use12Hours format="h:mm a" defaultValue={dayjs('12:08:am', 'h:mm a')} />
                        </Col>
                        <Col lg={3} sm={12}></Col>
                    </Row>
                    <Row className="mb-4 align-items-center">
                        <Col lg={3} sm={12}>Monday</Col>
                        <Col lg={3} sm={12}>
                            <TimePicker use12Hours format="h:mm a" defaultValue={dayjs('12:08:am', 'h:mm a')} /></Col>
                        <Col lg={3} sm={12}>
                            <TimePicker use12Hours format="h:mm a" defaultValue={dayjs('12:08:am', 'h:mm a')} />
                        </Col>
                        <Col lg={3} sm={12}>
                            <Switch defaultChecked={true} />
                        </Col>
                    </Row>
                    <Row className="mb-4 align-items-center">
                        <Col lg={3} sm={12}>Tuesday</Col>
                        <Col lg={3} sm={12}>
                            <TimePicker use12Hours format="h:mm a" defaultValue={dayjs('12:08:am', 'h:mm a')} /></Col>
                        <Col lg={3} sm={12}>
                            <TimePicker use12Hours format="h:mm a" defaultValue={dayjs('12:08:am', 'h:mm a')} />
                        </Col>
                        <Col lg={3} sm={12}>
                            <Switch defaultChecked={true} />
                        </Col>
                    </Row>
                    <Row className="mb-4 align-items-center">
                        <Col lg={3} sm={12}>Wednesday</Col>
                        <Col lg={3} sm={12}>
                            <TimePicker use12Hours format="h:mm a" defaultValue={dayjs('12:08:am', 'h:mm a')} /></Col>
                        <Col lg={3} sm={12}>
                            <TimePicker use12Hours format="h:mm a" defaultValue={dayjs('12:08:am', 'h:mm a')} />
                        </Col>
                        <Col lg={3} sm={12}>
                            <Switch defaultChecked={true} />
                        </Col>
                    </Row>
                    <Row className="mb-4 align-items-center">
                        <Col lg={3} sm={12}>Thurday</Col>
                        <Col lg={3} sm={12}>
                            <TimePicker use12Hours format="h:mm a" defaultValue={dayjs('12:08:am', 'h:mm a')} /></Col>
                        <Col lg={3} sm={12}>
                            <TimePicker use12Hours format="h:mm a" defaultValue={dayjs('12:08:am', 'h:mm a')} />
                        </Col>
                        <Col lg={3} sm={12}>
                            <Switch defaultChecked={true} />
                        </Col>
                    </Row>
                    <Row className="mb-4 align-items-center">
                        <Col lg={3} sm={12}>Friday</Col>
                        <Col lg={3} sm={12}>
                            <TimePicker use12Hours format="h:mm a" defaultValue={dayjs('12:08:am', 'h:mm a')} /></Col>
                        <Col lg={3} sm={12}>
                            <TimePicker use12Hours format="h:mm a" defaultValue={dayjs('12:08:am', 'h:mm a')} />
                        </Col>
                        <Col lg={3} sm={12}>
                            <Switch defaultChecked={true} />
                        </Col>
                    </Row>
                    <Row className="mb-4 align-items-center">
                        <Col lg={3} sm={12}>Saturday</Col>
                        <Col lg={3} sm={12}>
                            <TimePicker use12Hours format="h:mm a" defaultValue={dayjs('12:08:am', 'h:mm a')} /></Col>
                        <Col lg={3} sm={12}>
                            <TimePicker use12Hours format="h:mm a" defaultValue={dayjs('12:08:am', 'h:mm a')} />
                        </Col>
                        <Col lg={3} sm={12}>
                            <Switch defaultChecked={true} />
                        </Col>
                    </Row>
                    <Row className="mb-4 align-items-center">
                        <Col lg={3} sm={12}>Sunday</Col>
                        <Col lg={3} sm={12}>
                            <TimePicker use12Hours format="h:mm a" defaultValue={dayjs('12:08:am', 'h:mm a')} /></Col>
                        <Col lg={3} sm={12}>
                            <TimePicker use12Hours format="h:mm a" defaultValue={dayjs('12:08:am', 'h:mm a')} />
                        </Col>
                        <Col lg={3} sm={12}>
                            <Switch defaultChecked={true} />
                        </Col>
                    </Row>
                </Row>
                <Form.Item className='d-flex justify-content-end'>
                    <Button type="primary" htmlType="submit" shape="round" size='large'>
                        Create Location
                    </Button>
                </Form.Item>
            </Form>

        </>
    )
}

export default EditLocationForm