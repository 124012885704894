import React, { useState } from 'react'
import LocationTable from '../../components/User&Locations/LocationTable'
import UserLocationFilterBar from '../../components/User&Locations/UserLocationFilterBar'
import UserTable from '../../components/User&Locations/UserTable';
import { Tabs } from 'antd';
const items = [
    {
        key: '1',
        label: `Users`,
        children: <UserTable />,
    },
    {
        key: '2',
        label: `Location`,
        children: <LocationTable />,
    }
];

const UserAndLocationPage = () => {
    const [activeKey, setActiveKey] = useState('1');

    const onChange = (key) => {
        setActiveKey(key);
    };
    return (
        <>
            <UserLocationFilterBar activeKey={activeKey} />
            <Tabs defaultActiveKey="1" items={items} onChange={onChange} />
        </>
    )
}

export default UserAndLocationPage