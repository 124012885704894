import React, { useState } from 'react';
import { Table, Dropdown, Drawer } from 'antd';
import { UserIcon, CrossSquared } from '../../constants/svg';
import { NavLink } from 'react-router-dom';
import ThemeModal from '../ThemeModal';
import EditMembershipPlan from './EditMembershipPlan/EditMembershipPlan';
import ContentModal from '../ContentModal';
import SelectEmailToSend from './SelectEmailToSend/SelectEmailToSend';

const MembershipPlanContent = () => {
  const [showdelete, setShowDelete] = useState(false);
  const [open, setOpen] = useState(false);
  const [placement, setPlacement] = useState('right');
  const [contentModal, setContentModal] = useState(false);
  const showDrawer = () => {
    setOpen(true);
  };
  const onClose = () => {
    setOpen(false);
  };

  const items = [
    {
      key: '1',
      name: 'Edit',
      label: (
        <p className='m-0' onClick={showDrawer}>
          Edit
        </p>
      )
    },
    {
      key: '2',
      name: 'Send',
      label: (
        <p className='m-0' onClick={() => setContentModal(true)}>
          Send
        </p>
      )
    },
    {
      key: '3',
      name: 'Delete',
      label: (
        <p className='m-0' onClick={() => setShowDelete(true)}>
          Delete
        </p>
      )
    }
  ]

  const columns = [
    {
      title: 'Plan Name',
      dataIndex: 'planName',
      key: 'planName',
      render: (text, record) => (
        <NavLink to={record.link} className="cursor-pointer decoration-underline text-center">
          {record.planName}
        </NavLink>
      ),
      sorter: (a, b) => a.planName.localeCompare(b.planName),
      align: "center",
    },
    {
      title: 'Active Users',
      dataIndex: 'activeUsers',
      key: 'activeUsers',
      sorter: (a, b) => a.activeUsers.localeCompare(b.activeUsers),
      align: "center",
    },
    {
      title: 'Services',
      dataIndex: 'services',
      key: 'services',
      sorter: (a, b) => a.services.localeCompare(b.services),
      align: "center",
    },
    {
      title: 'Action',
      key: 'action',
      fixed: 'right',
      width: 30,
      align: 'center',
      render: () =>
        <Dropdown
          menu={{
            items,
          }}
          trigger={['click']}
          placement="bottomRight"
          menuClassName="general-menu"
        >
          <div className='dotted-btn'><UserIcon /></div>
        </Dropdown>
    },
  ];

  const data = [
    {
      key: '1',
      planName: 'John Brown',
      activeUsers: "32",
      services: "45",
      link: '/membership-detail'
    },
    {
      key: '2',
      planName: 'Nuel White',
      activeUsers: "22",
      services: "4",
      link: '/membership-detail'
    },
    {
      key: '3',
      planName: 'John Brown',
      activeUsers: "3",
      services: "52",
      link: '/membership-detail'
    },
    {
      key: '4',
      planName: 'John Brown',
      activeUsers: "47",
      services: "78",
      link: '/membership-detail'
    },
    {
      key: '5',
      planName: 'John Brown',
      activeUsers: "3",
      services: "52",
      link: '/membership-detail'
    },
    {
      key: '6',
      planName: 'John Brown',
      activeUsers: "3",
      services: "52",
      link: '/membership-detail'
    },
    {
      key: '7',
      planName: 'John Brown',
      activeUsers: "3",
      services: "52",
      link: '/membership-detail'
    },
    {
      key: '8',
      planName: 'John Brown',
      activeUsers: "3",
      services: "52",
      link: '/membership-detail'
    },
    {
      key: '9',
      planName: 'John Brown',
      activeUsers: "3",
      services: "52",
      link: '/membership-detail'
    },
    {
      key: '10',
      planName: 'John Brown',
      activeUsers: "3",
      services: "52",
      link: '/membership-detail'
    },
    {
      key: '11',
      planName: 'John Brown',
      activeUsers: "3",
      services: "52",
      link: '/membership-detail'
    },
    {
      key: '12',
      planName: 'John Brown',
      activeUsers: "3",
      services: "52",
      link: '/membership-detail'
    },
    {
      key: '13',
      planName: 'John Brown',
      activeUsers: "3",
      services: "52",
      link: '/membership-detail'
    },
    {
      key: '14',
      planName: 'John Brown',
      activeUsers: "3",
      services: "52",
      link: '/membership-detail'
    },
    {
      key: '15',
      planName: 'John Brown',
      activeUsers: "3",
      services: "52",
      link: '/membership-detail'
    },
    {
      key: '16',
      planName: 'John Brown',
      activeUsers: "3",
      services: "52",
      link: '/membership-detail'
    }
  ]
  return (
    <>
      <Table
        rowSelection={{
          type: 'checkbox',
        }}
        columns={columns}
        dataSource={data}
        bordered={false}
        pagination={{ pageSize: 10 }}
        scroll={{ x: 'max-content' }}
        responsive={true}
        className="membershipPlan"
      />

      <ThemeModal
        className="alertModal"
        title={"Alert"}
        content={"Are you sure you want to delete this plan?"}
        blueText={"Delete"}
        cancelText={"Cancel"}
        setShowDelete={setShowDelete}
        show={showdelete}
        onHide={() => setShowDelete(false)}
      />
      {/* Edit Membership Plan */}
      <Drawer
        title={<h1 className='fw-bold'>Edit Plan</h1>}
        placement={placement}
        size={"large"}
        onClose={onClose}
        closeIcon={<CrossSquared />}
        open={open}
      >
        <EditMembershipPlan />
      </Drawer>
      {/* Select Modal */}
      <ContentModal
        title={"Send Plan"}
        content={<SelectEmailToSend setOpen={setOpen} />}
        blueText={"Send"}
        show={contentModal}
        onHide={() => setContentModal(false)}
      />
    </>
  )
}

export default MembershipPlanContent