import { Form, Input, Button } from "antd";
import React from "react";
import { useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
const MarketingProposalEmail = (props) => {
  console.log(props);
  const navigate = useNavigate();

  const onFinish = (values) => {
    navigate("/marketing");
    toast.success("Your Propsal has been submitted!", {
      position: "bottom-right",
    });
    props.onHide();
  };

  return (
    <>
      <Form
        onFinish={onFinish}
        initialValues={{
          email: "xyz@gmail.com",
        }}
      >
        <Form.Item
          label="Email Address"
          name="email"
          rules={[
            {
              required: true,
              message: "Please input email",
            },
            {
              type: "email",
              message: "Please input valid email",
            },
          ]}
          labelCol={{ span: 24 }}
          wrapperCol={{ span: 24 }}
        >
          <Input />
        </Form.Item>
        <Form.Item className="d-flex justify-content-end">
          <Button type="primary" htmlType="submit" shape="round" size="large">
            Submit
          </Button>
        </Form.Item>
      </Form>
      <ToastContainer />
    </>
  );
};

export default MarketingProposalEmail;
