import { React } from "react";
import {  Row, Col } from "react-bootstrap";

import {
  
  DeleteIcon,
  LockIcon,
  ProfileIcon,

} from "../../constants/svg";


import {NavLink } from "react-router-dom";


const Settings = (props) => {
  return (
    
    <>
    <section className="settings-body">
      <Row>
        <Col xl={3} lg={3}>
          <div className="settings-bar">
            <div className="settings-navigation">
              <ul>
                <li className="setting-active">
                  <NavLink to="/settings/myprofile">
                  <div className="content-nav-bg">
                    <span className="settings-icon">
                      <ProfileIcon />
                    </span>
                    <span>My Profile</span>
                    </div>
                  </NavLink>
                </li>
                <li>
                  <NavLink to="/settings/changepassword">
                  <div className="content-nav-bg">
                    <span className="settings-icon">
                      <LockIcon />
                    </span>
                    <span>Change Password</span>
                    </div>
                  </NavLink>
                </li>
                <li>
                  <NavLink to="/settings/deleteaccount">
                  <div className="content-nav-bg">
                    <span className="settings-icon">
                      <DeleteIcon/>
                    </span>
                    <span>Delete Account</span>
                    </div>
                  </NavLink>
                </li>
              </ul>
            </div>
          </div>
        </Col>
        <Col xl={9} lg={9}>
          <div className="settings-bar">{props.children}</div>
        </Col>
      </Row>
    </section>
  </>
  );
};

export default Settings;
