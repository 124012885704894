import React from 'react'

const FilterBar = () => {
  return (
    <div className="filter-bar dashboardFilterBar">
      <h1 className='fw-bold m-0'>Dashboard</h1>
    </div>
  )
}

export default FilterBar