import { React, useState } from "react";
import { Document, Page } from "react-pdf";
import { Row, Col } from "react-bootstrap";
import { Avatar, Tag, Table } from "antd";
import { Assets } from "../../constants/images";
import {
  MenuDots,
  PaymentTick,
  PdfIcon,
} from "../../constants/svg";
import { NavLink } from "react-router-dom";
import ThemeModal from "../../components/ThemeModal";
import { Button } from "react-bootstrap";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import FollowUp from "../../components/Modals/Marketing/FollowUp";

//Table Component
const columns = [
  {
    title: 'Appointment Name',
    dataIndex: 'appointmentname',
    key: 'appointmentname',
    width: '25%',
    sorter: (a, b) => a.appointmentname.localeCompare(b.appointmentname),
  },
  {
    title: 'Total Time',
    dataIndex: 'totalTime',
    key: 'totalTime',
    width: '25%',
    sorter: (a, b) => a.totalTime.localeCompare(b.totalTime),
  },
  {
    title: 'Total Cost',
    dataIndex: 'totalCost',
    key: 'totalCost',
    width: '25%',
    sorter: (a, b) => a.totalCost.localeCompare(b.totalCost),
  },
  {
    title: 'Interval',
    dataIndex: 'interval',
    key: 'interval',
    width: '20%',
    sorter: (a, b) => a.interval.localeCompare(b.interval),
  },
];

const data = [
  {
    key: "1",
    appointmentname: "Teeth Checkup",
    totalTime: "45 Mins",
    totalCost: "$100",
    interval: "$10",
  },
  {
    key: "2",
    appointmentname: "Teeth Checkup",
    totalTime: "60 Mins",
    totalCost: "$120",
    interval: "$15",
  },
  {
    key: "3",
    appointmentname: "Teeth Checkup",
    totalTime: "30 Mins",
    totalCost: "$75",
    interval: "$7.5",
  },
  {
    key: "4",
    appointmentname: "Teeth Checkup",
    totalTime: "90 Mins",
    totalCost: "$200",
    interval: "$20",
  },
  {
    key: "5",
    appointmentname: "Teeth Checkup",
    totalTime: "120 Mins",
    totalCost: "$300",
    interval: "$25",
  },
  {
    key: "6",
    appointmentname: "Teeth Checkup",
    totalTime: "45 Mins",
    totalCost: "$100",
    interval: "$10",
  },
  {
    key: "7",
    appointmentname: "Teeth Checkup",
    totalTime: "60 Mins",
    totalCost: "$120",
    interval: "$15",
  },
  {
    key: "8",
    appointmentname: "Teeth Checkup",
    totalTime: "30 Mins",
    totalCost: "$75",
    interval: "$7.5",
  },
  {
    key: "9",
    appointmentname: "Teeth Checkup",
    totalTime: "30 Mins",
    totalCost: "$75",
    interval: "$7.5",
  },
  {
    key: "10",
    appointmentname: "Teeth Checkup",
    totalTime: "30 Mins",
    totalCost: "$75",
    interval: "$7.5",
  },
  {
    key: "11",
    appointmentname: "Teeth Checkup",
    totalTime: "30 Mins",
    totalCost: "$75",
    interval: "$7.5",
  }
]

const ProposalProfile = () => {
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [showfollowup, setShowFollowUp] = useState(false);

  const downloadPdf = () => {
    fetch(Assets.filepdf) // Modify the path to your PDF file
      .then(response => response.blob())
      .then(blob => {
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = 'Contract.pdf';
        document.body.appendChild(a);
        a.click();
        a.remove();
        window.URL.revokeObjectURL(url);
      });
  };
  return (
    <div className="user-management proposal-profile">
      <Row>
        <Col md={12}>
          <div className="breadcrumbs">
            <Breadcrumb>
              <Breadcrumb.Item>
                <NavLink to="/dashboard">Dashboard</NavLink>
              </Breadcrumb.Item>
              <Breadcrumb.Item active>
                <NavLink to="/Marketing/ProposalProfile">Proposal Profile</NavLink>
              </Breadcrumb.Item>
            </Breadcrumb>
          </div>
        </Col>
      </Row>
      <Row>
        <Col md={6}>
          <h1 className="fw-bold">Proposal Profile</h1>
        </Col>
        <Col md={6}>
          <div className="d-flex justify-content-end">
            <div>
              <Button className="filter-btn d-none">Filter</Button>
            </div>
            <div>
              <Button
                onClick={() => setShowFollowUp(true)}
                className="add-new-btn ms-3"
              >
                Follow Up
              </Button>
            </div>
          </div>
        </Col>
      </Row>
      <Row>
        <Col md={12}>
          <Row>
            <Col md={9}>
              <div className="stats-box">
                <h2>Dental Plan</h2>
                <div className="row">
                  <div className="col-lg-8">
                    <p className="greyColor">
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus<br /> porttitor consequat placerat. In pulvinar aliquam euismod.
                    </p></div>
                  <div className="col-lg-4">
                    <p className="greyColor m-0">Sent to:</p>
                    <div className="d-flex justify-content-between align-items-center">
                      <div className="dental-plan-box d-flex align-items-center">
                        <Avatar size="large" src={Assets.PatientImg} />
                        <h7 className="fw-bold ms-2">Peter Paul</h7>
                      </div>
                      <Tag color={"green"} className="p-2">
                        Seen
                      </Tag>
                    </div>

                  </div>
                </div>

                <ul className="blue-para-list mt-3">
                  <li className="me-3"><p className="m-0">Duration :<span className="ms-1">3 Months</span></p></li>
                  <li className="me-3"><p className="m-0">Total Appointments :<span className="ms-1">08</span></p></li>
                  <li className="me-3"><p className="m-0">Total Fees :<span className="ms-1">75%</span></p></li>
                  <li className="me-3"><p className="m-0">Discount :<span className="ms-1">25%</span></p></li>
                  <li className="me-0"><p className="m-0">Insurance :<span className="ms-1">25%</span></p></li>
                </ul>
              </div>
            </Col>
            <Col md={3}>
              <div className="stats-box">
                <h2 className="fw-bold mb-4">Agreement</h2>
                {/* Onlick Download Pdf */}
                <div className="pdf-download" onClick={downloadPdf}>
                  <PdfIcon />
                  <p className="m-0 decoration-underline ms-2">Contract.pdf</p>
                </div>
              </div>
            </Col>
          </Row>
        </Col>
      </Row >
      <Row>
        <Col md={9}>
          <div className="stats-box status-container">
            <h2>Status</h2>
            <div className="d-flex justify-content-between flex-wrap">
              <div>
                <div className="status">Created</div>
                <div className="status-response">24/8/2022</div>
                <div className="status-response">12:30 pm</div>
              </div>
              <div className="me-3">
                <div className="status">Sent</div>
                <div className="status-response">24/8/2022</div>
                <div className="status-response">12:30 pm</div>
              </div>
              <div className="me-3">
                <div className="status">Seen</div>
                <div className="status-response">24/8/2022</div>
                <div className="status-response">12:30 pm</div>
              </div>
              <div className="me-3">
                <div className="status">Sales Rep Connected</div>
                <div className="status-response">24/8/2022</div>
                <div className="status-response">12:30 pm</div>
              </div>
              <div className="me-3">
                <div className="status">Lead Converted</div>
                <div className="status-response">24/8/2022</div>
                <div className="status-response">12:30 pm</div>
              </div>
              <div className="me-3">
                <div className="status">Signed</div>
                <div className="status-response">24/8/2022</div>
                <div className="status-response">12:30 pm</div>
              </div>
              <div className="me-3">
                <div className="status">Accepted</div>
                <div className="status-response">-</div>
              </div>
            </div>
          </div>
          <div className="cost-of-service">Appointment Cost Breakup</div>
          <Table
            rowSelection={{
              type: 'checkbox',
            }}
            // size={'medium'}
            columns={columns}
            dataSource={data}
            scroll={{
              y: 300
            }}
            bordered={false}
            pagination={false}
            className="mb-4"
          />
          <div className="stats-box ">
            <div className="reason-wrap mb-4">
              <h3 className="m-0">Reasons you may need to proposed treatment</h3>
              <p className="m-0 text-black">lorem ipsum dolor sit
                amet, lorem ipsum dolor
                sit amet, consectetur
              </p>
            </div>
            <div className="reason-wrap mb-4">
              <h3 className="m-0">Findings and Diagnosis</h3>
              <p className="m-0 text-black">lorem ipsum dolor sit
                amet, lorem ipsum dolor
                sit amet, consectetur
              </p>
            </div>
            <div className="reason-wrap mb-4">
              <h3 className="m-0">Pre-op and Post-op instructions</h3>
              <p className="m-0 text-black">lorem ipsum dolor sit
                amet, lorem ipsum dolor
                sit amet, consectetur
              </p>
            </div>
            <div className="reason-wrap mb-4">
              <h3 className="m-0">What to expect the day of procedure</h3>
              <p className="m-0 text-black">lorem ipsum dolor sit
                amet, lorem ipsum dolor
                sit amet, consectetur
              </p>
            </div>
          </div>
        </Col>
        <Col md={3}>
          <div className="proposal-payment-plan stats-box">
            <div className="default-heading">Payment Plan</div>
            <div className="plan-no">Plan 1</div>
            <div className="plan-desc">
              lorem ipsum dolor sit amet, lorem ipsum dolor sit amet,
              consectetur.
            </div>
            <div>
              <div className="d-flex mt_10">
                <div>
                  <PaymentTick />
                </div>
                <div className="plan-conditions">Payemnt Source: </div>
                <div className="payment-condition-answer">Split</div>
              </div>
              <div className="d-flex mt_10">
                <div>
                  <PaymentTick />
                </div>
                <div className="plan-conditions">Amount Source: </div>
                <div className="payment-condition-answer">Adhoc</div>
              </div>
              <div className="d-flex mt_10">
                <div>
                  <PaymentTick />
                </div>
                <div className="plan-conditions">Payemnt Mode: </div>
                <div className="payment-condition-answer">Automatic</div>
              </div>
              <div className="d-flex mt_10">
                <div>
                  <PaymentTick />
                </div>
                <div className="plan-conditions">Total Discount: </div>
                <div className="payment-condition-answer">10%</div>
              </div>
            </div>
          </div>
          <div className="financing-plan-container stats-box">
            <div className="default-heading">Financing Plan</div>
            <div className="plan-no">Plan 1</div>
            <div className="plan-desc">
              lorem ipsum dolor sit amet, lorem ipsum dolor sit amet,
              consectetur.
            </div>
            <div>
              <div className="d-flex mt_10">
                <div>
                  <PaymentTick />
                </div>
                <div className="pt_12">
                  <span className="plan-conditions">
                    Minimum Down Payment:{" "}
                  </span>
                  <span className="payment-condition-answer">75%</span>
                </div>
              </div>
              <div className="d-flex mt_10">
                <div>
                  <PaymentTick />
                </div>
                <div className="pt_12">
                  <span className="plan-conditions">Interest Rate: </span>
                  <span className="payment-condition-answer">25%</span>
                </div>
              </div>{" "}
              <div className="d-flex mt_10">
                <div>
                  <PaymentTick />
                </div>
                <div className="pt_12">
                  <span className="plan-conditions">
                    Interest Rate Adjustment:{" "}
                  </span>
                  <span className="payment-condition-answer">Required</span>
                </div>
              </div>
              <div className="d-flex mt_10">
                <div>
                  <PaymentTick />
                </div>
                <div className="pt_12">
                  <span className="plan-conditions">Discount Eligiblity: </span>
                  <span className="payment-condition-answer">10%</span>
                </div>
              </div>
              <div className="d-flex mt_10">
                <div>
                  <PaymentTick />
                </div>
                <div className="pt_12">
                  <span className="plan-conditions">
                    Calculation of Payment Schedule:
                  </span>
                  <span className="payment-condition-answer">Adhoc</span>
                </div>
              </div>
              <div className="d-flex mt_10">
                <div>
                  <PaymentTick />
                </div>
                <div className="pt_12">
                  <span className="plan-conditions">Late Payment Fees:</span>
                  <span className="payment-condition-answer">$3000</span>
                </div>
              </div>
              <div className="d-flex mt_10">
                <div>
                  <PaymentTick />
                </div>
                <div className="pt_12">
                  <span className="plan-conditions">
                    Insurance Claim Processing Fees:
                  </span>
                  <span className="payment-condition-answer">65%</span>
                </div>
              </div>
              <div className="d-flex mt_10">
                <div>
                  <PaymentTick />
                </div>
                <div className="pt_12">
                  <span className="plan-conditions">Extended Financing:</span>
                  <span className="payment-condition-answer">10 Months</span>
                </div>
              </div>
            </div>
          </div>
        </Col>
      </Row>
      {/*FOLLOW UP MODAL */}
      <div className="pages-modals">
        <ThemeModal
          title={"Create Proposal"}
          content={<FollowUp />}
          show={showfollowup}
          onHide={() => setShowFollowUp(false)}
        />
      </div>
      {/* END OF FOLLOWUP MODAL*/}
    </div >
  );
};

export default ProposalProfile;
