import { React, useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { Link, NavLink } from "react-router-dom";
import ThemeModal from "../../components/ThemeModal";
import { Button } from "react-bootstrap";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import FinancingCard from "../../components/FinancingCard";
import FinancingCardData from "./FinancingCardData";
import AddFinancingPlan from "../../components/Modals/FinancingPlan/AddFinancingPlan";
const FinancingPage = () => {
  const [financingdata, setFinancingData] = useState(FinancingCardData);
  const [showfinancingplan, setShowFinancingPlan] = useState(false);

  console.log(financingdata);
  return (
    <div className="user-management">
      <Row>
        <Col md={12}>
          <div className="breadcrumbs">
            <Breadcrumb>
              <Breadcrumb.Item>
                <NavLink to="/dashboard">Dashboard</NavLink>
              </Breadcrumb.Item>
              <Breadcrumb.Item active>
                <NavLink to="/Financing">Financing Plan</NavLink>
              </Breadcrumb.Item>
            </Breadcrumb>
          </div>
        </Col>
      </Row>
      <Row>
        <Col md={6}>
          <h2>Financing Plan</h2>
        </Col>
        <Col md={6}>
          <div className="d-flex justify-content-start justify-content-md-end">
            <div>
              <Button className="filter-btn d-none">Filter</Button>
            </div>
            <div>
              <Button
                onClick={() => setShowFinancingPlan(true)}
                className="add-new-btn "
              >
                + Add New
              </Button>
            </div>
          </div>
        </Col>
      </Row>
      <Row>
        <FinancingCard financingdata={financingdata} />
      </Row>

      {/*Financing Plan MODAL */}
      <div className="pages-modals">
        <ThemeModal
          className="ezfi-modal"
          title={"Add Financing Plan"}
          content={<AddFinancingPlan />}
          show={showfinancingplan}
          onHide={() => setShowFinancingPlan(false)}
        />
      </div>
      {/* END OF Financing Plan MODAL*/}
    </div>
  );
};

export default FinancingPage;
 