import React, { useState } from 'react';
import { Table, Dropdown, Drawer } from 'antd';
import { NavLink } from 'react-router-dom';
import { CrossSquared, UserIcon } from '../../constants/svg';
import { Assets } from '../../constants/images';
import ThemeModal from '../ThemeModal';
import EditLocationForm from './EditLocationForm';


const onChange = (e) => {
    console.log(`checked = ${e.target.checked}`);
};



const LocationTable = () => {
    const [showdelete, setShowDelete] = useState(false);
    const [open, setOpen] = useState(false);
    const [placement, setPlacement] = useState('right');
    const showDrawer = () => {
        setOpen(true);
    };
    const onChange = (e) => {
        setPlacement(e.target.value);
    };
    const onClose = () => {
        setOpen(false);
    };

    const items = [
        {
            key: '1',
            name: 'Edit',
            label: (
                <p className='m-0' onClick={showDrawer}>
                    Edit
                </p>
            )
        },
        {
            key: '2',
            name: 'Delete',
            label: (
                <p className='m-0' onClick={() => setShowDelete(true)}>
                    Delete
                </p>
            )
        }
    ]

    const columns = [
        {
            title: 'Location',
            dataIndex: 'location',
            key: 'location',
            width: 50,
            render: (text, record) => (
                <img src={record.mapImg} alt="map-icon" width={45} height={45} />
            ),
        },
        {
            title: '',
            dataIndex: 'locationAdd',
            key: 'locationAdd',
            width: 150,
            render: (text, record) => (
                <NavLink to={record.link}>
                    {record.locationAdd}
                </NavLink>
            ),
        },
        {
            title: 'Address',
            dataIndex: 'address',
            key: 'address',
            width: 150,
        },
        {
            title: 'Contact No',
            dataIndex: 'contactNo',
            key: 'contactNo',
            width: 150,

        },
        {
            title: 'Users',
            dataIndex: 'users',
            key: 'users',
            width: 30,
        },
        {
            title: 'Action',
            key: 'action',
            fixed: 'right',
            width: 30,
            align: 'center',
            render: () =>
                <Dropdown
                    menu={{
                        items,
                    }}
                    trigger={['click']}
                    placement="bottomRight"
                >
                    <div className='dotted-btn'><UserIcon /></div>
                </Dropdown>
        },
        ,
    ];

    const data = [
        {
            key: '1',
            name: 'John Brown',
            age: 32,
            mapImg: Assets.mapImg,
            link: '/user-and-location/location-detail',
            address: '4702 Froe Street West Virginia',
            contactNo: '+971 25125 125',
            location: 'London',
            locationAdd: '4702 Froe Street West Virginia',
            users: '1',
        },
        {
            key: '2',
            name: 'Aron Behja',
            age: 44,
            mapImg: Assets.mapImg,
            link: '/user-and-location/location-detail',
            address: 'Phoenix No. 12 Lake Park',
            contactNo: '+971 25125 125',
            location: 'London',
            locationAdd: '4702 Froe Street West Virginia',
            users: '1',
        },
    ]

    return (
        <>
            <Table
                rowSelection={{
                    type: 'checkbox',
                }}
                columns={columns}
                dataSource={data}
                bordered={false}
                pagination={{ pageSize: 10 }}
                scroll={{ x: 'max-content' }}
                responsive={true}
            />

            <ThemeModal
                className="ezfi-modal"
                title={"Alert"}
                content={"Are you sure you want to delete this location?"}
                blueText={"Deactivate"}
                cancelText={"Cancel"}
                setShowDelete={setShowDelete}
                show={showdelete}
                onHide={() => setShowDelete(false)}
            />

            <Drawer
                title={<h1 className='fw-bold'>Edit Location</h1>}
                placement={placement}
                size={"large"}
                onClose={onClose}
                closeIcon={<CrossSquared />}
                open={open}
            >
                <EditLocationForm />
            </Drawer>
        </>
    )
}

export default LocationTable