import { React, useState } from "react";
import { Row, Col } from "react-bootstrap";
import MarketingFilterBar from "../../components/Marketing/MarketingFilterBar";
import { Table, Avatar, Badge, Button, Tag, Dropdown } from 'antd';
import { Assets } from "../../constants/images";
import { UserIcon } from '../../constants/svg';
import { NavLink } from "react-router-dom";
import 'dayjs/locale/en';

//Action dropdown menu list 
const items = [
  {
    key: '1',
    label: (
      <p className='m-0'>
        Sign Proposal
      </p>

    ),
  },
  {
    key: '2',
    label: (
      <p className='m-0'>
        Mark as Sales Rep Connected
      </p>
    ),
  },
  {
    key: '3',
    label: (
      <p className='m-0'>
        Mark as Lead Converted
      </p>
    ),
  },
];

const columns = [
  {
    title: '',
    width: 70,
    dataIndex: 'avatar',
    key: 'avatar',
    fixed: 'left',
    align: 'center',
    render: (avatarUrl) => (
      <Avatar src={avatarUrl} />
    ),
  },
  {
    title: 'Client Name',
    width: 150,
    dataIndex: 'clientName',
    key: 'clientName',
  },
  {
    title: 'Treatment Plan',
    dataIndex: 'treatmentPlan',
    key: 'treatmentPlan',
    width: 150,
    sorter: (a, b) => a.treatmentPlan.localeCompare(b.treatmentPlan),
    render: (text, record) => (
      <NavLink to={record.link}>
        {record.treatmentPlan}
      </NavLink>
    ),
  },
  {
    title: 'Email ID',
    dataIndex: 'email',
    key: 'email',
    width: 250,
    sorter: (a, b) => a.email.localeCompare(b.email),
  },
  {
    title: 'Financing Plan',
    dataIndex: 'financingPlan',
    key: 'financingPlan',
    width: 150,
    sorter: (a, b) => a.financingPlan.localeCompare(b.financingPlan),
  },
  {
    title: 'Payment Plan',
    dataIndex: 'paymentPlan',
    key: 'paymentPlan',
    width: 150,
    sorter: (a, b) => a.paymentPlan.localeCompare(b.paymentPlan),
  },
  {
    title: 'No. of Session',
    dataIndex: 'noOfSession',
    key: 'noOfSession',
    width: 150,
    sorter: (a, b) => a.noOfSession.localeCompare(b.noOfSession),
  },
  {
    title: 'Duration',
    dataIndex: 'duration',
    key: 'duration',
    width: 150,
    sorter: (a, b) => a.duration.localeCompare(b.duration),
  },
  {
    title: 'Interest Rate',
    dataIndex: 'interestRate',
    key: 'interestRate',
    width: 150,
    sorter: (a, b) => a.interestRate.localeCompare(b.interestRate),
  },
  {
    title: 'Total Fees',
    dataIndex: 'totalFees',
    key: 'totalFees',
    width: 150,
    sorter: (a, b) => a.totalFees.localeCompare(b.totalFees),

  },
  {
    title: 'Proposal Status',
    dataIndex: 'proposalStatus',
    key: 'proposalStatus',
    width: 150,
    sorter: (a, b) => a.financingPlan.localeCompare(b.financingPlan),
    render: (proposalStatus) => (
      <span>
        {proposalStatus.map((tag) => {
          let color = tag.toLowerCase() === 'sent' ? 'blue' :
            tag.toLowerCase() === 'created' ? 'orange' :
              tag.toLowerCase() === 'sales rep created' ? 'geekblue' :
                tag.toLowerCase() === 'lead converted' ? 'green' :
                  tag.toLowerCase() === 'signed' ? 'cyan' :
                    tag.toLowerCase() === 'accepted' ? 'purple' :
                      tag.toLowerCase() === 'seen' ? 'magenta' : 'blue';
          return (
            <Tag color={color} key={tag} className='mb-2 text-capitalize fw-bold'>
              {tag}
            </Tag>
          );
        })}
      </span>
    ),
  },
  {
    title: 'Action',
    key: 'operation',
    fixed: 'right',
    width: 90,
    align: 'center',
    render: () =>
      <Dropdown
        menu={{
          items,
        }}
        trigger={['click']}
        placement="bottomRight"
      >
        <Button className='dotted-btn'><UserIcon /></Button>
      </Dropdown>
  },
  ,
];

const data = [
  {
    key: '1',
    avatar: Assets.PatientImg,
    address: 'New York No. 1 Lake Park',
    clientName: 'Paul Doe',
    treatmentPlan: 'Treatment Plan',
    link: "/marketing/proposalprofile",
    email: 'efpyi@example.com',
    financingPlan: "FP 1",
    paymentPlan: " 1000",
    noOfSession: " 1",
    duration: " 60",
    interestRate: "1.5",
    totalFees: " 6000",
    proposalStatus: ['Created'],

  },
  {
    key: '2',
    avatar: Assets.PatientImg,
    address: 'California No. 2 Lake Park',
    clientName: 'John Smith',
    treatmentPlan: 'Dental Implants',
    link: "/marketing/proposalprofile",
    email: 'johnsmith@example.com',
    financingPlan: "FP 4",
    paymentPlan: " 1500",
    noOfSession: " 2",
    duration: " 120",
    interestRate: "2.5",
    totalFees: " 9000",
    proposalStatus: ['Sent'],

  },
  {
    key: '3',
    avatar: Assets.PatientImg,
    address: 'Texas No. 3 Lake Park',
    clientName: 'Mary Johnson',
    treatmentPlan: 'Orthodontics',
    link: "/marketing/proposalprofile",
    email: 'maryjohnson@example.com',
    financingPlan: "FP 1",
    paymentPlan: " 1200",
    noOfSession: " 3",
    duration: " 180",
    interestRate: "3.5",
    totalFees: " 8400",
    proposalStatus: ['Created'],

  },
  {
    key: '4',
    avatar: Assets.PatientImg,
    address: 'Florida No. 4 Lake Park',
    clientName: 'Jane Doe',
    treatmentPlan: 'Teeth Whitening',
    link: "/marketing/proposalprofile",
    email: 'janedoe@example.com',
    financingPlan: "FP 1",
    paymentPlan: " 500",
    noOfSession: " 1",
    duration: " 30",
    interestRate: "0.5",
    totalFees: " 3500",
    proposalStatus: ['Sales Rep Created'],

  },
  {
    key: '5',
    avatar: Assets.PatientImg,
    address: 'Arizona No. 5 Lake Park',
    clientName: 'Robert Johnson',
    treatmentPlan: 'Braces',
    link: "/marketing/proposalprofile",
    email: 'robertjohnson@example.com',
    financingPlan: "FP 1",
    paymentPlan: " 1800",
    noOfSession: " 5",
    duration: " 300",
    interestRate: "5.5",
    totalFees: " 10800",
    proposalStatus: ['Lead Converted'],

  },
  {
    key: '6',
    avatar: Assets.PatientImg,
    address: 'Ohio No. 6 Lake Park',
    clientName: 'Sarah Lee',
    treatmentPlan: 'Root Canal',
    link: "/marketing/proposalprofile",
    email: 'sarahlee@example.com',
    financingPlan: "FP 1",
    paymentPlan: " 1200",
    noOfSession: " 2",
    duration: " 120",
    interestRate: "2.0",
    totalFees: " 7200",
    proposalStatus: ['Accepted'],

  },
  {
    key: '7',
    avatar: Assets.PatientImg,
    address: 'Georgia No. 7 Lake Park',
    clientName: 'David Davis',
    treatmentPlan: 'Crowns and Bridges',
    link: "/marketing/proposalprofile",
    email: 'daviddavis@example.com',
    financingPlan: "FP 1",
    paymentPlan: " 2000",
    noOfSession: " 3",
    duration: " 180",
    interestRate: "3.0",
    totalFees: " 12000",
    proposalStatus: ['Signed'],

  },
  {
    key: '8',
    avatar: Assets.PatientImg,
    address: 'Washington No. 8 Lake Park',
    clientName: 'Olivia Kim',
    treatmentPlan: 'Dentures',
    link: "/marketing/proposalprofile",
    email: 'oliviakim@example.com',
    financingPlan: "FP 1",
    paymentPlan: " 800",
    noOfSession: " 1",
    duration: " 60",
    interestRate: "1.5",
    totalFees: " 5800",
    proposalStatus: ['Accepted'],

  },
  {
    key: '9',
    avatar: Assets.PatientImg,
    address: 'Virginia No. 9 Lake Park',
    clientName: 'William Smith',
    treatmentPlan: 'Dental Cleaning',
    link: "/marketing/proposalprofile",
    email: 'williamsmith@example.com',
    financingPlan: "FP 1",
    paymentPlan: " 300",
    noOfSession: " 1",
    duration: " 30",
    interestRate: "0.5",
    totalFees: " 1800",
    proposalStatus: ['Seen'],

  },
  {
    key: '10',
    avatar: Assets.PatientImg,
    address: 'Oregon No. 10 Lake Park',
    clientName: 'Ava Martinez',
    treatmentPlan: 'Fillings',
    link: "/marketing/proposalprofile",
    email: 'avamartinez@example.com',
    financingPlan: "FP 1",
    paymentPlan: " 400",
    noOfSession: " 1",
    duration: " 60",
    interestRate: "1.0",
    totalFees: " 2400",
    proposalStatus: ['Created'],

  },
  {
    key: '11',
    avatar: Assets.PatientImg,
    address: 'North Carolina No. 11 Lake Park',
    clientName: 'Chris Brown',
    treatmentPlan: 'Dental Cleaning',
    link: "/marketing/proposalprofile",
    email: 'chrisbrown@example.com',
    financingPlan: "FP 1",
    paymentPlan: " 400",
    noOfSession: " 1",
    duration: " 30",
    interestRate: "0.5",
    totalFees: " 2200",
    proposalStatus: ['Created'],

  },
  {
    key: '12',
    avatar: Assets.PatientImg,
    address: 'Virginia No. 12 Lake Park',
    clientName: 'Linda Anderson',
    treatmentPlan: 'Wisdom Teeth Extraction',
    link: "/marketing/proposalprofile",
    email: 'lindaanderson@example.com',
    financingPlan: "FP 1",
    paymentPlan: " 1000",
    noOfSession: " 1",
    duration: " 60",
    interestRate: "1.5",
    totalFees: " 6000",
    proposalStatus: ['Accepted'],

  },
  {
    key: '13',
    avatar: Assets.PatientImg,
    address: 'Ohio No. 13 Lake Park',
    clientName: 'Kevin Martinez',
    treatmentPlan: 'Fillings',
    link: "/marketing/proposalprofile",
    email: 'kevinmartinez@example.com',
    financingPlan: "FP 8",
    paymentPlan: " 300",
    noOfSession: " 1",
    duration: " 30",
    interestRate: "0.5",
    totalFees: " 1800",
    proposalStatus: ['Seen'],

  },
  {
    key: '14',
    avatar: Assets.PatientImg,
    address: 'Michigan No. 14 Lake Park',
    clientName: 'Rachel Lee',
    treatmentPlan: 'Dental Crown',
    link: "/marketing/proposalprofile",
    email: 'rachellee@example.com',
    financingPlan: "FP 9",
    paymentPlan: " 1200",
    noOfSession: " 2",
    duration: " 90",
    interestRate: "2.0",
    totalFees: " 7200",
    proposalStatus: ['Created'],

  },
  {
    key: '15',
    avatar: Assets.PatientImg,
    address: 'Georgia No. 10 Lake Park',
    clientName: 'Samantha Williams',
    treatmentPlan: 'Root Canal',
    link: "/marketing/proposalprofile",
    email: 'samanthawilliams@example.com',
    financingPlan: "FP 5",
    paymentPlan: " 800",
    noOfSession: " 2",
    duration: " 90",
    interestRate: "1.75",
    totalFees: " 4800",
    proposalStatus: ['Seen'],

  },

]

const MarketingPage = () => {
  return (
    <div className="user-management">
      <MarketingFilterBar />
      <Row>
        <Col md={12}>
          <div className="stats-box">
            <div className="badges-list">
              <div className="badges-item">
                <Badge color={"orange"} text={"Created (1)"} />
              </div>
              <div className="badges-item"><Badge color={"blue"} text={"Sent (5)"} /></div>
              <div className="badges-item"><Badge color={"magenta"} text={"Seen (5)"} /></div>
              <div className="badges-item"><Badge color={"geekblue"} text={"Sales Rep Conneected (5)"} /></div>
              <div className="badges-item"><Badge color={"green"} text={"Lead Converted (5)"} /></div>
              <div className="badges-item"><Badge color={"cyan"} text={"Signed (5)"} /></div>
              <div className="badges-item"><Badge color={"purple"} text={"Accepted (5)"} /></div>
            </div>
          </div>
          <Table
            rowSelection={{
              type: 'checkbox',
            }}
            columns={columns}
            dataSource={data}
            scroll={{
              x: 1500,
              y: 300
            }}
            bordered={false}
            pagination={{ pageSize: 10 }}
          />
        </Col>
      </Row>
    </div>
  );
};

export default MarketingPage;
