import { React} from "react";
import {  Row, Col } from "react-bootstrap";


import {
  
  TermsIcon,

  PrivacyIcon,
  FAQs
} from "../../constants/svg";


import {  NavLink } from "react-router-dom";

const ContentManagement = (props) => {
  return (
        <>
          <section className="settings-body">
            <Row>
              <Col xl={3} lg={3}>
                <div className="settings-bar">
                  <div className="settings-navigation">
                    <ul>
                      <li className="setting-active">
                        <NavLink to="/content/termsandcondition">
                        <div className="content-nav-bg">
                          <span className="settings-icon">
                            <TermsIcon />
                          </span>
                          <span>Terms and Conditions</span>
                          </div>
                        </NavLink>
                      </li>
                      <li>
                        <NavLink to="/content/privacypolicy">
                        <div className="content-nav-bg">
                          <span className="settings-icon">
                            <PrivacyIcon />
                          </span>
                          <span>Privacy Policy</span>
                          </div>
                        </NavLink>
                      </li>
                      <li>
                        <NavLink to="/content/faq">
                        <div className="content-nav-bg">
                          <span className="settings-icon">
                            <FAQs/>
                          </span>
                          <span>FAQs</span>
                          </div>
                        </NavLink>
                      </li>
                    </ul>
                  </div>
                </div>
              </Col>
              <Col xl={9} lg={9}>
                <div className="settings-bar">{props.children}</div>
              </Col>
            </Row>
          </section>
        </>
  );
}

export default ContentManagement