import { React, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router";
import { Eye, EyeClose, Tick } from "../../constants/svg";
import {
  Container,
  Button,
  Row,
  Col,
  FormControl,
  InputGroup,
  Form,
} from "react-bootstrap";
import { toast, ToastContainer } from "react-toastify";

const DeleteAccount = () => {
  const [showPassword, setShowPassword] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);
  const navigate = useNavigate();

  const tostmessages = {
    success: {
      message: "Success",
    },
  };
  const [pass, TogglePass] = useState(true);
  const [passw, TogglePassW] = useState(true);
  const [passcurrent, TogglePasscurrent] = useState(true);

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm({
    mode: "onChange",
  });
  const password = watch("formBasicPassword");

  const onSubmit = async (data) => {
    const { email, formBasicPassword } = data;
    if (
      data.email === "ezfi@tekrevol.com" &&
      data.formBasicPassword === "12345678"
    ) {
      toast.success("Successfully Logedin !", {
        position: toast.POSITION.TOP_RIGHT,
      });
      setTimeout(() => {
        navigate("/dashboard");
      }, 1500);
    } else {
      toast.error("Invalid Login!", {
        position: toast.POSITION.TOP_RIGHT,
      });
      setTimeout(() => {
        navigate("/");
      }, 1500);
    }
  };

  const [values, setValues] = useState({
    amount: "",
    password: "",
    showPassword: false,
  });

  const myHelper = {
    email: {
      required: "Email is Required",
      pattern: "Invalid Email",
    },
    fullname: {
      required: "Full name is Required",
      minLength: "Min length is 25",
      maxLength: "Max length is 255",
    },
    password: {
      required: "Password is Required",
      minLength: "Min length is 8",
      maxLength: "Max length is 255",
    },
    confrmpassword: {
      required: "Confrmpassword is Required",
      minLength: "Min length is 8",
      maxLength: "Max length is 255",
    },
  };

  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };

  const handleClickShowPassword = () => {
    setValues({
      ...values,
      showPassword: !values.showPassword,
    });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  return (
    <div className="deleteaccount-component">
      <div className="deleteaccount-heading">Delete Account</div>

      <Row>
        <Col md={9}>
          <p>
            For security reasons, we need you to kindly confirm your password
            before we proceed to delete your account.
          </p>
        </Col>
      </Row>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Row>
          <Col md={6}>
            <Form.Group
              className="input_setting"
              controlId="formBasicPassword"
            >
              <Form.Label>
                Your Password <span className="form_star">*</span>
              </Form.Label>
              <InputGroup>
                <FormControl
                  placeholder="Enter Password"
                  autoComplete={"off"}
                  {...register("formBasicPassword", {
                    required: {
                      value: true,
                      message: "Password is required",
                    },
                    minLength: {
                      value: 8,
                      message: "Min length is 8",
                    },
                    maxLength: {
                      value: 255,
                      message: "Max length is 255",
                    },
                  })}
                  type={pass ? "password" : "text"}
                />
                <InputGroup.Text
                  id="basic-addon2"
                  onClick={() => TogglePass(!pass)}
                >
                  {pass ? <Eye /> : <EyeClose />}
                </InputGroup.Text>
              </InputGroup>

              {errors.formBasicPassword && (
                <span className={"validation-error"} role="alert">
                  {errors.formBasicPassword.message}
                </span>
              )}
            </Form.Group>
            <Form.Group
              className="input_setting mb_20"
              controlId="formBasicPassword2"
            >
              <Form.Label>
                Confirm Password <span className="form_star">*</span>
              </Form.Label>
              <InputGroup>
                <FormControl
                  placeholder="Enter Password"
                  autoComplete={"off"}
                  {...register("formBasicPassword2", {
                    required: {
                      value: true,
                      message: "Password is required",
                    },
                    minLength: {
                      value: 8,
                      message: "Min length is 8",
                    },
                    maxLength: {
                      value: 255,
                      message: "Max length is 255",
                    },
                    validate: (value) =>
                      value === password || "Password Not Matched",
                  })}
                  type={passw ? "password" : "text"}
                />
                <InputGroup.Text
                  id="basic-addon2"
                  onClick={() => TogglePassW(!passw)}
                >
                  {passw ? <Eye /> : <EyeClose />}
                </InputGroup.Text>
              </InputGroup>

              {errors.formBasicPassword2 && (
                <span className={"validation-error pr-5"} role="alert">
                  {errors.formBasicPassword2.message}
                </span>
              )}
            </Form.Group>
          </Col>
          <Col md={12}>
            <div className="text-end">
              <Button className="save_btn mb-auto" type="submit">
                Confirm
              </Button>
            </div>
          </Col>
        </Row>
      </Form>
    </div>
  );
};

export default DeleteAccount;
