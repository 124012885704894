import React from 'react'
import LocationDetailContent from '../../../components/User&Locations/LocatioinDetail/LocationDetailContent'
import LocationtDetailFilterBar from '../../../components/User&Locations/LocatioinDetail/LocationDetailFilterBar'
import LocationDetailTable from '../../../components/User&Locations/LocatioinDetail/LocationDetailTable'

const LocationDetailPage = () => {
    return (
        <>
            <LocationtDetailFilterBar />
            <LocationDetailContent />
            <div className="stats-box">
                <LocationDetailTable />
            </div>

        </>
    )
}

export default LocationDetailPage