import React from "react";
import { Button, message, Steps, theme } from "antd";
import { useState } from "react";
import { Row, Col } from 'react-bootstrap'
import { WhiteMainLogo } from "../../constants/svg";
import AccountSetup from "./StepsComponents/AccountSetup";
import Accountotp from "../components/NewComponents/Accountotp";
import { NavLink, useNavigate } from "react-router-dom";

const SignUpStepForm = () => {
    const { token } = theme.useToken();
    const [current, setCurrent] = useState(0);
    const [otpMatch, setOtpMatch] = useState(true);
    const navigate = useNavigate();

    //Step Form Steps
    const steps = [
        {
            title: "Setup account",
            content: <AccountSetup />,
            para: "",
        },
        {
            title: "Account Verification",
            content: <Accountotp hideVeirfyBtn={true} setOtpMatch={setOtpMatch} otpMatch={otpMatch} />,
            para: "Enter the verification code sent on your email address.",
        }
    ];
    const next = () => {
        setCurrent(current + 1);
    };
    const prev = () => {
        setCurrent(current - 1);
    };
    const items = steps.map((item) => ({
        key: item.title,
        title: item.title
    }));

    const navigateFunc = () => {
        navigate("/thanks")
    }

    return (
        <>
            <div className="stepsFormContainer">
                <div className="stepsFormWrapper">
                    <Row className="h-100">
                        <Col lg={3}>
                            <div className="stepsSider">
                                <div className="whiteLogo">
                                    <WhiteMainLogo />
                                </div>
                                <div className="sidebarHeading">
                                    <h1 className="fw-bold text-white">Get started</h1>
                                </div>
                                <Steps direction="vertical" current={current} items={items} />
                            </div>
                        </Col>
                        <Col lg={9}>
                            <div className="stepsBody">
                                <div className="stepsContent">
                                    <div className="stepContentHead">
                                        <p className="greenColor fw-bold">STEP {current + 1}/{steps.length}</p>
                                        <h2 className="fw-bold">{steps[current].title}</h2>
                                        <p className="greyColor">  {steps[current].para}</p>
                                    </div>


                                    {steps[current].content}

                                </div>
                                <div className="stepsFooter">
                                    <div class="signupHere d-flex"><p>Already have an account? </p>&nbsp;<NavLink to="/"><h4>Sign in here</h4></NavLink></div>
                                    <div>
                                        {current < steps.length - 1 && (
                                            <Button type="primary" onClick={() => next()}>
                                                Next Step
                                            </Button>
                                        )}

                                        {current > 0 && (
                                            <Button
                                                style={{
                                                    margin: "0 8px"
                                                }}
                                                className="border-0 shadow-none greyColor cancelBtn"
                                                onClick={() => prev()}
                                            >
                                                Previous
                                            </Button>
                                        )}

                                        {current === steps.length - 1 && (
                                            <Button
                                                type="primary"
                                                onClick={navigateFunc}
                                                disabled={otpMatch}
                                            >
                                                Submit
                                            </Button>
                                        )}
                                    </div>
                                </div>
                            </div>

                        </Col>
                    </Row>
                </div>
            </div>
        </>
    );
};
export default SignUpStepForm;
