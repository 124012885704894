import { React, useState, useEffect } from "react";
import { Button } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import OtpInput from "react-otp-input";
import { useForm } from "react-hook-form";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router";

const Accountotp = (props) => {
  const [error, setError] = useState(null);
  const [otp, setOtp] = useState("");
  const navigate = useNavigate();
  const [errorMessage, setErrorMessage] = useState("");
  const [resendEnabled, setResendEnabled] = useState(false);
  const [timer, setTimer] = useState(60);

  useEffect(() => {
    if (timer > 0) {
      const intervalId = setInterval(() => {
        setTimer(timer - 1);
      }, 1000);
      return () => clearInterval(intervalId);
    } else {
      setResendEnabled(true);
      setTimer(60);
    }
  }, [timer]);

  const {
    handleSubmit,
    formState: { errors, isValid },
  } = useForm({
    mode: "onChange",
  });

  const onSubmit = (data) => {
    if (otp === "22222") {

      setError(false);
      toast.success("OTP verified");
      setTimeout(() => {
        navigate("/passwordconfirm");
      }, 1500);
    } else {
      setError(true);
      setErrorMessage("OTP not verified");
      toast.error("Invalid OTP");
    }
  };

  const handleOtpChange = (otp) => {
    setOtp(otp);
    if (otp.length === 0) {
      setError(true);
      setErrorMessage("Please Enter OTP code");
      props.setOtpMatch(true);
    } else if (otp.length > 0 && otp.length < 5) {
      setError(true);
      setErrorMessage("Incomplete OTP code");
      props.setOtpMatch(true);
    } else if (otp === "22222") {
      setError(false);
      props.setOtpMatch(false);
    } else {
      setError(true);
      setErrorMessage("Invalid OTP code");
      props.setOtpMatch(true);
    }
  };



  const handleResendClick = () => {
    setResendEnabled(false);
    setTimer(60);
    setOtp("");
    toast.success("OTP has been sent!");
  };

  return (
    <>
      <ToastContainer />
      <Form onSubmit={handleSubmit(onSubmit)}>
        <div className="otp">
          <OtpInput
            className="otp-input"
            value={otp}
            onChange={(otp) => handleOtpChange(otp)}
            numInputs={5}
            isInputNum={true}
            placeholder="-----"
            hasErrored={error}
            focusStyle="focus"
            disabled={resendEnabled}
          />
          {error && (
            <small className="validation-error text-red ms-1">
              {errorMessage}
            </small>
          )}
        </div>
        {resendEnabled ? (
          <div className="resend-code">
            <span className="d-block blueColor" onClick={handleResendClick}>Resend the code</span>
          </div>
        ) : (
          <div className="resend-code d-flex justify-content-between">
            <span>Resend the code </span>
            <span className="greenColor">{timer} seconds</span>
          </div>
        )}
        {props.hideVeirfyBtn ? null : <div className="main_btn">
          <Button className="main_form_btn" type="submit">
            Verify Now
          </Button>
        </div>}

      </Form>
    </>
  );
};

export default Accountotp;
