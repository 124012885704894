import React, { useState } from "react";
import { Breadcrumb, Button } from "antd";
import { NavLink } from "react-router-dom";
import { EditSquare, PlusIcon } from "../../constants/svg";
import AddNewLocModal from "./AddNewLocModal";
import ContentModal from "../ContentModal";

const LocationDetailFilterBar = (props) => {
  const [contentModal, setContentModal] = useState();
  const [editModal, setEditModal] = useState(false);
  return (
    <>
      <div className="d-flex justify-content-between filter-bar">
        <div className="title-detail">
          <Breadcrumb
            items={[
              {
                title: <NavLink to="/location">Locations</NavLink>,
              },
              {
                title: "Location Detail",
              },
            ]}
          />
          <h1 className="fw-bold">Location Details</h1>
        </div>
        <div className="location-btn-div d-flex justify-content-end align-items-center gap-4">
          <div
            className="cursor-pointer"
            onClick={() => {
              setEditModal(true);
            }}
          >
            <EditSquare />
          </div>
          <Button
            type="primary"
            icon={<PlusIcon />}
            shape="round"
            size="large"
            onClick={() => {
              setContentModal(true);
            }}
          >
            <span className="ms-2">Add New</span>
          </Button>
        </div>
      </div>
      <ContentModal
        title={"Add New Location"}
        content={
          <AddNewLocModal
            setContentModal={setContentModal}
            toastMsg="Location has been added!"
          />
        }
        blueText={"Send"}
        show={contentModal}
        onHide={() => setContentModal(false)}
      />
      <ContentModal
        title={"Edit Location"}
        content={
          <AddNewLocModal
            setContentModal={setEditModal}
            toastMsg={"Location has been edited!"}
          />
        }
        blueText={"Send"}
        show={editModal}
        onHide={() => setEditModal(false)}
      />
    </>
  );
};

export default LocationDetailFilterBar;
