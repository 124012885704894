import React from 'react';
import { Table, Dropdown } from 'antd';
import { NavLink } from 'react-router-dom';
import { UserIcon } from '../../constants/svg';
import { toast } from "react-toastify";


const sendEmail = () => {
    toast.success("Email sent successfully");
}

const items = [
    {
        key: '1',
        label: (
            <p className='m-0' onClick={sendEmail}>
                Resend the Proposal
            </p>

        ),
    }
];

const columns = [
    {
        title: 'User Name',
        dataIndex: 'userName',
        key: 'userName',
        width: 80,
        sorter: (a, b) => a.userName.localeCompare(b.userName),
        render: (text, record) => (
            <NavLink to={record.link}>
                {record.userName}
            </NavLink>
        ),
    },
    {
        title: 'Role',
        dataIndex: 'role',
        key: 'role',
        width: 50,
        sorter: (a, b) => a.role.localeCompare(b.role),
    },
    {
        title: 'Email Address',
        dataIndex: 'email',
        key: 'email',
        width: 10,
        sorter: (a, b) => a.email.localeCompare(b.email),
    },
    {
        title: 'Contact No',
        dataIndex: 'contactNo',
        key: 'contactNo',
        width: 80,
        sorter: (a, b) => a.contactNo - b.contactNo,

    },
    {
        title: 'Location',
        dataIndex: 'location',
        key: 'location',
        width: 150,
        sorter: (a, b) => a.location.localeCompare(b.location),
    },
    {
        title: 'Action',
        key: 'action',
        fixed: 'right',
        width: 30,
        align: 'center',
        render: () =>
            <Dropdown
                menu={{
                    items,
                }}
                trigger={['click']}
                placement="bottomRight"
            >
                <div className='dotted-btn'><UserIcon /></div>
            </Dropdown>
    },
    ,
];

// const data = []

const data = [
    {
        key: '1',
        userName: 'John Brown',
        link: '/user-and-location/patient-detail',
        role: 'Admin',
        email: 'efpyi@example.com',
        contactNo: '+971 891234567',
        location: 'New York No. 1 Lake Park',
    },
    {
        key: '2',
        userName: 'Jane Smith',
        link: '/user-and-location/patient-detail',
        role: 'Manager',
        email: 'janesmith@example.com',
        contactNo: '+971 891234568',
        location: 'Los Angeles No. 2 Lake Park',
    },
    {
        key: '3',
        userName: 'Mike Johnson',
        link: '/user-and-location/patient-detail',
        role: 'Sales',
        email: 'mikejohnson@example.com',
        contactNo: '+971 891234569',
        location: 'Chicago No. 3 Lake Park',
    },
    {
        key: '4',
        userName: 'Sarah Davis',
        link: '/user-and-location/patient-detail',
        role: 'Marketing',
        email: 'sarahdavis@example.com',
        contactNo: '+971 891234570',
        location: 'San Francisco No. 4 Lake Park',
    },
    {
        key: '5',
        userName: 'William Lee',
        link: '/user-and-location/patient-detail',
        role: 'Engineer',
        email: 'williamlee@example.com',
        contactNo: '+971 891234571',
        location: 'Seattle No. 5 Lake Park',
    },
    {
        key: '6',
        userName: 'Emily Johnson',
        link: '/user-and-location/patient-detail',
        role: 'HR',
        email: 'emilyjohnson@example.com',
        contactNo: '+971 891234572',
        location: 'Boston No. 6 Lake Park',
    },
    {
        key: '7',
        userName: 'David Wilson',
        link: '/user-and-location/patient-detail',
        role: 'Accounting',
        email: 'davidwilson@example.com',
        contactNo: '+971 891234573',
        location: 'Houston No. 7 Lake Park',
    },
    {
        key: '8',
        userName: 'Grace Anderson',
        link: '/user-and-location/patient-detail',
        role: 'Developer',
        email: 'graceanderson@example.com',
        contactNo: '+971 891234574',
        location: 'Miami No. 8 Lake Park',
    },
    {
        key: '9',
        userName: 'Oliver Green',
        link: '/user-and-location/patient-detail',
        role: 'Designer',
        email: 'olivergreen@example.com',
        contactNo: '+971 891234575',
        location: 'Denver No. 9 Lake Park',
    },
    {
        key: '10',
        userName: 'Sophia Taylor',
        link: '/user-and-location/patient-detail',
        role: 'Product Manager',
        email: 'sophiataylor@example.com',
        contactNo: '+971 891234576',
        location: 'Atlanta No. 10 Lake Park',
    },
    {
        key: '11',
        userName: 'Ryan Martinez',
        link: '/user-and-location/patient-detail',
        role: 'Engineer',
        email: 'ryanmartinez@example.com',
        contactNo: '+971 891234577',
        location: 'Dallas No. 11 Lake Park',
    },
    {
        key: '12',
        userName: 'Natalie Wright',
        link: '/user-and-location/patient-detail',
        role: 'Customer Service',
        email: 'nataliewright@example.com',
        contactNo: '+971 891234578',
        location: 'Phoenix No. 12 Lake Park',
    },
    {
        key: '13',
        userName: 'Luke Turner',
        link: '/user-and-location/patient-detail',
        role: 'Sales',
        email: 'luketurner@example.com',
        contactNo: '+971 891234579',
        location: 'Portland No. 13 Lake Park',
    },
    {
        key: '14',
        userName: 'Avery Parker',
        link: '/user-and-location/patient-detail',
        role: 'Marketing',
        email: 'averyparker@example.com',
        contactNo: '+971 891234580',
        location: 'Philadelphia No. 14 Lake Park',
    },
    {
        key: '15',
        userName: 'Gabriel Adams',
        link: '/user-and-location/patient-detail',
        role: 'Sales',
        email: 'gabrieladams@example.com',
        contactNo: '+971 891234581',
        location: 'Detroit No. 15 Lake Park',
    },
    {
        key: '16',
        userName: 'Chloe Mitchell',
        link: '/user-and-location/patient-detail',
        role: 'Designer',
        email: 'chloemitchell@example.com',
        contactNo: '+971 891234582',
        location: 'Charlotte No. 16 Lake Park',
    },
    {
        key: '17',
        userName: 'Mason Scott',
        link: '/user-and-location/patient-detail',
        role: 'Engineer',
        email: 'masonscott@example.com',
        contactNo: '+971 891234583',
        location: 'Las Vegas No. 17 Lake Park',
    },
    {
        key: '18',
        userName: 'Lily Baker',
        link: '/user-and-location/patient-detail',
        role: 'Marketing',
        email: 'lilybaker@example.com',
        contactNo: '+971 891234584',
        location: 'Kansas City No. 18 Lake Park',
    },
    {
        key: '19',
        userName: 'Ethan Phillips',
        link: '/user-and-location/patient-detail',
        role: 'Developer',
        email: 'ethanphillips@example.com',
        contactNo: '+971 891234585',
        location: 'Minneapolis No. 19 Lake Park',
    },
    {
        key: '20',
        userName: 'Isabella Adams',
        link: '/user-and-location/patient-detail',
        role: 'Accounting',
        email: 'isabellaadams@example.com',
        contactNo: '+971 891234586',
        location: 'Austin No. 20 Lake Park',
    }

]

const UserTable = () => {
    return (
        <Table
            rowSelection={{
                type: 'checkbox',
            }}
            columns={columns}
            dataSource={data}
            bordered={false}
            pagination={{ pageSize: 10 }}
            scroll={{ x: 'max-content' }}
            responsive={true}
        />
    )
}

export default UserTable