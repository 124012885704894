import React from "react";
import { PlusIcon } from "../../constants/svg";

import { Button } from "antd";
import { NavLink } from "react-router-dom";

const TreatmentPlansFilterBar = () => {
  return (
    <div className="filter-bar d-flex justify-content-between align-items-center">
      <div className="filter-bar__title">
        <h1 className="fw-bold">Treatment Plans</h1>
      </div>
      <NavLink to="/treatment-template">
        <Button type="primary" icon={<PlusIcon />} shape="round" size="large">
          <span className="ms-2 ">Create Treatment Plan</span>
        </Button>
      </NavLink>
    </div>
  );
};

export default TreatmentPlansFilterBar;
