import React, { useState } from "react";
import {
  Form,
  Input,
  Slider,
  Button,
  Space,
  Switch,
  Radio,
  Select,
} from "antd";
import { ArrowDown, BluePlusIcon, Close, PlusIcon } from "../../constants/svg";
import { useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import ContentModal from "../ContentModal";

const { Option } = Select;
const CreatePaymentPlanContent = () => {
  const navigate = useNavigate();
  const [value, setValue] = useState(1);
  const [contentModal, setContentModal] = useState(false);
  const onChange = (e) => {
    console.log("radio checked", e.target.value);
    setValue(e.target.value);
  };

  const onFinish = (values) => {
    navigate("/treatment-plans");
    toast.success("Appointment has been added!", {
      position: "bottom-right",
    });
  };
  const [selectedState, setselectedState] = useState("New York");
  const handleStateChange = (value) => {
    setselectedState(value);
  };
  return (
    <>
      <Form
        onFinish={onFinish}
        initialValues={{
          planName: "Payment Plan 1",
          planDescription:
            "lorem ipsum dolor sit amet, lorem ipsum dolor sit amet, consectetur",
          paymentSource: "split",
          amountSource: "adhoc",
          paymentMode: "upon",
          mini: "28"
        }}
      >
        <Form.Item
          label="Plan Name"
          name="planName"
          labelCol={{ span: 24 }}
          wrapperCol={{ span: 24 }}
          rules={[
            {
              required: true,
              message: "Please input payment name!",
            },
            {
              type: "text",
              message: "Please input valid plane name.",
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Description"
          name="planDescription"
          labelCol={{ span: 24 }}
          wrapperCol={{ span: 24 }}
          rules={[
            {
              required: true,
              message: "Please input your Description!",
            },
          ]}
        >
          <Input.TextArea rows={4} cols={4} />
        </Form.Item>
        <Form.Item
          label="Payment Source"
          name="paymentSource"
          rules={[
            {
              required: true,
              message: "Please select payment source",
            },
          ]}
          labelCol={{ span: 24 }}
          wrapperCol={{ span: 24 }}
        >
          <Radio.Group>
            <Radio value="single">Signle</Radio>
            <Radio value="split">Split</Radio>
          </Radio.Group>
        </Form.Item>
        <Form.Item
          label="Amount Source"
          name="amountSource"
          rules={[
            {
              required: true,
              message: "Please select amount source",
            },
          ]}
          labelCol={{ span: 24 }}
          wrapperCol={{ span: 24 }}
        >
          <Radio.Group>
            <Radio value="treatmentplan">Treatment Plan</Radio>
            <Radio value="adhoc">Adhoc</Radio>
          </Radio.Group>
        </Form.Item>
        <Form.Item
          label="Payment Mode"
          name="paymentMode"
          rules={[
            {
              required: true,
              message: "Please select payment mode",
            },
          ]}
          labelCol={{ span: 24 }}
          wrapperCol={{ span: 24 }}
        >
          <Radio.Group>
            <Radio value="auto">Auto Debit</Radio>
            <Radio value="upon">Upon Confirmation</Radio>
          </Radio.Group>
        </Form.Item>
        <Form.Item
          label="Duration"
          labelCol={{ span: 24 }}
          wrapperCol={{ span: 24 }}
        >
          <Space.Compact compact="true" className="border-0">
            <div className="sliderValueBar">
              <p className="minValue"></p>
              <p className="maxValue">48%</p>
            </div>
            <Form.Item
              name="mini"
              rules={[
                {
                  required: true,
                  message: "Please input your minimum down payment",
                },
              ]}
            >
              <Slider
                min={0}
                max={48}
                tooltip={{
                  open: true,
                  formatter: (value) => `${value} %`,
                  placement: "bottom",
                  getPopupContainer: () =>
                    document.querySelector(".modal-body"),
                }}
              />
            </Form.Item>
          </Space.Compact>
        </Form.Item>
        <Form.Item className="d-flex justify-content-end">
          <Button type="primary" htmlType="submit" shape="round" size="large">
            Create
          </Button>
        </Form.Item>
      </Form>
    </>
  );
};

export default CreatePaymentPlanContent;
