import React from 'react';
import { Breadcrumb, Button } from 'antd';
import { NavLink } from 'react-router-dom';

const OfferDetailFilterBar = () => {
    return (
        <>
            <div className="d-flex justify-content-between filter-bar">
                <div className="title-detail">
                    <Breadcrumb
                        items={[
                            {
                                title: <NavLink to="/discount-offers">Discount Offers</NavLink>
                            },
                            {
                                title: "Offer Details"
                            },
                        ]}
                    />
                    <h1 className='fw-bold'>Offer Details</h1>
                </div>

            </div>


        </>
    )
}

export default OfferDetailFilterBar