import React from "react";
import { Form, Input, Button } from "antd";
import { toast } from "react-toastify";
import { Assets } from "../../constants/images";

const AddNewLocModal = (props) => {
  const onFinish = (values) => {
    props.setContentModal(false);
    toast.success(props.toastMsg, {
      position: "bottom-right",
    });
  };
  return (
    <>
      <Form
        onFinish={onFinish}
        initialValues={{
          locationName:
            "4702 Froe Street West Virginia",
        }}
      >
        <Form.Item
        className="mb-0 pb-2"
          label="Location Name"
          name="locationName"
          labelCol={{ span: 24 }}
          wrapperCol={{ span: 24 }}
          rules={[
            {
              required: false,
              message: "Please input location",
            },
          ]}
        >
          <Input/>
        </Form.Item>

        <img src={Assets.dummyLocation} alt="location" className="img-fluid"/>

        <Form.Item className="d-flex justify-content-end">
          <Button type="primary" htmlType="submit" shape="round" size="large">
            Add
          </Button>
        </Form.Item>
      </Form>
    </>
  );
};

export default AddNewLocModal;
