import { React, useState } from "react";
import { Row, Col } from "react-bootstrap";
import TopBar from "../components/theme/TopBar";
// import TopBar from "../components/TopBar";
import SideBar from "../components/Sidebar";

const DashboardLayout = (props) => {
  return (
    <div>
      {/* <ResponsiveNavbar /> */}
      <div className="dashboardLayoutContainer top-bar">
        <Row>
          <Col xl={2} className="d-none d-xl-block pe-0">
            <SideBar />
            {/* <DashboardSidebar /> */}
          </Col>
          <Col lg={12} xl={10} className="ps-4">
            <TopBar />
            {props.children}
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default DashboardLayout;
