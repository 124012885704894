import React from 'react'
import PatientContent from '../../components/Patients/PatientContent'
import PatientFilterBar from '../../components/Patients/PatientFilterBar'

const PatientPage = () => {
    return (
        <>
            <PatientFilterBar />
            <PatientContent />
        </>
    )
}

export default PatientPage