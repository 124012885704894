import React, { useState } from 'react'
import { Form, Button, Input, Select } from 'antd';
import { Row, Col } from 'react-bootstrap';
import { ArrowDown } from '../../constants/svg';
import { toast, ToastContainer } from "react-toastify";



const AgreementForm = (props) => {
    const [emailSelected, setemailSelected] = useState([]);
    const handleTeamMemberChange = (value) => {
        setemailSelected(value);
    };
    const onFinish = (values) => {
        console.log('Success:', values);
        console.log("props", props)
        toast.success("Agreement has been sent!", {
            position: "bottom-right",
        });
        props.setContentModal(false)
    };
    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };
    const { Option } = Select;


    const handleChange = (value) => {
        console.log(`selected ${value}`);
    };
    return (
        <>
            <ToastContainer />
            <Form
                onFinish={onFinish}
                size={"large"}
            >
                <Row>
                    <Col lg={12} sm={12}>
                        <Form.Item
                            label="Agreement Name"
                            name="agreementName"
                            rules={[
                                {
                                    required: true,
                                    message: "Please enter your agreement name",
                                },
                            ]}
                            labelCol={{ span: 24 }}
                            wrapperCol={{ span: 24 }}

                        >
                            <Input placeholder="Signature" />
                        </Form.Item>
                    </Col>
                    <Col lg={12} sm={12}>
                        <Form.Item
                            label="Send To"
                            name="teamMembers"
                            rules={[
                                {
                                    required: true,
                                    message: "Please select team members!",
                                },
                            ]}
                            labelCol={{ span: 24 }}
                            wrapperCol={{ span: 24 }}
                        >
                            <Select
                                style={{
                                    width: '100%',
                                }}
                                placeholder="Select Email to Sent"
                                onChange={handleChange}

                                popupClassName="popup-menu"
                                options={[
                                    { value: 'johndoe@gmail.com', label: 'johndoe@gmail.com' },
                                    { value: 'janedoe@gmail.com', label: 'janedoe@gmail.com' },
                                    { value: 'pauldeo@gmail.com', label: 'pauldeo@gmail.com' },
                                ]}
                                suffixIcon={<ArrowDown />}
                            />
                        </Form.Item>
                    </Col>
                    <Col lg={12} sm={12}>
                        <Form.Item className='d-flex justify-content-end'>
                            <Button type="primary" htmlType="submit" shape="round" size='large'>
                                Send
                            </Button>
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </>
    )
}

export default AgreementForm