import { React, useState } from "react";
import { GreenTick, UserIcon } from "../constants/svg";
import ThemeModal from "../components/ThemeModal"
import { Dropdown } from "antd";
import ContentModal from "./ContentModal";
import EditPaymentPlanForm from "./PaymentPlans/EditPaymentPlan";

const PaymentCard = (props) => {
  const [contentModal, setContentModal] = useState(false);
  const [showdelete, setShowDelete] = useState(false);
  const items = [
    {
      key: '1',
      name: 'Edit',
      label: (
        <p className='m-0' onClick={() => setContentModal(true)}>
          Edit
        </p>
      )
    },
    {
      key: '2',
      name: 'Delete',
      label: (
        <p className='m-0' onClick={() => setShowDelete(true)}>
          Delete
        </p>
      )
    }
  ]
  return (
    <>
      <div className="financing-card-bg smallCard mt-3 mt-lg-0">
        <div className="financing-card-container">
          <div className="d-flex justify-content-between">
            <div>
              <h3>{props?.PaymentCatdData?.planName}</h3>
            </div>
            {props?.incDropdown ? <div className="user-button">
              <Dropdown
                menu={{
                  items,
                }}
                trigger={['click']}
                placement="bottomRight"
                overlayClassName="financeDropDown"
              >
                <div className='dotted-btn'><UserIcon /></div>
              </Dropdown>
            </div> : null}

          </div>

          <p>
            {props?.PaymentCatdData?.discription}
          </p>
          <hr className="mb_10"></hr>
          <div className="plan-detail-list">
            {props?.PaymentCatdData?.planDetail?.map((d, i) => {
              return (
                <div className="mb_10" key={i}>
                  <span className="me-2">
                    <GreenTick />
                  </span>
                  <span className="payment-source">{d.planField} </span>&nbsp;
                  <span className="third-party">{d.planFieldValue}</span>
                </div>
              )
            })}

          </div>

        </div>
      </div>

      {/*Edit Finance Plan MODAL */}
      <ContentModal
        title={"Edit Payment Plan"}
        content={<EditPaymentPlanForm setContentModal={setContentModal} />}
        blueText={"Send"}
        show={contentModal}
        onHide={() => setContentModal(false)}
      />



      {/* Delete Role MODAL */}
      <ThemeModal
        className="alertModal"
        title={"Alert"}
        content={"Are you sure you want to delete this plan?"}
        blueText={"Delete"}
        cancelText={"Cancel"}
        setShowDelete={setShowDelete}
        show={showdelete}
        onHide={() => setShowDelete(false)}
      />
    </>
  );
};

export default PaymentCard;
