import { Button } from "antd";
import React from "react";

const DeleteLocModal = (props) => {
  return (
    <div className="user-modal">
      <p className="custom-grey-modal-text">Are you sure you want to delete this location?</p>
      <div className="text-end">
        <Button
          type="primary"
          shape="round"
          size="small"
          onClick={() => {
            props.setContentModal(false);
          }}
        >
          Delete
        </Button>
        <Button
          size={"large"}
          className="border-0 shadow-none greyColor cancelBtn"
          onClick={() => {
            props.setContentModal(false);
          }}
        >
          Cancel
        </Button>
      </div>
    </div>
  );
};

export default DeleteLocModal;
