import React from 'react'
import { Row, Col } from 'react-bootstrap';
import AddMemberForm from './AddMemberForm';

const AddMemberContent = () => {
    return (
        <>
            <div className="stats-box">
                <Row>
                    <Col lg={10}>
                        <AddMemberForm />
                    </Col>
                </Row>
            </div>
        </>

    )
}

export default AddMemberContent