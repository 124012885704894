import React from 'react';
import { Table, Tag } from 'antd';
import { Assets } from '../../constants/images';
import { NavLink } from 'react-router-dom';


const downloadPdf = () => {
    fetch(Assets.filepdf) // Modify the path to your PDF file
        .then(response => response.blob())
        .then(blob => {
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.href = url;
            a.download = 'Agreement.pdf';
            document.body.appendChild(a);
            a.click();
            a.remove();
            window.URL.revokeObjectURL(url);
        });
};
const SentAgreementTable = () => {

    const data = [
        {
            key: '1',
            createdDate: '21/02/2022',
            agreementName: 'Signature',
            sentTo: 'pauldeo@gmail.com',
            agreementDocument: 'View Agreement',
            status: 'pending',
        },
        {
            key: '2',
            createdDate: '25/02/2022',
            agreementName: 'Agreement',
            sentTo: 'pauldeo@gmail.com',
            agreementDocument: 'View Agreement',
            status: '-',
        },
        {
            key: '3',
            createdDate: '28/02/2022',
            agreementName: 'Signature',
            sentTo: 'johndoe@gmail.com',
            agreementDocument: 'View Agreement',
            status: 'pending',
        },
        {
            key: '4',
            createdDate: '01/03/2022',
            agreementName: 'Agreement',
            sentTo: 'janedoe@gmail.com',
            agreementDocument: 'View Agreement',
            status: '-',
        },
        {
            key: '5',
            createdDate: '03/03/2022',
            agreementName: 'Signature',
            sentTo: 'johndoe@gmail.com',
            agreementDocument: 'View Agreement',
            status: 'pending',
        },
        {
            key: '6',
            createdDate: '01/03/2022',
            agreementName: 'Agreement',
            sentTo: 'janedoe@gmail.com',
            agreementDocument: 'View Agreement',
            status: '-',
        },
        {
            key: '7',
            createdDate: '03/03/2022',
            agreementName: 'Signature',
            sentTo: 'johndoe@gmail.com',
            agreementDocument: 'View Agreement',
            status: 'pending',
        },
        {
            key: '8',
            createdDate: '01/03/2022',
            agreementName: 'Agreement',
            sentTo: 'janedoe@gmail.com',
            agreementDocument: 'View Agreement',
            status: '-',
        },
        {
            key: '9',
            createdDate: '03/03/2022',
            agreementName: 'Signature',
            sentTo: 'johndoe@gmail.com',
            agreementDocument: 'View Agreement',
            status: 'pending',
        },
        // add more rows as needed
    ];


    const columns = [
        {
            title: 'Created Date',
            dataIndex: 'createdDate',
            sorter: (a, b) => a.createdDate.localeCompare(b.createdDate),
        },
        {
            title: 'Agreement Name',
            dataIndex: 'agreementName',
            sorter: (a, b) => a.agreementName.localeCompare(b.agreementName),
        },
        {
            title: 'Sent To',
            dataIndex: 'sentTo',
            sorter: (a, b) => a.sentTo.localeCompare(b.sentTo),
        },
        {
            title: 'Agreement Document',
            dataIndex: 'agreementDocument',
            sorter: (a, b) => a.agreementDocument.localeCompare(b.agreementDocument),
            render: (text, record) => {
                return (
                    <NavLink to="javascript:" onClick={downloadPdf} className="decoration-underline">
                        {text}
                    </NavLink>
                );
            },
        },
        {
            title: 'Status',
            dataIndex: 'status',
            sorter: (a, b) => a.status.localeCompare(b.status),
            render: (status) => (
                status === 'pending' ? <Tag className='yellowTag text-capitalize'>{status}</Tag> : "-"
            ),
        },
    ];
    return (
        <div className="stats-box">
            <Table
                dataSource={data}
                columns={columns}
                pagination={true}
                rowSelection={{
                    type: 'checkbox',
                }}
                scroll={{ x: 'max-content' }}
                responsive={true}
                size={'large'}
                className={"p-0"}
            />
        </div>
    )
}

export default SentAgreementTable