import React from 'react'
import PatientDetailLarge from '../../../components/PartientDetailLarge/PatientDetailLarge';
import PatientDetailFilterBar from '../../../components/User&Locations/PatientDetail/PatientDetailFilterBar';
import PatientDetailTable from '../../../components/User&Locations/PatientDetail/PatientDetailTable';
import { Assets } from '../../../constants/images';

const PatientDetailPage = () => {
    return (
        <>
            <PatientDetailFilterBar />
            <PatientDetailLarge ImgSrc={Assets.patientLargeImg} />
            <div className="stats-box">
                <h2>Family Members</h2>
                <PatientDetailTable />
            </div>
        </>
    )
}

export default PatientDetailPage