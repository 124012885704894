import React from "react";
import Navigation from "./navigation/Navigation";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const App = () => {
  return <>
    <ToastContainer />
    <Navigation />;
  </>
};

export default App;
