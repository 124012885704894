import React, { useState, useEffect, useRef } from 'react';
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Form, Input, Button, Space, Select, message } from "antd";
import { Eye, EyeClose, ArrowDown } from "../../../constants/svg";
import { useForm } from "react-hook-form";
import { VALIDATIONS_TEXT, Patterns, maxBounds, minBounds } from "../../../constants/app-constants";
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'

const { Option } = Select;

const AccountSetup = () => {
    const [initialValues, setInitialValues] = useState({
    });
    const [showPassword, setShowPassword] = useState(false);
    const [position, setPosition] = useState(null);
    const [phone, setPhone] = useState('');

    const [formValues, setFormValues] = useState({});
    const [formErrors, setFormErrors] = useState({});
    const [isFormValid, setIsFormValid] = useState(false);

    console.log("formValues:", formValues)
    console.log("formErrors:", formErrors)
    console.log("isFormValid:", isFormValid)

    const { register, formState: { errors } } = useForm({
        mode: "onChange",
        defaultValues: initialValues
    });


    const [form] = Form.useForm();
    const onSubmit = (values) => {
        console.log(values);
        toast.success("Account setup successfully", {
            position: toast.POSITION.BOTTOM_RIGHT,
        });
    };
    const handlepositionChange = (value) => {
        setPosition(value);
        console.log(`Selected position: ${value}`);
    };

    const togglePasswordVisibility = () => {
        setShowPassword((prevState) => !prevState);
    };

    const positionsOptions = ['Doctor', 'Hygienist', 'Nurse', 'Manager'];


    //Form Functions
    const handleFormValuesChange = (values) => {
        setFormValues({ ...formValues, ...values });
    };

    const handleFormErrorsChange = (errors) => {
        setFormErrors({ ...formErrors, ...errors });
    };

    const validateFields = async () => {
        try {
            await form.validateFields(); // validate all fields
            setIsFormValid(true); // set isFormValid to true if validation succeeds
        } catch (error) {
            setIsFormValid(false); // set isFormValid to false if validation fails
        }
    };

    return (
        <>
            <Form
                onFinish={onSubmit}
                className="authForm SignInForm"
                initialValues={initialValues}
                onValuesChange={handleFormValuesChange}
                onFieldsChange={validateFields} // Trigger validation on field change
            >
                <Form.Item
                    label="Careprovider / Doctor Name"
                    name="cardDocName"
                    labelCol={{ span: 24 }}
                    wrapperCol={{ span: 24 }}
                    rules={[
                        {
                            required: true,
                            message: VALIDATIONS_TEXT.FIELD_IS_REQUIRED,
                        },
                        {
                            pattern: Patterns.Name,
                            message: VALIDATIONS_TEXT.NAME_PATTERN,
                        },
                        {
                            max: maxBounds.USERNAME,
                            message: VALIDATIONS_TEXT.NAME_MAX,
                        },
                    ]}
                >
                    <Space.Compact compact="true" className="border-0">
                        <Input
                            placeholder="Enter Careprovider / Doctor Name"
                            {...register("cardDocName")}
                        />
                        {errors.cardDocName && (
                            <div className='ant-form-item-explain-error'>{errors.cardDocName.message}</div>
                        )}
                    </Space.Compact>
                </Form.Item>

                <Form.Item
                    label="Password"
                    name="signInPassword"
                    rules={[
                        {
                            required: true,
                            message: VALIDATIONS_TEXT.PASSWORD_REQUIRED,
                        },
                        {
                            min: minBounds.PASSWORD,
                            message: VALIDATIONS_TEXT.PASSWORD_MIN,
                        },
                        {
                            max: maxBounds.PASSWORD,
                            message: VALIDATIONS_TEXT.PASSWORD_MAX,
                        }
                    ]}
                    labelCol={{ span: 24 }}
                    wrapperCol={{ span: 24 }}
                >
                    <Space.Compact compact="true" className="border-0">
                        <Input.Password
                            placeholder="••••••••"
                            iconRender={visible => (visible ?
                                <div className="password-toggle cursor-pointer" onClick={togglePasswordVisibility}>
                                    <Eye />
                                </div>
                                :
                                <div className="password-toggle cursor-pointer" onClick={togglePasswordVisibility}>
                                    <EyeClose />
                                </div>
                            )}
                            autoComplete="off"
                            role="presentation"
                            {...register('signInPassword')}
                        />
                        {errors.signInPassword && <div className='ant-form-item-explain-error'>{errors.signInPassword.message}</div>}
                    </Space.Compact>
                </Form.Item>

                <Form.Item
                    label="Business Phone Number"
                    name="bussinessPhoneNumber"
                    labelCol={{ span: 24 }}
                    wrapperCol={{ span: 24 }}
                >
                    <PhoneInput
                        country={'us'}
                        value={phone}
                        onChange={phone => setPhone(phone)}
                    />
                </Form.Item>

                <Form.Item
                    label="Personal Phone Number"
                    name="personalPhoneNumber"
                    labelCol={{ span: 24 }}
                    wrapperCol={{ span: 24 }}
                >
                    <PhoneInput
                        country={'us'}
                        value={phone}
                        onChange={phone => setPhone(phone)}
                    />
                </Form.Item>


                <Form.Item
                    label="Position"
                    name="position"
                    rules={[
                        {
                            required: true,
                            message: "Please select position",
                        },
                    ]}
                    labelCol={{ span: 24 }}
                    wrapperCol={{ span: 24 }}
                >
                    <Select
                        placeholder="Select position"
                        onChange={handlepositionChange}
                        value={position}
                        suffixIcon={<ArrowDown />}
                        clearIcon={<ArrowDown />}
                        menuItemSelectedIcon={<ArrowDown />}
                    >
                        {positionsOptions.map((position) => (
                            <Option key={position} value={position}>
                                {position}
                            </Option>
                        ))}
                    </Select>
                </Form.Item>

                <Form.Item
                    label=" Address 1"
                    name="houseAddress"
                    labelCol={{ span: 24 }}
                    wrapperCol={{ span: 24 }}
                    rules={[
                        {
                            required: true,
                            message: VALIDATIONS_TEXT.FIELD_IS_REQUIRED,
                        },
                        {
                            pattern: Patterns.Address,
                            message: VALIDATIONS_TEXT.ADDRESS_PATTERN,
                        },
                        {
                            max: maxBounds.ADDRESS,
                            message: VALIDATIONS_TEXT.ADDRESS_MAX,
                        },
                    ]}
                >
                    <Space.Compact compact="true" className="border-0">
                        <Input
                            placeholder="Enter your address"
                            {...register("houseAddress")}
                        />
                        {errors.houseAddress && (
                            <div className='ant-form-item-explain-error'>{errors.houseAddress.message}</div>
                        )}
                    </Space.Compact>
                </Form.Item>
            </Form>
            <ToastContainer />
        </>
    );
};

export default AccountSetup;
