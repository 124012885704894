import React, { useState } from 'react'
import PatientInfoDetailLarge from '../../../components/Patients/PatientInfoDetails/PatientInfoDetailLarge';
import PatientInfoFilterBar from '../../../components/Patients/PatientInfoDetails/PatientInfoFilterBar';
import { Assets } from '../../../constants/images';
import { Tabs } from 'antd'
import AppointmentTable from '../../../components/Patients/PatientInfoDetails/Appointments/AppointmentTable';
import BillingHistorySection from '../../../components/Patients/PatientInfoDetails/BillingHistory/BillingHistorySection';

const PatientInfoDetailPage = () => {
    const [activeKey, setActiveKey] = useState('1');
    const onChange = (key) => {
        setActiveKey(key);
    };
    const items = [
        {
            key: '1',
            label: `Appointment`,
            children: <AppointmentTable />,
        },
        {
            key: '2',
            label: `Billing History`,
            children: <BillingHistorySection />,
        }
    ];
    return (
        <>
            <PatientInfoFilterBar />
            <PatientInfoDetailLarge ImgSrc={Assets.patientLargeImg} />
            <Tabs defaultActiveKey={activeKey} items={items} onChange={onChange} />
        </>
    )
}

export default PatientInfoDetailPage