import React from 'react'
import MembershipPlanDetailContent from '../../components/MembershipPlanDetail/MembershipPlanDetailContent';
import MembershipPlanDetailFilterPlan from '../../components/MembershipPlanDetail/MembershipPlanDetailFilterPlan';

const MembershipPlanDetailPage = () => {
    return (
        <>
            <MembershipPlanDetailFilterPlan />
            <MembershipPlanDetailContent />
        </>
    )
}

export default MembershipPlanDetailPage