import { React, useState } from "react";
import { GreenTick, UserIcon } from "../../constants/svg";
import { Assets } from "../../constants/images";
import { Dropdown } from "antd";
import ContentModal from "../ContentModal";
import parse from 'html-react-parser'
import EditSubscriptionForm from "./EditSubscriptionForm";

const SubscriptionCard = ({ subscriptionData }) => {
    const [contentModal, setContentModal] = useState(false);
    const items = [
        {
            key: '1',
            name: 'Edit',
            label: (
                <p className='m-0' onClick={() => setContentModal(true)}>
                    Edit
                </p>
            )
        }
    ]
    return (
        <>
            <div className="financing-card-bg mt-3 mt-lg-0">
                <div className="financing-card-container">
                    <div className="d-flex justify-content-between">
                        <div>
                            <img className="img-fluid" src={Assets.Star} alt="avatar-img" />
                        </div>
                        <div className="user-button">
                            <Dropdown
                                menu={{
                                    items,
                                }}
                                trigger={['click']}
                                placement="bottomRight"
                                overlayClassName="financeDropDown"
                            >
                                <div className='dotted-btn'><UserIcon /></div>
                            </Dropdown>
                        </div>
                    </div>
                    <h3>{subscriptionData?.planName}</h3>
                    <h3 className="subscriptionPrice">{parse(subscriptionData.planPrice)}</h3>
                    <hr className="mb_10"></hr>
                    <div className="plan-detail-list">
                        {subscriptionData?.planDetail?.map((d, i) => {
                            return (
                                <div className="mb_10" key={i}>
                                    <span className="me-2">
                                        <GreenTick />
                                    </span>
                                    <p className="payment-source m-0 d-inline">{parse(d.planField)} </p>
                                </div>
                            )
                        })}

                    </div>

                </div>
            </div>

            {/*Edit Finance Plan MODAL */}
            {/* <EditFinancingForm setContentModal={setContentModal} /> */}
            <ContentModal
                title={"Edit Subscription Plan"}
                content={<EditSubscriptionForm />}
                blueText={"Update"}
                show={contentModal}
                onHide={() => setContentModal(false)}
            />
        </>
    );
};

export default SubscriptionCard;
