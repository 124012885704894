import React, { useState } from 'react';
import { Dropdown, Space, Checkbox, Input, Button } from 'antd';
import { ArrowDown, FilterIcon, SearchSvg } from '../../constants/svg';


const LeadsFilterBar = () => {
    const [searchText, setSearchText] = useState('');
    const [visible, setVisible] = useState(false);

    const handleSearch = (e) => {
        setSearchText(e.target.value);
    };
    const onChange = (e) => {
        console.log(`checked = ${e.target.checked}`);
    };
    const closeDropdown = () => {
        setVisible(false);
    }
    const handleVisibleChange = (visible) => {
        setVisible(visible);
    };

    const items = [
        {
            label: <div className="menu-search-bar">
                <div className="search-icon">
                    <SearchSvg />
                </div>
                <Input placeholder="Search" value={searchText} onChange={handleSearch} />
            </div>,
            key: '0',
            className: 'no-hover-effect',
        },
        {
            label: <Checkbox onChange={onChange}>Accepted <span className='filter-count'>(09)</span></Checkbox>,
            key: '1',
        },
        {
            label: <Checkbox onChange={onChange}>Rejected <span className='filter-count'>(05)</span></Checkbox>,
            key: '2',
        },
        {
            label: <Checkbox onChange={onChange}>Pending <span className='filter-count'>(02)</span></Checkbox>,
            key: '3',
        },
        {
            label: <div className='apply-button d-flex justify-content-end'>
                <Button onClick={closeDropdown} shape="round" size='large' type='primary'>
                    Apply
                </Button>
            </div>,
            key: '3',
            className: 'no-hover-effect',
        },

    ];

  return (
    <>
      <div className="d-flex justify-content-between">
        <div className="filter-bar discountOfferFilterbar">
          <h1 className="fw-bold m-0">Leads</h1>
        </div>
        <div className="filter-box">
          <Dropdown
            menu={{
              items,
            }}
            trigger={["click"]}
            open={visible}
            placement="bottomLeft"
            className="withSearchBar"
            overlayClassName="search-menu"
            onOpenChange={handleVisibleChange}
          >
            <div className="dropdownboxed filter">
              <div className="icon-holder d-flex align-items-center">
                <FilterIcon />
                <p className="m-0 greyColor ms-2">Accepted</p>
              </div>

              <ArrowDown />
            </div>
          </Dropdown>
        </div>
      </div>
    </>
  );
};

export default LeadsFilterBar;
