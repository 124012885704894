import React from 'react';
import { Breadcrumb, Button } from 'antd';
import { NavLink } from 'react-router-dom';
import { PlusIcon } from '../../../constants/svg';

const PatientInfoFilterBar = () => {
    return (
        <>
            <div className='filter-bar'>
                <div className="d-flex align-item-bottom justify-content-between">
                    <div className="breadcrumbBox">
                        <Breadcrumb
                            items={[
                                {
                                    title: <NavLink to="/patients">Patients</NavLink>
                                },
                                {
                                    title: "Patient Detail"
                                },
                            ]}
                        />
                        <h1 className='fw-bold'>Patient Details</h1>
                    </div>
                    {/* onClick={showDrawer} */}
                    <NavLink to="/patients/patient-detail/add-member">
                        <Button type="primary" icon={<PlusIcon />} shape="round" size='large' >
                            <span className='ms-2 '>Add Member</span>
                        </Button>
                    </NavLink>

                </div>

            </div>
        </>
    )
}

export default PatientInfoFilterBar