import { React, useState } from "react";
import { Row, Col } from "react-bootstrap";

import { EyeHide } from "../../constants/svg";

import { NavLink } from "react-router-dom";

import ThemeModal from "../../components/ThemeModal";
import { Button } from "react-bootstrap";

import Breadcrumb from "react-bootstrap/Breadcrumb";
import AddRole from "../../components/Modals/RoleManagement/AddRole";
import EditRole from "../../components/Modals/RoleManagement/EditRole";
import DeleteRecord from "../../components/Modals/RoleManagement/DeleteRecord";

const RoleManagement = () => {
  const [showrole, setShowRole] = useState(false);

  const [showeditrole, setShowEditRole] = useState(false);
  const [showdelete, setShowDelete] = useState(false);

  return (
    <div className="user-management">
      <Row>
        <Col md={12}>
          <div className="breadcrumbs">
            <Breadcrumb>
              <Breadcrumb.Item>
                <NavLink to="/dashboard">Dashboard</NavLink>
              </Breadcrumb.Item>
              <Breadcrumb.Item active>
                <NavLink to="/rolemanagement">Role Management</NavLink>
              </Breadcrumb.Item>
            </Breadcrumb>
          </div>
        </Col>
      </Row>
      <Row>
        <Col md={8} sm={8}>
          <h2>Role Management</h2>
        </Col>
        <Col md={4} sm={4}>
          <div className="d-flex justify-content-start justify-content-sm-end ">
            <div>
              <Button
                onClick={() => setShowDelete(true)}
                className="eyehide-bg"
              >
                <EyeHide />
              </Button>
            </div>
            <div>
              <Button
                onClick={() => setShowRole(true)}
                className="add-new-btn ms-3"
              >
                + Add New
              </Button>
            </div>
          </div>
        </Col>
      </Row>
      <Row>
        <Col md={12}>
          <div className="table-container">
            <div style={{ height: 650, width: "100%" }}>{/* table */}</div>
          </div>
        </Col>
      </Row>

      {/* ADD Role MODAL */}
      <div className="pages-modals">
        <ThemeModal
          className="ezfi-modal"
          title={"Add Role"}
          content={<AddRole />}
          // footer={<LogoutModalButtons />}
          show={showrole}
          onHide={() => setShowRole(false)}
        />
      </div>
      {/* END OF ADD Role MODAL */}

      {/* Edit Role MODAL */}
      <div className="pages-modals">
        <ThemeModal
          className="ezfi-modal"
          title={"Edit Role"}
          content={<EditRole />}
          show={showeditrole}
          onHide={() => setShowEditRole(false)}
        />
      </div>
      {/* END OF Edit Role MODAL */}

      {/* Delete Role MODAL */}
      <div className="pages-modals">
        <ThemeModal
          className="ezfi-modal"
          title={"Alert"}
          content={<DeleteRecord />}
          show={showdelete}
          onHide={() => setShowDelete(false)}
        />
      </div>
      {/* END OF Delete Role MODAL */}
    </div>
  );
};

export default RoleManagement;
