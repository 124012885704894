import React from 'react';
import { Table, Avatar } from 'antd';
import { Assets } from '../../../constants/images';

const dataSource = [
    {
        key: '1',
        name: 'John Doe',
        contact: '9876543210',
        email: 'johndoe@example.com',
        avatar: Assets.PatientImg,
    },
    {
        key: '2',
        name: 'Jane Smith',
        contact: '9876543211',
        email: 'janesmith@example.com',
        avatar: Assets.PatientImg,
    },
];

const columns = [
    {
        title: '',
        dataIndex: 'avatar',
        key: 'avatar',
        width: '80px',
        align: 'center',
        render: (avatarUrl) => <Avatar src={avatarUrl} />,
    },
    {
        title: 'Patient Name',
        dataIndex: 'name',
        key: 'name',
        sorter: (a, b) => a.name.localeCompare(b.name),
    },
    {
        title: 'Contact No.',
        dataIndex: 'contact',
        key: 'contact',
        sorter: (a, b) => a.contact.localeCompare(b.contact),
    },
    {
        title: 'Email ID',
        dataIndex: 'email',
        key: 'email',
        sorter: (a, b) => a.email.localeCompare(b.email),
    },
];

const OfferDetailTable = () => {
    return (
        <Table
            rowSelection={{ type: 'checkbox' }}
            dataSource={dataSource}
            bordered={false}
            pagination={false}
            columns={columns}
            scroll={{ x: 'max-content' }}
            responsive={true}
            size={'large'}
            className={"p-0"}
        />
    )
}

export default OfferDetailTable