import React from 'react';
import { Row, Col } from 'react-bootstrap';
import SubscriptionCard from '../../components/SubscriptionPlans/SubscriptionCard';

const SubscriptionPlanContent = () => {
    const subscriptionPlanData = [
        {
            id: 1,
            planName: "Starter",
            planPrice: "<span>$99</span>/per month",
            discription: "lorem ipsum dolor sit amet, lorem ipsum dolor sit amet, consectetur adipisicing elit sed do eiusmod tempor incididunt ut labore ",
            planDetail: [
                {
                    id: 1,
                    planField: "1 Location",
                },
                {
                    id: 2,
                    planField: "Card processing fees",
                },
                {
                    id: 3,
                    planField: "<span>EzPay Module:</span> Contactless payments via SMS and mobile app",
                }
            ]
        },
        {
            id: 2,
            planName: "Pro",
            planPrice: "<span>$399</span>/per month",
            discription: "lorem ipsum dolor sit amet, lorem ipsum dolor sit amet, consectetur adipisicing elit sed do eiusmod tempor incididunt ut labore ",
            planDetail: [
                {
                    id: 1,
                    planField: "1 Location",
                },
                {
                    id: 2,
                    planField: "Card processing fees",
                },
                {
                    id: 3,
                    planField: "<span>EzPay Module:</span> Contactless payments via SMS and mobile app",
                },
                {
                    id: 4,
                    planField: "$2 per month per Membership Plan",
                },
                {
                    id: 5,
                    planField: "EzPlan Membership Plan",
                },
                {
                    id: 6,
                    planField: "EzLoan Patient Financing Module",
                }
            ]
        },
        {
            id: 3,
            planName: "Enterprise",
            planPrice: "<span>Custom Price</span>",
            discription: "lorem ipsum dolor sit amet, lorem ipsum dolor sit amet, consectetur adipisicing elit sed do eiusmod tempor incididunt ut labore ",
            planDetail: [
                {
                    id: 1,
                    planField: "1 Location",
                },
                {
                    id: 2,
                    planField: "Card processing fees",
                },
                {
                    id: 3,
                    planField: "<span>EzPay Module:</span> Contactless payments via SMS and mobile app",
                },
                {
                    id: 4,
                    planField: "$2 per month per Membership Plan",
                },
                {
                    id: 5,
                    planField: "EzPlan Membership Plan",
                },
                {
                    id: 6,
                    planField: "EzLoan Patient Financing Module",
                },
                {
                    id: 7,
                    planField: "Custom Pricing for Group Practices and DSOs",
                },
                {
                    id: 8,
                    planField: "Enterprise Dashboard",
                },
                {
                    id: 9,
                    planField: "Reporting Module",
                },
            ]
        }
    ]
    return (
        <>
            <Row>
                {subscriptionPlanData.map((data, index) => {
                    return (
                        <Col xs={12} md={6} lg={4} xl={4} key={index}>
                            <SubscriptionCard subscriptionData={data} />
                        </Col>
                    )
                })}
            </Row>
        </>
    )
}

export default SubscriptionPlanContent