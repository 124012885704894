import React from 'react'
import TreatmentCreateContent from '../../components/TreatmentCreatePlan/TreatmentCreateContent'
import TreatmentCreateFilter from '../../components/TreatmentCreatePlan/TreatmentCreateFilter'

const TreatmentPlanTemplate = () => {
  return (
    <>
        <TreatmentCreateFilter/>
        <TreatmentCreateContent/>
    </>
  )
}

export default TreatmentPlanTemplate