import React, { useState } from 'react'
import { CrossSquared, PlusIcon } from '../../constants/svg';
import { Button, Drawer } from 'antd';
import CreateMembershipPlan from './CreateMembershipPlan/CreateMembershipPlan';

const MembershipPlanFilterbar = () => {
    const [open, setOpen] = useState(false);
    const [placement, setPlacement] = useState('right');
    const showDrawer = () => {
        setOpen(true);
    };
    const onClose = () => {
        setOpen(false);
    };
    return (
        <>
            <div className="filter-bar d-flex justify-content-between align-items-center">
                <div className="filter-bar__title">
                    <h1 className="fw-bold">Membership Plans</h1>
                </div>
                <Button type="primary" icon={<PlusIcon />} shape="round" size="large" onClick={showDrawer}>
                    <span className="ms-2 ">Create New</span>
                </Button>
            </div>
            <Drawer
                title={<h1 className='fw-bold'>Create New Plan</h1>}
                placement={placement}
                size={"large"}
                onClose={onClose}
                closeIcon={<CrossSquared />}
                open={open}
            >
                <CreateMembershipPlan setOpen={setOpen} />
            </Drawer>
        </>
    )
}

export default MembershipPlanFilterbar