import React from 'react'
import OfferDetailContent from '../../../components/DiscountOffers/OfferDetailPage/OfferDetailContent';
import OfferDetailFilterBar from '../../../components/DiscountOffers/OfferDetailPage/OfferDetailFilterBar';
import OfferDetailTable from '../../../components/DiscountOffers/OfferDetailPage/OfferDetailTable';

const OfferDetailPage = () => {
    return (
        <>
            <OfferDetailFilterBar />
            <OfferDetailContent />
            <div className="stats-box">
                <OfferDetailTable />
            </div>

        </>
    )
}

export default OfferDetailPage