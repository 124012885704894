import React, { useState } from "react";
import {
  Form,
  Input,
  Slider,
  Button,
  Space,
  Switch,
  Radio,
  Select,
} from "antd";
import { ArrowDown, BluePlusIcon, Close, PlusIcon } from "../../constants/svg";
import { useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import ContentModal from "../ContentModal";
import CreateDoctorContent from "./CreateDoctorContent";

const { Option } = Select;

const AddAppointmentContent = () => {
  const navigate = useNavigate();
  const [value, setValue] = useState(1);
  const [contentModal, setContentModal] = useState(false);
  const onChange = (e) => {
    console.log("radio checked", e.target.value);
    setValue(e.target.value);
  };

  const onFinish = (values) => {
    navigate("/treatment-plans");
    toast.success("Appointment has been added!", {
      position: "bottom-right",
    });
  };
  const handleCreateDoc = () => {
    setContentModal(true);
  };
  const [selectedState, setselectedState] = useState("New York");
  const handleStateChange = (value) => {
    setselectedState(value);
  };
  return (
    <>
      <Form
        onFinish={onFinish}
        initialValues={{
          appointmentName: "Appointment 1",
          totalTime: "60 Minutes",
          interval: "2 Months",
          cdtCode: "CD21344",
          totalCost: "$2000",
          planDescription:
            "lorem ipsum dolor sit amet, lorem ipsum dolor sit amet, consectetur",
        }}
      >
        <Form.Item
          label="Appointment Name"
          name="appointmentName"
          labelCol={{ span: 24 }}
          wrapperCol={{ span: 24 }}
          rules={[
            {
              required: true,
              message: "Please input appointment name!",
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Total Time (Mins)"
          name="totalTime"
          labelCol={{ span: 24 }}
          wrapperCol={{ span: 24 }}
          rules={[
            {
              required: true,
              message: "Please input appointment time!",
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Interval"
          name="interval"
          labelCol={{ span: 24 }}
          wrapperCol={{ span: 24 }}
          rules={[
            {
              required: true,
              message: "Please input interval!",
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="CDT Code"
          name="cdtCode"
          rules={[
            {
              required: true,
              message: "Please select financing plan!",
            },
          ]}
          labelCol={{ span: 24 }}
          wrapperCol={{ span: 24 }}
        >
          <Select
            style={{ width: "100%" }}
            placeholder="Please select financing plan"
            onChange={handleStateChange}
            value={selectedState}
            suffixIcon={<ArrowDown />}
            removeIcon={<Close />}
            className={"form-dropdown"}
          >
            <Option value="CD21344">CD21344</Option>
            <Option value="CD21345">CD21345</Option>
            <Option value="CD21346">CD21346</Option>
          </Select>
        </Form.Item>
        <Form.Item
          label="Total Cost"
          name="totalCost"
          labelCol={{ span: 24 }}
          wrapperCol={{ span: 24 }}
          rules={[
            {
              required: true,
              message: "Please input interval!",
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Description"
          name="planDescription"
          labelCol={{ span: 24 }}
          wrapperCol={{ span: 24 }}
          rules={[
            {
              required: true,
              message: "Please input your Description!",
            },
          ]}
        >
          <Input.TextArea rows={4} cols={4} />
        </Form.Item>
        <div className="custom-form-btn">
          <label htmlFor="eimg-upload" className="file-upload-label mb-0">
            Treatment Image
          </label>
          <div className="addNewBtn" onClick={handleCreateDoc}>
            <PlusIcon /> <p className="m-0 blueColor">Create Doctor</p>
          </div>
        </div>
        <Form.Item
          label=""
          name="cdtCode"
          rules={[
            {
              required: true,
              message: "Please select financing plan!",
            },
          ]}
          labelCol={{ span: 24 }}
          wrapperCol={{ span: 24 }}
        >
          <Select
            style={{ width: "100%" }}
            placeholder="Please select financing plan"
            onChange={handleStateChange}
            value={selectedState}
            suffixIcon={<ArrowDown />}
            removeIcon={<Close />}
            className={"form-dropdown"}
          >
            <Option value="CD21344">CD21344</Option>
            <Option value="CD21345">CD21345</Option>
            <Option value="CD21346">CD21346</Option>
          </Select>
        </Form.Item>

        <Form.Item className="d-flex justify-content-end">
          <Button type="primary" htmlType="submit" shape="round" size="large">
            Save
          </Button>
        </Form.Item>
      </Form>
      <ContentModal
        title={"Create Doctor"}
        content={<CreateDoctorContent setContentModal={setContentModal} />}
        blueText={"Save"}
        show={contentModal}
        onHide={() => setContentModal(false)}
      />
    </>
  );
};

export default AddAppointmentContent;
