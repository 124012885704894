import React, { useState } from "react";
import {
  Form,
  Input,
  Slider,
  Button,
  Space,
  Switch,
  Radio,
  Select,
} from "antd";
import { ArrowDown, BluePlusIcon, Close, PlusIcon } from "../../constants/svg";
import { useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import ContentModal from "../ContentModal";

const { Option } = Select;

const CreateDoctorContent = (props) => {
  const [selectedState, setselectedState] = useState("New York");
  const navigate = useNavigate();
  const [value, setValue] = useState(1);
  const onChange = (e) => {
    console.log("radio checked", e.target.value);
    setValue(e.target.value);
  };
  const handleStateChange = (value) => {
    setselectedState(value);
  };
  const onFinish = (values) => {
    // navigate("/treatment-plans");

    props.setContentModal(false);
    toast.success("Doctor has been created!", {
        position: "bottom-right",
      });
  };
  return (
    <>
      <Form
        onFinish={onFinish}
        initialValues={{
          docName: "John",
          docEmail: "ash13@gmail.com",
          docPhone: "+01-34885485",
          doclocation: "Select Location",
        }}
      >
        <Form.Item
          label="Doctor Name"
          name="docName"
          labelCol={{ span: 24 }}
          wrapperCol={{ span: 24 }}
          rules={[
            {
              required: true,
              message: "Please input name!",
            },
            {
                type: "text",
                message: "Please input valid name",
              },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Contact Email"
          name="docEmail"
          rules={[
            {
              required: true,
              message: "Please input email ID",
            },
            {
              type: "email",
              message: "Please input valid email ID",
            },
          ]}
          labelCol={{ span: 24 }}
          wrapperCol={{ span: 24 }}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Contact Phone"
          name="docPhone"
          rules={[
            {
              required: true,
              message: "Please input phone number",
            },
            {
              type: "text",
              message: "Please input valid phone number",
            },
          ]}
          labelCol={{ span: 24 }}
          wrapperCol={{ span: 24 }}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Add location"
          name="doclocation"
          rules={[
            {
              required: true,
              message: "Please select location!",
            },
          ]}
          labelCol={{ span: 24 }}
          wrapperCol={{ span: 24 }}
        >
          <Select
            style={{ width: "100%" }}
            placeholder="Select Location"
            onChange={handleStateChange}
            value={selectedState}
            suffixIcon={<ArrowDown />}
            removeIcon={<Close />}
            className={"form-dropdown"}
          >
            <Option value="Location 1">Location 1</Option>
            <Option value="Location 2">Location 2</Option>
            <Option value="Location 3">Location 3</Option>
          </Select>
        </Form.Item>

        <Form.Item className="d-flex justify-content-end">
          <Button type="primary" htmlType="submit" shape="round" size="large">
            Create
          </Button>
        </Form.Item>
      </Form>
    </>
  );
};

export default CreateDoctorContent;
