import React from 'react';

export const Assets = {
    Logo: require("../assets/images/ezFiLogo.png"),
    OnboardingImg: require("../assets/images/onboarding-img.png"),
    ProfileAvatar: require("../assets/images/photo.png"),
    AvatarOne: require("../assets/images/avatarone.png"),
    AvatarTwo: require("../assets/images/avatartwo.png"),
    AvatarThree: require("../assets/images/avatarthree.png"),
    AvatarSetting: require("../assets/images/settingavatar.png"),
    PatientImg: require("../assets/images/patientimg.png"),
    KfcLogo: require("../assets/images/mc.png"),
    Star: require("../assets/images/star.png"),
    RenewalIcon: require("../assets/images/Icon.png"),
    RenewalIconTwo: require("../assets/images/IconTwo.png"),
    RenewalIconThree: require("../assets/images/IconThree.png"),
    RenewalIconFour: require("../assets/images/IconFour.png"),
    EzIconOne: require("../assets/images/ezicon1.png"),
    EzIconTwo: require("../assets/images/ezicon2.png"),
    EzIconThree: require("../assets/images/ezicon3.png"),
    EzIconFour: require("../assets/images/ezicon4.png"),
    EzIconFive: require("../assets/images/ezicon5.png"),
    EzIconSix: require("../assets/images/ezicon6.png"),
    filepdf: require("../assets/images/Admin.pdf"),
    mapImg: require("../assets/images/map-image.png"),
    patientLargeImg: require("../assets/images/patient-large.png"),
    mapMediumImg: require("../assets/images/map-image.png"),
    uploadAttachment: require("../assets/images/uploadattachment.png"),
    uploadImage: require("../assets/images/uploadimage.png"),
    uploadVideo: require("../assets/images/uploadvideo.png"),
    dummyLocation: require("../assets/images/location.png"),
}