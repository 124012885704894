import React from 'react';
import { Breadcrumb } from 'antd';
import { NavLink } from 'react-router-dom';

const AddMemberFilterBar = () => {
    return (
        <div className='filter-bar'>
            <div className="d-flex align-item-bottom justify-content-between">
                <div className="breadcrumbBox">
                    <Breadcrumb
                        items={[
                            {
                                title: <NavLink to="/patients">Patients</NavLink>
                            },
                            {
                                title: <NavLink to="/patients/patient-detail">Patients Details</NavLink>
                            },
                            {
                                title: "Add Member"
                            },
                        ]}
                    />
                    <h1 className='fw-bold'>Add Member</h1>
                </div>
            </div>
        </div>
    )
}

export default AddMemberFilterBar