import React, { useState } from "react";
import { Button, Tag } from "antd";
import { NavLink } from "react-router-dom";
import { Assets } from "../../constants/images";
import ContentModal from "../ContentModal";
import RejectRequestModal from "./RejectRequestModal";

const RequestDetail = (props) => {
 

  return (
    <>
      <div className="main-request-detail">
        <div className="image-name-div">
          <div className="img-div">
            <img src={props.selectedRow.avatar} alt="avatar" className="" />
          </div>
          <span>{props.selectedRow.clientName}</span>
        </div>
        <div className="request-content-div">
          <ul className="greyBlackList">
            <li>
              <p>
                <span className="title">Email ID:</span>
                <span className="text">{props.selectedRow.email}</span>
              </p>
            </li>
            <li>
              <p>
                <span className="title">Contact no.:</span>
                <span className="text">{props.selectedRow.contactNo}</span>
              </p>
            </li>
            <li>
              <p>
                <span className="title">Inquiry:</span>
                <span className="text">{props.selectedRow.inquiry}</span>
              </p>
            </li>
            <li>
              <p>
                <span className="title">Status:</span>
                {props.selectedRow.proposalStatus.map((tag) => {
                  let color =
                    tag.toLowerCase() === "accepted"
                      ? "green"
                      : tag.toLowerCase() === "pending"
                      ? "yellow"
                      : tag.toLowerCase() === "rejected"
                      ? "red"
                      : "";
                  return (
                    <Tag
                      color={color}
                      key={tag}
                      className="mb-2 text-capitalize fw-bold"
                    >
                      {tag}
                    </Tag>
                  );
                })}
              </p>
            </li>
            {props.selectedRow.proposalStatus.includes("Rejected") && (
              <li>
                <p>
                  <span className="title">Reason:</span>
                  <span className="text">{props.selectedRow.reason}</span>
                </p>
              </li>
            )}
          </ul>
        </div>
        {props.selectedRow.proposalStatus.includes("Rejected") ? null : (
          <div className="request-btn-div">
            <div>
              {props.selectedRow.proposalStatus.includes("Pending") && (
                <Button
                  className="border-0 shadow-none greyColor cancelBtn"
                  size="large"
                  onClick={() => {
                    props.setContentModal(false);
                    props.setRejectModal(true)
                  }}
                >
                  Reject
                </Button>
              )}
            </div>
            <NavLink to="/">
              <Button
                type="primary"
                htmlType="submit"
                shape="round"
                size="large"
              >
                Send Proposal
              </Button>
            </NavLink>
          </div>
        )}
      </div>
     
    </>
  );
};

export default RequestDetail;
