import React, { useState } from 'react';
import { Dropdown, Space, Checkbox, Button, Drawer } from 'antd';
import { ArrowDown, CrossSquared, FilterIcon, PlusIcon } from '../../constants/svg';
import AddLocationForm from './AddLocationForm';

const onChange = (e) => {
    console.log(`checked = ${e.target.checked}`);
};
const items = [
    {
        label: <Checkbox onChange={onChange}>Owner</Checkbox>,
        key: '0',
    },
    {
        label: <Checkbox onChange={onChange}>Doctor</Checkbox>,
        key: '1',
    },
    {
        label: <Checkbox onChange={onChange}>Sales coordinator</Checkbox>,
        key: '2',
    },
    {
        label: <Checkbox onChange={onChange}>Treatment coordinator</Checkbox>,
        key: '3',
    },
    {
        label: <Checkbox onChange={onChange}>Finance coordinator</Checkbox>,
        key: '4',
    },
    {
        label: <Checkbox onChange={onChange}>Ops</Checkbox>,
        key: '5',
    },
    {
        label: <Checkbox onChange={onChange}>Admin</Checkbox>,
        key: '6',
    }
];

const UserLocationFilterBar = (props) => {
    const [open, setOpen] = useState(false);
    const [placement, setPlacement] = useState('right');
    const showDrawer = () => {
        setOpen(true);
    };
    const onChange = (e) => {
        setPlacement(e.target.value);
    };
    const onClose = () => {
        setOpen(false);
    };

    return (
        <>
            <div className="filter-bar userLocationFilterBar">
                <div className="d-flex justify-content-between align-items-center">
                    <h1 className='fw-bold m-0'>Users & Locations</h1>
                    {props.activeKey === "1" ? <div className="filter-box">
                        <Dropdown
                            menu={{
                                items,
                            }}
                            trigger={['click']}
                        >
                            <div className="dropdownboxed filter">
                                <div className="icon-holder d-flex align-items-center">
                                    <FilterIcon />
                                    <p className='m-0 greyColor ms-2'>Filter</p>
                                </div>

                                <ArrowDown />
                            </div>
                        </Dropdown>
                    </div> :
                        <Button type="primary" icon={<PlusIcon />} shape="round" size='large' onClick={showDrawer}>
                            <span className='ms-2'>Create Location</span>
                        </Button>
                    }

                </div>

            </div>
            <Drawer
                title={<h1 className='fw-bold'>Add Location</h1>}
                placement={placement}
                size={"large"}
                onClose={onClose}
                closeIcon={<CrossSquared />}
                open={open}
            >
                <AddLocationForm />
            </Drawer>
        </>
    )
}

export default UserLocationFilterBar