import React from 'react';
import FinancingPlanContent from '../../components/FinancingPlan/FinancingPlanContent';
import FinancingPlanFilterBar from '../../components/FinancingPlan/FinancingPlanFilterBar';

const FinancingPlanPage = () => {
    return (
        <>
            <FinancingPlanFilterBar />
            <FinancingPlanContent />
        </>
    )
}

export default FinancingPlanPage