import React from 'react'
import { ThankyouImage } from '../../constants/svg';
import { NavLink } from 'react-router-dom';

const ThankyouPage = () => {
    return (
        <>
            <div className="thankyouPageLayout">
                <div className="thankyoubox">
                    <div className="thankyouImage">
                        <ThankyouImage />
                    </div>
                    <div className="thankyouTitle">
                        <h4 className='fw-bold'>Thank You!</h4>
                    </div>
                    <div className="thankyouPara">
                        <p>Your account request has been sent to the admin. Once you <br/>
                            receive the confirmation on the registered email address, you will<br/>
                            be able to access the EzFi platform.
                        </p>
                    </div>
                    <div class="signupHere d-flex"><p className="m-0">Already have an account? </p>&nbsp;<NavLink to="/"><h4 className="m-0">Sign in here</h4></NavLink></div>
                </div>
            </div>
        </>
    )
}

export default ThankyouPage