import React from 'react';
import { Table, Avatar } from 'antd';
import { Assets } from '../../../constants/images';

const LocationDetailTable = () => {
    const columns = [
        {
            title: 'User ID',
            dataIndex: 'id',
            key: 'id',
        },
        {
            title: 'Full Name',
            dataIndex: 'fullName',
            key: 'fullName',
            sorter: (a, b) => a.fullName.localeCompare(b.fullName),
            sortDirections: ['ascend', 'descend'],
        },
        {
            title: 'Email Address',
            dataIndex: 'email',
            key: 'email',
            sorter: (a, b) => a.email.localeCompare(b.email),
            sortDirections: ['ascend', 'descend'],
        },
        {
            title: 'Role',
            dataIndex: 'role',
            key: 'role',
            sorter: (a, b) => a.role.localeCompare(b.role),
            sortDirections: ['ascend', 'descend'],
        },
        {
            title: 'Password',
            dataIndex: 'password',
            key: 'password',
            sorter: (a, b) => a.password.localeCompare(b.password),
            sortDirections: ['ascend', 'descend'],
        },
    ];

    const data = [
        {
            id: '1',
            fullName: 'John Doe',
            email: 'johndoe@example.com',
            role: 'Admin',
            password: 'asdssd',
        },
        {
            id: '2',
            fullName: 'Jane Smith',
            email: 'janesmith@example.com',
            role: 'User',
            password: 'qwertryty',
        },
        {
            id: '3',
            fullName: 'Bob Johnson',
            email: 'bobjohnson@example.com',
            role: 'User',
            password: 'poiujhgfdswe',
        },
    ];

    return (
        <Table
            columns={columns}
            dataSource={data}
            bordered={false}
            scroll={{ x: 'max-content' }}
            responsive={true}
            size={'large'}
            className={"p-0"}
        />
    )
}

export default LocationDetailTable;