import React from "react";
import { Row, Col } from "react-bootstrap";
import { Assets } from "../../../constants/images";
import { EditSquare } from "../../../constants/svg";

const LocationDetailContent = () => {
  return (
    <div className="locationDetailContent mb-3">
      <Row>
        <Col lg={5}>
          <Row className="mb-2">
            <Col lg={4}>
              <p className="m-0 boldGrey">Location:</p>
            </Col>
            <Col lg={8}>
              <p className="m-0">4702 Froe Street West Virginia</p>
            </Col>
          </Row>
          <Row className="mb-2">
            <Col lg={4}>
              <p className="m-0 boldGrey">Map View:</p>
            </Col>
            <Col lg={8}>
              <img src={Assets.mapMediumImg} alt="" />
            </Col>
          </Row>
          <Row className="mb-2">
            <Col lg={4}>
              <p className="m-0 boldGrey">No. of Employees:</p>
            </Col>
            <Col lg={8}>
              <p className="m-0">04</p>
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
};

export default LocationDetailContent;
