import React, { useContext, useState, useEffect } from 'react'
import { Row, Col, Form, DropdownButton, Dropdown } from "react-bootstrap"
import { NavLink, Link, useNavigate } from 'react-router-dom';
// import NotificationPopup from "../components/Popups/NotificationsPopup";
import AsyncSelect, { components } from 'react-select';
import { Assets } from "../../constants/images";
import { toast, ToastContainer } from "react-toastify";
import {
    Logout, SearchIcon, Notification,
    NormalSearch,
    NoSearchFound,
    SearchSvg,
    SearchSvgLight,
    DropdownSvg,
    SetupIcon,
    SettingsIcon,
    LogoutIconBlack,
} from '../../constants/svg';
import NotificationsPopup from '../Popups/NotificationsPopup';
import { Select, Space } from 'antd';
import LocationDropdown from './LocationDropdown';
import ThemeModal from '../ThemeModal';

const colourStyles = {
    control: styles => ({ ...styles, backgroundColor: 'white' }),
    option: (styles, { data, isDisabled, isFocused, isSelected, }) => {
        return {
            ...styles,
            backgroundColor: isDisabled ? 'red' : 'white',
            cursor: "pointer",
            color: '#989898',
            textAlign: "left",
        }
    },
};


function TopBar(props) {
    const navigate = useNavigate();
    // const [handleLogout, setHandleLogout] = useContext(LogoutPopupContext);
    const [mobileMenu, setMobileMenu] = useState(false);
    const [disable, setDisable] = useState(false);
    const [open, setOpen] = useState(false);
    const [menuOpen, setMenuOpen] = useState(false);
    const [show, setShow] = useState(false);
    const [showlogout, setShowlogout] = useState(false);
    const handleLogout = () => setShowlogout(false);
    const handleShow = () => setShow(true);
    const handleClose = () => setShow(false);
    const [showdelete, setShowDelete] = useState(false);
    console.log(showdelete, "show delete")

    const options = [
        { value: 'Reports', label: 'Reports' },
        { value: 'Revenue', label: 'Revenue' },
        { value: 'Tenure', label: 'Tenure' },
        { value: 'Employees', label: 'Employees' },
        { value: 'Daily Checklist', label: 'Daily Checklist' }
    ];

    const handleCloseDropdown = () => {
        setOpen(false)
    }

    /** React Select Filter icon component */

    const Control = ({ children, ...props }) => (
        <components.Control {...props} >
            <SearchSvg />   {children}
        </components.Control>
    );

    const CustomOption = ({ children, ...props }) => (
        <components.Option {...props}>
            <SearchSvgLight />
            {children}
        </components.Option>
    );

    const SelectMenuButton = ({ children, ...props }) => {
        return (
            <components.MenuList  {...props}>
                {children.length > 0 ? <div className="menuSearchHead  mb-3">
                    <p className='menu-heading m-0 font-weight-bold'>Recent searches</p>
                    <p className='m-0 clearText'>Clear All</p>
                </div> : null
                }

                {children.length > 0 ? children?.map((child) => (
                    <div className='d-flex alig-items-center search-wrapper' onClick={() => navigate('/search')}>
                        <NormalSearch />{child}
                    </div>
                )) : <div className='nosearchResult'>
                    <div className="noSearchImg">
                        <NoSearchFound />
                    </div>
                    <p className='text-center'>No Search Found</p>
                </div>
                }
            </components.MenuList >
        )
    }

    // const [handleNotificationPopup, setHandleNotificationPopup] = useContext(NotificationPopupContext);

    const ProfileBox = () => {
        return (
            <div className="profileBox text-start d-flex justify-content-between align-items-center">
                <img
                    src={Assets.AvatarOne}
                    className="profileImg"
                />
                <div className="profileName">Aaron Martin</div>
                <div><DropdownSvg /></div>
            </div>
        )
    }
    /** LogOut Function */

    const logoutClickFunc = () => {
        setDisable(true);
        setTimeout(function () {
            //code goes here
            setDisable(false);

            toast.success('LoggedOut Successfully', {
                position: "bottom-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
            // navigate("/");
            // setHandleLogout(false)
        }, 0.5); //Time before execution
    };

    // Onclick open menu
    const openMenu = () => {

        setTimeout(() => {
            setMenuOpen(true);
        }, 1000);
    };

    //handle Logout
    const handleShowLogout = () => {
        setShowDelete(true)
    }

    return (
        <>
            {show === true && <NotificationsPopup handleClose={handleClose} />}
            <div className={"topBar"}>
                <ToastContainer />
                <Row>
                    <Col xs={6} sm={6} md={6} lg={props?.searchBarOnly ? 12 : 6} xl={props?.searchBarOnly ? 12 : 6} xxl={props?.searchBarOnly ? 12 : 6}>
                        <LocationDropdown />
                    </Col>
                    {props?.searchBarOnly ? null :
                        <Col xs={6} sm={6} md={6} lg={6} xl={6} xxl={6}>
                            <div className="topBar-left">
                                <ul className={"list-inline text-end"}>
                                    <li className={"list-inline-item"}>
                                        <div className="topBarWrapper">
                                            <div className={`searchBarContainer ${menuOpen ? 'open' : ''}`} onClick={openMenu}>
                                                <AsyncSelect
                                                    className="searchBar"
                                                    classNamePrefix="searchBar"
                                                    loadOptions={() => Promise.resolve(options)}
                                                    options={options}
                                                    placeholder="Search"
                                                    styles={colourStyles}
                                                    // menuIsOpen={true}
                                                    isSearchable
                                                    isClearable
                                                    components={{
                                                        Control,
                                                        IndicatorSeparator: () => null,
                                                        DropdownIndicator: () => null,
                                                        Indicator: () => null,
                                                        MenuList: SelectMenuButton
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    </li>
                                    <li className={"list-inline-item"}>
                                        {/* onClick={() => setHandleNotificationPopup(true)} */}
                                        <div className={"notificationContainer"} onClick={handleShow}>
                                            <Notification />
                                        </div>
                                    </li>
                                    <li className={"list-inline-item"}>
                                        <DropdownButton
                                            open={open}
                                            className="profileBtn"
                                            align="start"
                                            title={<ProfileBox />}
                                            id="dropdown-menu-align-end"
                                            onToggle={() => setOpen(false)}
                                            onSelect={handleCloseDropdown}
                                        >
                                            {/* This will link to this page /settings/myprofile  */}
                                            <Dropdown.Item href="javascript:" onClick={handleCloseDropdown} className={"dropdown-item dropdown-link"}>
                                                <span className="mx-2">
                                                    {/* <SetupIcon /> */}
                                                    <SettingsIcon />
                                                </span>
                                                Settings
                                            </Dropdown.Item>
                                            {/* onClick={() => { setHandleLogout(true) }} */}
                                            <div className={"dropdown-item dropdown-link cursor-pointer"} onClick={handleShowLogout}>
                                                <span className="mx-2">
                                                    <LogoutIconBlack />
                                                </span>
                                                Logout
                                            </div>
                                        </DropdownButton>
                                    </li>
                                </ul>
                                <div className="hamburger-menu close" onClick={() => { setMobileMenu(true) }} >
                                    <label className="menu__btn">
                                        <span></span>
                                    </label>
                                </div>
                            </div>
                        </Col>
                    }
                </Row>
            </div >
            <ThemeModal
                className="alertModal"
                title={"Alert"}
                content={"Are you sure you want to logout"}
                blueText={false}
                cancelText={"No"}
                setShowDelete={setShowDelete}
                btnLink={"/"}
                btnLinkText={"Yes"}
                show={showdelete}
                onHide={() => setShowDelete(false)}
            />
        </>
    )
}

export default TopBar