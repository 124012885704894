import React, { useState } from 'react';
import { Button, Drawer } from 'antd';
import { CrossSquared, PlusIcon } from '../../constants/svg';
import AddPatientForm from './AddPatientForm';


const PatientFilterBar = (props) => {
    const [open, setOpen] = useState(false);
    const [placement, setPlacement] = useState('right');
    const showDrawer = () => {
        setOpen(true);
    };
    const onClose = () => {
        setOpen(false);
    };

    return (
        <>
            <div className="filter-bar PatientFilterBar">
                <div className="d-flex justify-content-between align-items-center">
                    <h1 className='fw-bold m-0'>Patients</h1>
                    <Button type="primary" icon={<PlusIcon />} shape="round" size='large' onClick={showDrawer}>
                        <span className='ms-2 '>Create Patients</span>
                    </Button>

                </div>

            </div>
            <Drawer
                title={<h1 className='fw-bold'>Add Patients</h1>}
                placement={placement}
                size={"large"}
                onClose={onClose}
                closeIcon={<CrossSquared />}
                open={open}
            >
                <AddPatientForm />
            </Drawer>
        </>
    )
}

export default PatientFilterBar