import React from 'react';
import { Container, Row, Col } from "react-bootstrap";
import { GreenDollar, GreenGroup, PinkFileIcon, PurpleDollar, RedDocs, RoundedDocs, RoundedDollar, TotalPatients, YellowDollar, } from "../../constants/svg";

const Statistics = () => {
  return (
    <>
      <div className="stats-box">
        <h2 className='fw-bold'>Revenue Tracking</h2>
        <Row className="mt-4">
          <Col lg={3}>
            <div className="d-flex icon-box">
              <RoundedDollar />
              <div className="ps-3">
                <h3>03</h3>
                <p className="greyColor mb-0">
                  Monthly Collection
                </p>
              </div>
            </div>
          </Col>
          <Col lg={3}>
            <div className="d-flex icon-box">
              <RoundedDocs />
              <div className="ps-3">
                <h3>12</h3>
                <p className="greyColor mb-0">
                  Total Proposals
                  Accepted
                </p>
              </div>
            </div>
          </Col>
          <Col lg={3}>
            <div className="d-flex icon-box">
              <RedDocs />
              <div className="ps-3">
                <h3>16</h3>
                <p className="greyColor mb-0">
                  Monthly Proposals
                  Accepted
                </p>
              </div>
            </div>
          </Col>
          <Col>
            <div className="d-flex icon-box">
              <GreenGroup />
              <div className="ps-3">
                <h3>431</h3>
                <p className="greyColor mb-0">
                  Total
                  Patients
                </p>
              </div>
            </div>
          </Col>
        </Row>
      </div>
      <div className="stats-box">
        <h2 className='fw-bold'>Finance Tracking</h2>
        <Row className="mt-4">
          <Col lg={3}>
            <div className="d-flex icon-box">
              <PinkFileIcon />
              <div className="ps-3">
                <h3>345</h3>
                <p className="greyColor mb-0">
                  No. of Treatment
                  Plan
                </p>
              </div>
            </div>
          </Col>
          <Col lg={3}>
            <div className="d-flex icon-box">
              <PurpleDollar />
              <div className="ps-3">
                <h3>$216,031.7</h3>
                <p className="greyColor mb-0">
                  Total Amount
                </p>
              </div>
            </div>
          </Col>
          <Col lg={3}>
            <div className="d-flex icon-box">
              <GreenDollar />
              <div className="ps-3">
                <h3>$21,839.74</h3>
                <p className="greyColor mb-0">
                  Amount Collected
                </p>
              </div>
            </div>
          </Col>
          <Col>
            <div className="d-flex icon-box">
              <YellowDollar />
              <div className="ps-3">
                <h3>$194,191.96</h3>
                <p className="greyColor mb-0">
                  Amount
                  Remaining
                </p>
              </div>
            </div>
          </Col>
        </Row>
      </div>
    </>
  )
}

export default Statistics