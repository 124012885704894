import React from 'react';
import { Breadcrumb, Button } from 'antd';
import { NavLink } from 'react-router-dom';
import { PlusIcon } from '../../../constants/svg';

const LocationtDetailFilterBar = () => {
    return (
        <>
            <div className="d-flex justify-content-between filter-bar">
                <div className="title-detail">
                    <Breadcrumb
                        items={[
                            {
                                title: <NavLink to="/user-and-location">User & Locations</NavLink>
                            },
                            {
                                title: "Location Detail"
                            },
                        ]}
                    />
                    <h1 className='fw-bold'>Location Details</h1>
                </div>
                {/* note: open this on careprovider screen */}
                {/* <Button type="primary" icon={<PlusIcon />} shape="round" size='large'>
                    <span className='ms-2'>Add New</span>
                </Button> */}

            </div>


        </>
    )
}

export default LocationtDetailFilterBar