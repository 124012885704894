import React, { useContext, useState } from "react";
import {
    MainLogo,
    DashboardIcon,
    Marketing,
    Patients,
    Agreement,
    Subscriptions,
    TreatmentPlan,
    Payment,
    Financing,
    Ezplan,
    Ezpay,
    DiscountOffer,
    UserLocation,
    Settings,
    RoundPlusIcon,
    Logout,
    Leads,
    Membership,
    AppointmentIcon,
    EContract,
    Employees,
    Refund,
} from "../constants/sidebarIcons";
import { NavLink } from "react-router-dom";
import { LockationIcon } from "../constants/svg";
import ThemeModal from "./ThemeModal";

// import { LogoutPopupContext } from "../App"

const SideBar = (props) => {
    // const [handleLogout, setHandleLogout] = useContext(LogoutPopupContext);
    const [isOpen, setIsOpen] = useState(false);
    const [activeIndex, setActiveIndex] = useState(-1);
    const [showdelete, setShowDelete] = useState(false);

    const handleClick = (index) => {
        setActiveIndex(index === activeIndex ? -1 : index);
    }

    //handle Logout
    const handleShowLogout = () => {
        setShowDelete(true)
    }
    const SidebarList = [{
        pageLink: "javascript:",
        pageIcon: <AppointmentIcon />,
        pageName: "Appointments",
    },
    {
        pageLink: "/leads",
        pageIcon: <Leads />,
        pageName: "Leads",
    },
    {
        pageLink: "/membership-plan",
        pageIcon: <Membership />,
        pageName: "Membership",
    },
    {
        pageLink: "javascript:",
        pageIcon: <EContract />,
        pageName: "E-Contracts",
    },
    {
        pageLink: "javascript:",
        pageIcon: <Employees />,
        pageName: "Employees",
    },
    {
        pageLink: "/patients",
        pageIcon: <Patients />,
        pageName: "Patients",
    },
    {
        pageLink: "/location",
        pageIcon: <LockationIcon />,
        pageName: "Locations",
    },
    {
        pageLink: "/discount-offers",
        pageIcon: <DiscountOffer />,
        pageName: "Discount Offers",
    },
    {
        pageLink: "/subscription-plans",
        pageIcon: <Subscriptions />,
        pageName: "Subcriptions",
    },
    {
        pageLink: "/treatment-plans",
        pageIcon: <TreatmentPlan />,
        pageName: "Treatment Plans",
    },
    {
        pageLink: "/payment-plans",
        pageIcon: <Payment />,
        pageName: "Payment Plan",
    }, {
        pageLink: "/financing-plans",
        pageIcon: <Financing />,
        pageName: "Financing Plan",
    },
    {
        pageLink: "/marketing",
        pageIcon: <Marketing />,
        pageName: "Marketing",
    },
    {
        pageLink: "javascript:",
        pageIcon: <Refund />,
        pageName: "Refund",
    },
    {
        pageLink: "javascript:",
        pageIcon: <Payment />,
        pageName: "Payment",
    },
    {
        pageLink: "javascript:",
        pageIcon: <TreatmentPlan />,
        pageName: "Reports",
    },

    ];
    return (
        <>
            <div className={props?.menuState ? `${"dashboardSidebar"} ${"active"}` : `${"dashboardSidebar"} `} >
                <NavLink to="/dashboard" className={"logo-dashboard"}>
                    <MainLogo />
                </NavLink>
                <div className="d-sm-none crossMenu" onClick={() => { props?.setMenuState(false) }}>
                    {/* <CrossSquared /> */}
                </div>
                <ul className="sideBarList">
                    {
                        SidebarList?.map((list, index) => {
                            return (
                                <li key={index} className={`${list.hasSubMenu ? "hasChilList" : ""}`}>
                                    <NavLink className={`link-item nav-link ${list.hasSubMenu ? "has-children" : ""}`} to={list.pageLink}>
                                        <div className={"nav-bg"}>
                                            <span>
                                                {list.pageIcon}
                                            </span>
                                            <span className="ps-2" onClick={() => { props?.setMenuState(false) }}>{list.pageName}</span>


                                            {list?.hasSubMenu ? <ul className={index === activeIndex ? "nav-child-list active" : "nav-child-list"}>
                                                {
                                                    list?.hasSubMenu && list.subMenu.map((subMenuItem, index2) => {
                                                        return (
                                                            <li key={index2} className={"nav-child-item"} >
                                                                <NavLink className={"nav-link"} onClick={() => { props?.setMenuState(false) }} to={subMenuItem.subMenuLink}>
                                                                    {subMenuItem.SubmenuName}
                                                                </NavLink>
                                                            </li>
                                                        )
                                                    })
                                                }
                                            </ul> : null}
                                        </div>
                                    </NavLink>
                                    <div className="plusIcon d-sm-none" onClick={() => handleClick(index)}>
                                        <RoundPlusIcon />
                                    </div>
                                </li>
                            )
                        })
                    }
                    <li className="mt-3" onClick={handleShowLogout}>
                        <div className={"link-item"}>
                            <span>
                                <Logout />
                            </span>
                            <span className="ps-3">Logout</span>
                        </div>
                    </li>
                </ul>

            </div >
            <ThemeModal
                className="alertModal"
                title={"Alert"}
                content={"Are you sure you want to logout"}
                blueText={false}
                cancelText={"No"}
                setShowDelete={setShowDelete}
                btnLink={"/"}
                btnLinkText={"Yes"}
                show={showdelete}
                onHide={() => setShowDelete(false)}
            />
        </>
    )
}

export default SideBar