import React from "react";
import { Dropdown, Space, Checkbox, Button } from "antd";
import { ArrowDown, FilterIcon, PlusIcon } from "../../constants/svg";
import { NavLink } from "react-router-dom";

const onChange = (e) => {
  console.log(`checked = ${e.target.checked}`);
};
const items = [
  {
    label: <Checkbox onChange={onChange}>Careprovider</Checkbox>,
    key: "0",
  },
  {
    label: <Checkbox onChange={onChange}>Location</Checkbox>,
    key: "1",
  },
];

const MarketingFilterBar = () => {
  return (
    <>
      <div className="d-flex justify-content-between">
        <div className="filter-bar MarketingFilterBar">
          <h1 className="fw-bold m-0">Marketing</h1>
        </div>
        <div className="filter-box-wrapper">
          <div className="fiter-create d-flex">
            <div className="filter-box">
              <Dropdown
                menu={{
                  items,
                }}
                trigger={["click"]}
              >
                <div className="dropdownboxed filter">
                  <div className="icon-holder d-flex align-items-center">
                    <FilterIcon />
                    <p className="m-0 greyColor ms-2">Filter</p>
                  </div>

                  <ArrowDown />
                </div>
              </Dropdown>
            </div>
            <div className="filter-bar PatientFilterBar">
              <div className="d-flex justify-content-between ms-3 align-items-center">
                <NavLink to="/marketing/create-proposal">
                <Button
                  type="primary"
                  icon={<PlusIcon />}
                  shape="round"
                  size="large"
                >
                  <span className="ms-2 ">Create Proposal</span>
                </Button>
                </NavLink>

              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default MarketingFilterBar;
