import React from 'react'
import AddMemberContent from '../../../components/Patients/PatientInfoDetails/AddMemberPage/AddMemberContent'
import AddMemberFilterBar from '../../../components/Patients/PatientInfoDetails/AddMemberPage/AddMemberFilterBar'

const AddMemeberPage = () => {
    return (
        <>
            <AddMemberFilterBar />
            <AddMemberContent />
        </>
    )
}

export default AddMemeberPage