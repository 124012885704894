import React, { useContext, useState } from 'react';
import { Modal } from 'react-bootstrap';
import NotificationsCards from "../Cards/NotificationsCards";
import { Assets } from '../../constants/images';
import parse from 'html-react-parser';
import { NoNotificationSvg } from '../../constants/svg';



const NotificationsPopup = (props) => {
    const [notificationDetails, setNotificationDetails] = useState([
        {
            id: "1",
            avatarImage: Assets.AvatarOne,
            notificationText: "<b>Emily Tyler</b> sent you a comment in <b>Drive times</b>",
            time: "2h ago",
        },
        {
            id: "2",
            avatarImage: Assets.AvatarTwo,
            notificationText: "Updated the status of <b>MTD Sales</b> in <b>Store 2</b>",
            time: "6h ago",
        },
        {
            id: "3",
            avatarImage: Assets.AvatarThree,
            notificationText: "Updated the status of <b>MTD Sales</b> in <b>Store 2<b>",
            time: "Today 9:30 am",
        },
        {
            id: "4",
            avatarImage: Assets.AvatarOne,
            notificationText: "<b>Emily Tyler</b> sent you a comment in <b>Drive times</b>",
            time: "Tomorrow 2:30 pm",
        },
        {
            id: "5",
            avatarImage: Assets.AvatarTwo,
            notificationText: "Updated the status of <b>MTD Sales</b> in <b>Store 2<b>",
            time: "Tomorrow 1:45 pm",
        },
        {
            id: "6",
            avatarImage: Assets.AvatarThree,
            notificationText: "Updated the status of <b>MTD Sales</b> in <b>Store 2<b>",
            time: "Sep 12 | 10:54 am",
        },
        {
            id: "7",
            avatarImage: Assets.AvatarThree,
            notificationText: "Updated the status of <b>MTD Sales</b> in <b>Store 2<b>",
            time: "Today 9:30 am",
        },
        {
            id: "8",
            avatarImage: Assets.AvatarOne,
            notificationText: "<b>Emily Tyler</b> sent you a comment in <b>Drive times</b>",
            time: "Tomorrow 2:30 pm",
        },
        {
            id: "9",
            avatarImage: Assets.AvatarTwo,
            notificationText: "Updated the status of <b>MTD Sales</b> in <b>Store 2<b>",
            time: "Tomorrow 1:45 pm",
        },
        {
            id: "10",
            avatarImage: Assets.AvatarThree,
            notificationText: "Updated the status of <b>MTD Sales</b> in <b>Store 2<b>",
            time: "Sep 12 | 10:54 am",
        }
    ]);
    return (
        <>
            <Modal
                className='notification-modal'
                show={true}
                size="md"
                onHide={props?.handleClose}

            >
                <Modal.Header closeButton>
                    <Modal.Title className='heading-small'>Notifications</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="notication-card-container">
                        {notificationDetails?.length > 0 ?
                            notificationDetails?.map((data, index) => {
                                return <NotificationsCards avatarImg={data?.avatarImage} text={parse(data?.notificationText)} time={data?.time} key={index} />
                            })
                            :
                            <div className="noDetailFoundBox">
                                <div className="noDetailFoundImage">
                                    <NoNotificationSvg />
                                </div>
                                <p className="noDetailFoundText m-0">No Notification</p>
                            </div>
                        }
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default NotificationsPopup