import React, { useState } from "react";
import { Table, Dropdown, Drawer } from "antd";
import { NavLink } from "react-router-dom";
import { CrossSquared, UserIcon } from "../../constants/svg";
import { Assets } from "../../constants/images";
import ThemeModal from "../ThemeModal";
import EditLocationForm from "../User&Locations/EditLocationForm";
import DeleteLocModal from "./DeleteLocModal";
import ContentModal from "../ContentModal";
import AddNewLocModal from "./AddNewLocModal";

const onChange = (e) => {
  console.log(`checked = ${e.target.checked}`);
};

const LocationContent = () => {
  const [contentModal, setContentModal] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const [open, setOpen] = useState(false);
  const [placement, setPlacement] = useState("right");
  const showDrawer = () => {
    setOpen(true);
  };
  const onChange = (e) => {
    setPlacement(e.target.value);
  };
  const onClose = () => {
    setOpen(false);
  };

  const items = [
    {
      key: "1",
      name: "Edit",
      label: (
        <p
          className="m-0"
          onClick={() => {
            setEditModal(true);
          }}
        >
          Edit
        </p>
      ),
    },
    {
      key: "2",
      name: "Delete",
      label: (
        <p className="m-0" onClick={() => setContentModal(true)}>
          Delete
        </p>
      ),
    },
  ];

  const columns = [
    {
      title: "Location Id",
      dataIndex: "locationId",
      key: "locationId",
      width: 30,
    },
    {
      title: "Location",
      dataIndex: "location",
      key: "location",
      width: 150,
      render: (text, record) => (
        <>
          <img src={record.mapImg} alt="map-icon" width={45} height={45} />
          <NavLink
            to={record.link}
            className="custom-margin decoration-underline blueColor cursor-pointer"
          >
            {record.locationAdd}
          </NavLink>
        </>
      ),
    },
    // {
    //   title: "",
    //   dataIndex: "locationAdd",
    //   key: "locationAdd",
    //   width: 150,
    //   render: (text, record) => (
    //     <NavLink to={record.link}>{record.locationAdd}</NavLink>
    //   ),
    // },

    {
      title: "Employees",
      dataIndex: "employees",
      key: "employees",
      width: 30,
    },
    {
      title: "Action",
      key: "action",
      fixed: "right",
      width: 30,
      align: "center",
      render: () => (
        <Dropdown
          menu={{
            items,
          }}
          trigger={["click"]}
          placement="bottomRight"
        >
          <div className="dotted-btn">
            <UserIcon />
          </div>
        </Dropdown>
      ),
    },
    ,
  ];

  const data = [
    {
      key: "1",
      locationId: "01",
      mapImg: Assets.mapImg,
      link: "/locations/location-detail",
      contactNo: "+971 25125 125",
      locationAdd: "4702 Froe Street West Virginia",
      employees: "01",
    },
    {
      key: "2",
      locationId: "02",
      mapImg: Assets.mapImg,
      link: "/locations/location-detail",
      address: "Phoenix No. 12 Lake Park",
      contactNo: "+971 25125 125",
      location: "London",
      locationAdd: "4702 Froe Street West Virginia",
      employees: "10",
    },
  ];
  return (
    <>
      <Table
        rowSelection={{
          type: "checkbox",
        }}
        columns={columns}
        dataSource={data}
        bordered={false}
        pagination={{ pageSize: 10 }}
        scroll={{ x: "max-content" }}
        responsive={true}
      />

      <ContentModal
        title={"Alert"}
        content={<DeleteLocModal setContentModal={setContentModal} />}
        blueText={"Send"}
        show={contentModal}
        onHide={() => setContentModal(false)}
      />

      <ContentModal
        title={"Edit Location"}
        content={<AddNewLocModal setContentModal={setEditModal}  toastMsg = {"Location has been edited!"}/>}
        blueText={"Send"}
        show={editModal}
        onHide={() => setEditModal(false)}
      
      />
      <Drawer
        title={<h1 className="fw-bold">Edit Location</h1>}
        placement={placement}
        size={"large"}
        onClose={onClose}
        closeIcon={<CrossSquared />}
        open={open}
      >
        <EditLocationForm />
      </Drawer>
    </>
  );
};

export default LocationContent;
