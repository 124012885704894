import React from 'react'
import { PlusIcon } from '../../../constants/svg';
import { NavLink } from 'react-router-dom';
import { Button, Breadcrumb } from 'antd';

const PayersFilterBar = () => {
    return (
        <div className='filter-bar'>
            <div className="d-flex align-item-bottom justify-content-between">
                <div className="breadcrumbBox">
                    <Breadcrumb
                        items={[
                            {
                                title: <NavLink to="/ezpay/dashboard">EZ Pay Dashboard</NavLink>
                            },
                            {
                                title: "Patient Detail"
                            },
                        ]}
                    />
                    <h1 className='fw-bold'>Payers Listings</h1>
                </div>
                {/* onClick={showDrawer} */}
                <Button type="primary" icon={<PlusIcon />} shape="round" size='large' >
                    <span className='ms-2 '>Create Payers</span>
                </Button>

            </div>

        </div>
    )
}

export default PayersFilterBar