import React, { useState } from 'react';
import { Select } from 'antd';
import { TargetIcon, BluePinLoc, ThinArrowDown } from '../../constants/svg';

const { Option } = Select;

const LocationDropdown = () => {
    const [selectedOption, setSelectedOption] = useState('Wadala Branch');

    const handleLocChange = (value) => {
        setSelectedOption(value);
    };

    const renderOption = (option) => {
        return (
            <Option key={option.value} value={option.value} label={option.label}>
                {option.label}
                {option.value === selectedOption && (
                    <span style={{ marginLeft: 8 }}>
                        <TargetIcon />
                    </span>
                )}
            </Option>
        );
    };

    const options = [
        {
            value: 'Wadala Branch',
            label: 'Wadala Branch',
        },
        {
            value: 'Dadar Branch',
            label: 'Dadar Branch',
        },
        {
            value: 'Mumbai',
            label: 'Mumbai',
        }
    ];

    return (
        <Select
            value={selectedOption}
            onChange={handleLocChange}
            optionLabelProp="label"
            optionFilterProp="label"
            popupClassName="locationDropdownMenu"
            dropdownRender={(menu) => (

                <div>

                    {menu}
                </div>
            )}
            className="locationDropdown"
            suffixIcon={
                <>
                    <span>
                        <ThinArrowDown />
                    </span>
                    <span className='locationIconBlue'>
                        <BluePinLoc />
                    </span>
                </>

            }
        >
            {options.map(renderOption)}
        </Select>
    );
};

export default LocationDropdown;
