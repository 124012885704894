import { useState } from "react";
import dayjs from "dayjs";
import { Form, Input, Radio, DatePicker, Select, Button } from "antd";
import { Row, Col } from "react-bootstrap";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { NavLink, useNavigate } from "react-router-dom";
import {
  ArrowDown,
  CameraIcon,
  Close,
  GreyCalendar,
} from "../../constants/svg";
import { Assets } from "../../constants/images";
import "dayjs/locale/en";
import ThemeModal from "../ThemeModal";
import DeleteRecord from "../Modals/RoleManagement/DeleteRecord";
import MarketingProposalEmail from "./MarketingProposalEmail";

const { Option } = Select;

const dateFormat = "DD/MM/YYYY";

const validateZip = (rule, value, callback) => {
  const regex = /^[0-9]+$/;
  if (regex.test(value)) {
    callback();
  } else {
    callback("Please enter a numerical value");
  }
};

const validatePhoneNumber = (rule, value, callback) => {
  const regex =
    /^\s*(?:\+?(\d{1,3}))?[- (]*(\d{3})[- )]*(\d{3})[- ]*(\d{4})(?: *[x/#]{1}(\d+))?\s*$/;
  if (regex.test(value)) {
    callback();
  } else {
    callback("Please enter a valid phone number!");
  }
};

const MarketingCreateContent = () => {
  const navigate = useNavigate();
  const [patientData, setPatientData] = useState({});
  const [showdelete, setShowDelete] = useState(false);
  const [profilePic, setProfilePic] = useState({
    file: null,
    file_url: null,
  });
  const onFinish = (values) => {
    setPatientData(values);
    console.log("Patient Data: ", values);
    navigate("/marketing");
    toast.success("Your Propsal has been submitted!", {
      position: "bottom-right",
    });
  };

  const [selectedState, setselectedState] = useState("New York");
  const handleStateChange = (value) => {
    setselectedState(value);
  };
  const tostmessages = {
    success: {
      message: "Success",
    },
  };

  const onSubmit = (data) => {};
  return (
    <>
      <div className="stats-box">
        <Form
          onFinish={onFinish}
          initialValues={{
            patientId: "01",
            patientplan: "plan 1",
            paymentplan: "plan 1",
            financeplan: "Plan 1",
            treatmentcost: "$200",
            discount: "$200",
            insurance: "$200",
            agreementname: "$200",
            durationTreatment: "$200",
            totalappointment: "$200",
            gender: "male",
            email: "joedoe@gmail.com",
            planDescription:
              "lorem ipsum dolor sit amet, lorem ipsum dolor sit amet, consectetur",
            dob: dayjs("26/03/2021", dateFormat),
            patientName: "Paul Doe",
            height: "165",
            occupation: "Designer",
            weight: "65",
            phoneNumber: "+01-34885485",
            address: "Street 2, New york city",
            zip: "0000",
            city: "New York",
            state: "New York",
            country: "United States",
            fullName: "John Doe",
            mobile: "+01-34885485",
          }}
        >
          <Row>
            <Col xs={8}>
              <h3 className="fw-bold mb-3">Patient Details</h3>
              <Row>
                <Col lg={6} sm={12}>
                  <Form.Item
                    label="Patient ID"
                    name="patientId"
                    rules={[
                      {
                        required: true,
                        message: "Please select patient id!",
                      },
                    ]}
                    labelCol={{ span: 24 }}
                    wrapperCol={{ span: 24 }}
                  >
                    <Select
                      style={{ width: "100%" }}
                      placeholder="Please select patient id"
                      onChange={handleStateChange}
                      value={selectedState}
                      suffixIcon={<ArrowDown />}
                      removeIcon={<Close />}
                      className={"form-dropdown"}
                    >
                      <Option value="01">01</Option>
                      <Option value="02">02</Option>
                      <Option value="03">03</Option>
                    </Select>
                  </Form.Item>
                </Col>
                <Col lg={6} sm={12}>
                  <Form.Item
                    label="Patient Email ID"
                    name="email"
                    rules={[
                      {
                        required: false,
                        message: "Please input patient email ID",
                      },
                      {
                        type: "email",
                        message: "Please input valid email ID",
                      },
                    ]}
                    labelCol={{ span: 24 }}
                    wrapperCol={{ span: 24 }}
                  >
                    <Input />
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col lg={6} sm={12}>
                  <Form.Item
                    label="Patient Name"
                    name="fullName"
                    rules={[
                      {
                        required: false,
                        message: "Please select patient name!",
                      },
                    ]}
                    labelCol={{ span: 24 }}
                    wrapperCol={{ span: 24 }}
                  >
                    <Select
                      style={{ width: "100%" }}
                      placeholder="Please select patient name"
                      onChange={handleStateChange}
                      value={selectedState}
                      suffixIcon={<ArrowDown />}
                      removeIcon={<Close />}
                      className={"form-dropdown"}
                    >
                      <Option value="Paul Doe">Paul Doe</Option>
                      <Option value="Paul Doe">Paul Doe</Option>
                      <Option value="Paul Doe">Paul Doe</Option>
                    </Select>
                  </Form.Item>
                </Col>
              </Row>

              <h3 className="fw-bold mb-3">Plan Details</h3>
              <Row>
                <Col lg={12} sm={12}>
                  <Form.Item
                    label="Description"
                    name="planDescription"
                    labelCol={{ span: 24 }}
                    wrapperCol={{ span: 24 }}
                    rules={[
                      {
                        required: true,
                        message: "Please input your Description!",
                      },
                    ]}
                  >
                    <Input.TextArea rows={4} cols={4} />
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col lg={6} sm={12}>
                  <Form.Item
                    label="Select Treatment Plan"
                    name="patientplan"
                    rules={[
                      {
                        required: true,
                        message: "Please select treatment plan!",
                      },
                    ]}
                    labelCol={{ span: 24 }}
                    wrapperCol={{ span: 24 }}
                  >
                    <Select
                      style={{ width: "100%" }}
                      placeholder="Please select treatment plan"
                      onChange={handleStateChange}
                      value={selectedState}
                      suffixIcon={<ArrowDown />}
                      removeIcon={<Close />}
                      className={"form-dropdown"}
                    >
                      <Option value="Plan 1">Plan 1</Option>
                      <Option value="Plan 2">Plan 2</Option>
                      <Option value="Plan 3">Plan 3</Option>
                    </Select>
                  </Form.Item>
                </Col>
                <Col lg={6} sm={12}>
                  <Form.Item
                    label="Treatment Cost"
                    name="treatmentcost"
                    rules={[
                      {
                        required: false,
                        message: "Please select treatment plan!",
                      },
                    ]}
                    labelCol={{ span: 24 }}
                    wrapperCol={{ span: 24 }}
                  >
                    <Select
                      style={{ width: "100%" }}
                      placeholder="Please select treatment plan"
                      onChange={handleStateChange}
                      value={selectedState}
                      suffixIcon={<ArrowDown />}
                      removeIcon={<Close />}
                      className={"form-dropdown"}
                    >
                      <Option value="$200">$200</Option>
                      <Option value="$300">$300</Option>
                      <Option value="$400">$400</Option>
                    </Select>
                  </Form.Item>
                </Col>

                <Col lg={6} sm={12}>
                  <Form.Item
                    label="Duration of Treatment (months)"
                    name="durationTreatment"
                    rules={[
                      {
                        required: false,
                        message: "Please input treatment duration",
                      },
                      {
                        type: "text",
                        message: "Please input valid treatment duration",
                      },
                    ]}
                    labelCol={{ span: 24 }}
                    wrapperCol={{ span: 24 }}
                  >
                    <Input />
                  </Form.Item>
                </Col>
                <Col lg={6} sm={12}>
                  <Form.Item
                    label="Total Appointment"
                    name="email"
                    rules={[
                      {
                        required: false,
                        message: "Please input total appointment",
                      },
                      {
                        type: "text",
                        message: "Please input valid total appointment",
                      },
                    ]}
                    labelCol={{ span: 24 }}
                    wrapperCol={{ span: 24 }}
                  >
                    <Input />
                  </Form.Item>
                </Col>
              </Row>

              <h3 className="fw-bold mb-3">Payment Plan</h3>
              <Row>
                <Col lg={6} sm={12}>
                  <Form.Item
                    label="Payment Plan"
                    name="paymentplan"
                    rules={[
                      {
                        required: true,
                        message: "Please select payment plan!",
                      },
                    ]}
                    labelCol={{ span: 24 }}
                    wrapperCol={{ span: 24 }}
                  >
                    <Select
                      style={{ width: "100%" }}
                      placeholder="Please select payment plan"
                      onChange={handleStateChange}
                      value={selectedState}
                      suffixIcon={<ArrowDown />}
                      removeIcon={<Close />}
                      className={"form-dropdown"}
                    >
                      <Option value="Plan 1">Plan 1</Option>
                      <Option value="Plan 2">Plan 2</Option>
                      <Option value="Plan 3">Plan 3</Option>
                    </Select>
                  </Form.Item>
                </Col>
                <Col lg={6} sm={12}>
                  <Form.Item
                    label="Financing Plan"
                    name="financeplan"
                    rules={[
                      {
                        required: true,
                        message: "Please select financing plan!",
                      },
                    ]}
                    labelCol={{ span: 24 }}
                    wrapperCol={{ span: 24 }}
                  >
                    <Select
                      style={{ width: "100%" }}
                      placeholder="Please select financing plan"
                      onChange={handleStateChange}
                      value={selectedState}
                      suffixIcon={<ArrowDown />}
                      removeIcon={<Close />}
                      className={"form-dropdown"}
                    >
                      <Option value="Plan 1">Plan 1</Option>
                      <Option value="Plan 2">Plan 2</Option>
                      <Option value="Plan 3">Plan 3</Option>
                    </Select>
                  </Form.Item>
                </Col>
              </Row>

              <h3 className="fw-bold mb-3">Financing Plan</h3>
              <Row>
                <Col lg={6} sm={12}>
                  <Form.Item
                    label="Financing Plan"
                    name="financeplan"
                    rules={[
                      {
                        required: true,
                        message: "Please select financing plan!",
                      },
                    ]}
                    labelCol={{ span: 24 }}
                    wrapperCol={{ span: 24 }}
                  >
                    <Select
                      style={{ width: "100%" }}
                      placeholder="Please select financing plan"
                      onChange={handleStateChange}
                      value={selectedState}
                      suffixIcon={<ArrowDown />}
                      removeIcon={<Close />}
                      className={"form-dropdown"}
                    >
                      <Option value="Plan 1">Plan 1</Option>
                      <Option value="Plan 2">Plan 2</Option>
                      <Option value="Plan 3">Plan 3</Option>
                    </Select>
                  </Form.Item>
                </Col>
              </Row>

              <h3 className="fw-bold mb-3">Payment, Finance and Insurance</h3>
              <Row>
                <Col lg={6} sm={12}>
                  <Form.Item
                    label="Discount"
                    name="discount"
                    rules={[
                      {
                        required: false,
                        message: "Please input discount",
                      },
                      {
                        type: "text",
                        message: "Please input valid discount",
                      },
                    ]}
                    labelCol={{ span: 24 }}
                    wrapperCol={{ span: 24 }}
                  >
                    <Input />
                  </Form.Item>
                </Col>
              </Row>

              <Row>
                <Col lg={6} sm={12}>
                  <Form.Item
                    label="Insurance"
                    name="insurance"
                    rules={[
                      {
                        required: false,
                        message: "Please input insurance",
                      },
                      {
                        type: "text",
                        message: "Please input valid insurance",
                      },
                    ]}
                    labelCol={{ span: 24 }}
                    wrapperCol={{ span: 24 }}
                  >
                    <Input />
                  </Form.Item>
                </Col>
                <Col lg={6} sm={12} className="my-auto">
                  <Button className="border-btn">Check Eligibility</Button>
                </Col>
              </Row>
              <Row>
                <Col lg={6} sm={12}>
                  <Form.Item
                    label="Agreement Name"
                    name="agreementname"
                    rules={[
                      {
                        required: true,
                        message: "Please input agreement name",
                      },
                      {
                        type: "text",
                        message: "Please input valid agreement name",
                      },
                    ]}
                    labelCol={{ span: 24 }}
                    wrapperCol={{ span: 24 }}
                  >
                    <Input />
                  </Form.Item>
                </Col>
              </Row>

              <Row>
                <Col lg={6} sm={12}>
                  {/* 
                  <label htmlFor="img-upload" className="file-upload">
                    <input
                      type="file"
                      accept=".png, .jpg, .jpeg"
                      id="img-upload"
                      name="avatar"
                      className="d-none"
                      onChange={(e) =>
                        setProfilePic({
                          file: e.target.files[0],
                          file_url: URL.createObjectURL(e.target.files[0]),
                        })
                      }
                    />
                    <img
                      src={
                        profilePic.file_url
                          ? profilePic.file_url
                          : Assets.uploadImg
                      }
                      alt=""
                      className="w-100 h-100 img-upload"
                    />
                  </label> */}
                  <label htmlFor="eimg-upload" className="file-upload-label">
                    Upload Agreement
                  </label>
                  <div className="file-upload-container">
                    <input
                      type="file"
                      accept=".png, .jpg, .jpeg"
                      id="img-upload" // add id here
                      name="avatar"
                      className="file-upload-input"
                      onChange={(e) =>
                        setProfilePic({
                          file: e.target.files[0],
                          file_url: URL.createObjectURL(e.target.files[0]),
                        })
                      }
                    />
                    <label
                      htmlFor="img-upload"
                      className="file-upload-button"
                      onClick={(e) => {
                        e.stopPropagation(); // prevent click event from bubbling up to the label
                      }}
                    ></label>
                    <div
                      className="upload-img-div"
                      onClick={() => {
                        document.getElementById("img-upload").click();
                      }}
                    >
                      <img
                        src={
                          profilePic.file_url
                            ? profilePic.file_url
                            : Assets.uploadAttachment
                        }
                        alt=""
                        className="img-upload"
                      />
                    </div>
                  </div>
                </Col>
              </Row>
            </Col>

            <div className="form-btn-div">
              <Form.Item className="d-flex justify-content-end">
                <Button
                  type="primary"
                  htmlType="submit"
                  shape="round"
                  size="large"
                  className="border-btn"
                >
                  Create
                </Button>
              </Form.Item>
              <Form.Item className="d-flex justify-content-end">
                <Button
                  type="primary"
                  // htmlType="submit"
                  shape="round"
                  size="large"
                  onClick={() => setShowDelete(true)}
                >
                  Create & Send
                </Button>
              </Form.Item>
            </div>
            <ToastContainer />
            <ThemeModal
              className="ezfi-modal"
              title={"Send To"}
              //send email proposal k form ka component banao jo content par pass hojae ga 
              content={<MarketingProposalEmail  />}
              show={showdelete}
          
              onHide={() => setShowDelete(false)}
            />
          </Row>
        </Form>
      </div>
    </>
  );
};

export default MarketingCreateContent;
