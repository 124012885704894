import React from "react";
import MarketingCreateContent from "../../components/MarketingCreateProposal/MarketingCreateContent";
import MarketingCreateFilterbar from "../../components/MarketingCreateProposal/MarketingCreateFilterbar";

const CreateProposal = () => {
  return (
    <>
      <MarketingCreateFilterbar />
      <MarketingCreateContent />
    </>
  );
};

export default CreateProposal;
