import { React, useState } from "react";
import { Row, Col } from "react-bootstrap";

import {  NavLink } from "react-router-dom";
import DropdownButton from "react-bootstrap/DropdownButton";
import Dropdown from "react-bootstrap/Dropdown";
import ThemeModal from "../../components/ThemeModal";
import { Button } from "react-bootstrap";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import AddUser from "../../components/Modals/UserManagement/AddUser";
import EditUser from "../../components/Modals/UserManagement/EditUser";
import DeleteRecord from "../../components/Modals/RoleManagement/DeleteRecord";
import FilterBtn from "../../components/FilterBtn";

const UserManagement = () => {

  const [showaddnew, setShowAddNew] = useState(false);
  const [showdelete, setShowDelete] = useState(false);

  // edit modal
  const [showeditnew, setShowEditNew] = useState(false);
 

  return (
    <>
      <div className="user-management">
        <Row>
          <Col md={12}>
            <div className="breadcrumbs">
              <Breadcrumb>
                <Breadcrumb.Item>
                  <NavLink to="/dashboard">Dashboard</NavLink>
                </Breadcrumb.Item>
                <Breadcrumb.Item active>
                  <NavLink to="/usermanagement">User Management</NavLink>
                </Breadcrumb.Item>
              </Breadcrumb>
            </div>
          </Col>
        </Row>
        <Row>
          <Col md={6}>
            <h2>User Management</h2>
          </Col>
          <Col md={6}>
            <div className="d-flex justify-content-start justify-content-md-end">
              <div className="user-filter-btn">
                <DropdownButton
                  id="dropdown-basic-button"
                  className="user-filter-btn"
                  title={<FilterBtn />}
                >
                  <Dropdown.Item href="#/action-1">Email</Dropdown.Item>
                  <Dropdown.Item href="#/action-2">Role</Dropdown.Item>
                </DropdownButton>
              </div>
              <div>
                <Button
                  onClick={() => setShowAddNew(true)}
                  className="add-new-btn ms-3"
                >
                  + Add New
                </Button>
              </div>
            </div>
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <div className="table-container">
              <div style={{ height: 650, width: "100%" }}>
              {/* table */}
              </div>
            </div>
          </Col>
        </Row>
      </div>

      {/* USER MANAGEMENT MODAL STARTS HERE */}

      {/* ADD USER MODAL */}
      <div className="pages-modals">
        <ThemeModal
          className="ezfi-modal"
          title={"Add User"}
          content={<AddUser />}
          // footer={<LogoutModalButtons />}
          show={showaddnew}
          onHide={() => setShowAddNew(false)}
        />
      </div>
      {/* END OF ADD USER MODAL */}

      {/* Edit USER MODAL */}
      <ThemeModal
        className="ezfi-modal"
        title={"Edit User"}
        content={<EditUser />}
        // footer={<LogoutModalButtons />}
        show={showeditnew}
        onHide={() => setShowEditNew(false)}
      />
      {/* END OF Edit USER MODAL */}

      {/* EDIT USER MODAL */}
      {/* END OF EDIT USER MODAL */}

      {/* Delete User MODAL */}
      <div className="pages-modals">
        <ThemeModal
          className="ezfi-modal"
          title={"Alert"}
          content={<DeleteRecord />}
          show={showdelete}
          onHide={() => setShowDelete(false)}
        />
      </div>
      {/* END OF Delete User MODAL */}
      {/* USER MANAGEMENT MODAL ENDS HERE */}
    </>
  );
};

export default UserManagement;
