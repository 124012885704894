import React from 'react';
import { Table, Avatar } from 'antd';
import { Assets } from '../../../constants/images';

const PatientDetailTable = () => {
    const columns = [
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            render: (text, record) => (
                <div>
                    <Avatar size="medium" src={record.avatar} style={{ marginRight: 8 }} />
                    {record.name}
                </div>
            ),
        },
        {
            title: 'Age',
            dataIndex: 'age',
            key: 'age',
            sorter: (a, b) => a.age - b.age,
        },
        {
            title: 'Gender',
            dataIndex: 'gender',
            key: 'gender',
            sorter: (a, b) => a.gender.localeCompare(b.gender),
        },
        {
            title: 'Date of Birth',
            dataIndex: 'dob',
            key: 'dob',
            sorter: (a, b) => new Date(a.dob) - new Date(b.dob),
        },
        {
            title: 'Relation',
            dataIndex: 'relation',
            key: 'relation',
            sorter: (a, b) => a.relation.localeCompare(b.relation),
        },
        {
            title: 'Email ID',
            dataIndex: 'email',
            key: 'email',
            sorter: (a, b) => a.email.localeCompare(b.email),
        },
    ];

    const data = [
        {
            key: '1',
            name: 'John Doe',
            age: 30,
            gender: 'Male',
            dob: '1992-05-20',
            relation: 'Self',
            email: 'johndoe@example.com',
            avatar: Assets.PatientImg,
        },
        {
            key: '2',
            name: 'Jane Doe',
            age: 25,
            gender: 'Female',
            dob: '1996-08-15',
            relation: 'Spouse',
            email: 'janedoe@example.com',
            avatar: Assets.PatientImg,
        },
        {
            key: '3',
            name: 'Bob Smith',
            age: 45,
            gender: 'Male',
            dob: '1976-03-10',
            relation: 'Father',
            email: 'bobsmith@example.com',
            avatar: Assets.PatientImg,
        },
    ];

    return (
        <Table
            columns={columns}
            dataSource={data}
            bordered={false}
            scroll={{ x: 'max-content' }}
            responsive={true}
            size={'large'}
            className={"p-0"}
        />
    )
}

export default PatientDetailTable;