import React from 'react';
import { Avatar, Tag } from 'antd';
import { Row, Col } from 'react-bootstrap';
import { Envelope, Loc, Telephone, BlueUser } from '../../constants/svg';

const PatientDetailLarge = (props) => {
    return (
        <div className="patient-wrap stats-box">
            <Row>
                <Col lg={5}>
                    <Row>
                        <Col lg={4}>
                            <Avatar src={props.ImgSrc} size={{ xs: 24, sm: 32, md: 64, lg: 96, xl: 120, xxl: 180 }} />
                        </Col>
                        <Col lg={8}>
                            <div className="patient-detail-box">
                                <h3 className='name mb-2'>Paul Doe</h3>
                                <p className='detail-heading'>Personal Details</p>
                                <ul className="patient-detail-list">
                                    <li className="patient-item">
                                        <span className="patient-icon"><Envelope /></span>
                                        <span className="patient-item-value">PaulDoe@gmail.com</span>
                                    </li>
                                    <li className="patient-item">
                                        <span className="patient-icon"><Telephone /></span>
                                        <span className="patient-item-value">+912656348455</span>
                                    </li>
                                    <li className="patient-item">
                                        <span className="patient-icon"><Loc /></span>
                                        <span className="patient-item-value">Mumbai</span>
                                    </li>
                                    <li className="patient-item">
                                        <span className="patient-icon"><BlueUser /></span>
                                        <span className="patient-item-value">Sub-account</span>
                                    </li>
                                </ul>
                            </div>
                        </Col>
                    </Row>
                </Col>
            </Row>
            <div className="d-flex justify-content-end">
                <Tag className="greenTag">Accepted</Tag>
            </div>

        </div>
    )
}

export default PatientDetailLarge