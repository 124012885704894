import React, { useState } from 'react';
import { Form, Input, Slider, Button, Space, Switch, Radio } from 'antd';

const EditPaymentPlanForm = (props) => {
    const [value, setValue] = useState(1);
    const onChange = (e) => {
        console.log('radio checked', e.target.value);
        setValue(e.target.value);
    };

    const onFinish = (values) => {
        console.log('Slider value:', values);

    }
    return (
        <>
            <Form
                onFinish={onFinish}
                initialValues={{
                    planName: "Finance Plan 1",
                    planDescription: "lorem ipsum dolor sit amet, lorem ipsum dolor sit amet, consectetur",
                    miniDownPayment: "50",
                    interestRate: "28",
                    discountEligibility: "28",
                    paymentSource: 1,
                    amountSource: 1,
                    paymentMode: 2
                }}
            >
                <h6 className='mb-4'><b>Set Payment Plan Terms*</b></h6>
                <Form.Item
                    label="Plan Name"
                    name="planName"
                    labelCol={{ span: 24 }}
                    wrapperCol={{ span: 24 }}
                    rules={[
                        {
                            required: true,
                            message: 'Please input your Plan Name!',
                        },
                    ]}
                >
                    <Input />
                </Form.Item>

                <Form.Item
                    label="Description"
                    name="planDescription"
                    labelCol={{ span: 24 }}
                    wrapperCol={{ span: 24 }}
                    rules={[
                        {
                            required: true,
                            message: 'Please input your Description!',
                        },
                    ]}
                >
                    <Input.TextArea rows={4} cols={4} />
                </Form.Item>
                <Form.Item
                    label="Payment Source"
                    name="paymentSource"
                    labelCol={{ span: 24 }}
                    wrapperCol={{ span: 24 }}
                    rules={[
                        {
                            required: true,
                            message: 'Please select this field',
                        },
                    ]}
                >
                    <Radio.Group onChange={onChange} value={value}>
                        <Radio value={1}>Single</Radio>
                        <Radio value={2}>Split</Radio>
                    </Radio.Group>
                </Form.Item>
                <Form.Item
                    label="Amount Source"
                    name="amountSource"
                    labelCol={{ span: 24 }}
                    wrapperCol={{ span: 24 }}
                    rules={[
                        {
                            required: true,
                            message: 'Please select this field',
                        },
                    ]}
                >
                    <Radio.Group onChange={onChange} value={value}>
                        <Radio value={1}>Treatment Plan</Radio>
                        <Radio value={2}>Adhoc</Radio>
                    </Radio.Group>
                </Form.Item>
                <Form.Item
                    label="Payment Mode"
                    name="paymentMode"
                    labelCol={{ span: 24 }}
                    wrapperCol={{ span: 24 }}
                    rules={[
                        {
                            required: true,
                            message: 'Please select this field',
                        },
                    ]}
                >
                    <Radio.Group onChange={onChange} value={value}>
                        <Radio value={1}>Auto Debit</Radio>
                        <Radio value={2}>Upon Confirmation</Radio>
                    </Radio.Group>
                </Form.Item>
                <Form.Item
                    label="Additional Discount Eligibility"
                    labelCol={{ span: 24 }}
                    wrapperCol={{ span: 24 }}
                >
                    <Space.Compact compact="true" className='border-0'>
                        <div className="sliderValueBar">
                            <p className='minValue'>0%</p>
                            <p className='maxValue'>100%</p>
                        </div>
                        <Form.Item
                            name="discountEligibility"
                            rules={[{ required: true, message: 'Please input your discount eligibility', }]}
                        >
                            <Slider
                                min={0}
                                max={100}
                                tooltip={{
                                    open: true,
                                    formatter: (value) => `${value}%`,
                                    placement: 'bottom',
                                    getPopupContainer: () => document.querySelector('.modal-body'),
                                }}
                            />
                        </Form.Item>
                    </Space.Compact>
                </Form.Item>

                <Form.Item className='d-flex justify-content-end'>
                    <Button type="primary" htmlType="submit" shape="round" size='large'>
                        Update
                    </Button>
                </Form.Item>
            </Form>
        </>
    )
}

export default EditPaymentPlanForm