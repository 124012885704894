import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import { Table, Tag } from 'antd';

const data = [
    {
        key: '1',
        code: 'ABC001',
        name: 'John',
        treatmentPlan: 'Plan A',
        startDate: '2022-01-01',
        endDate: '2022-02-01',
        discount: '10%',
        userLimit: '5',
        status: 'active',
    },
    {
        key: '2',
        code: 'ABC002',
        name: 'Sarah',
        treatmentPlan: 'Plan B',
        startDate: '2022-02-01',
        endDate: '2022-03-01',
        discount: '15%',
        userLimit: '10',
        status: 'inactive',
    },
];

const columns = [
    {
        title: 'Code',
        dataIndex: 'code',
        key: 'code',
        render: (text, record) => (
            <NavLink to={`/discount-offers/offer-detail`}>{text}</NavLink>
        )
    },
    {
        title: 'Name',
        dataIndex: 'name',
        key: 'name',
        sorter: (a, b) => a.name.localeCompare(b.name),
    },
    {
        title: 'Treatment Plan',
        dataIndex: 'treatmentPlan',
        key: 'treatmentPlan',
        sorter: (a, b) => a.treatmentPlan.localeCompare(b.treatmentPlan),
    },
    {
        title: 'Start Date',
        dataIndex: 'startDate',
        key: 'startDate',
        sorter: (a, b) => a.startDate.localeCompare(b.startDate),
    },
    {
        title: 'End Date',
        dataIndex: 'endDate',
        key: 'endDate',
        sorter: (a, b) => a.endDate.localeCompare(b.endDate),
    },
    {
        title: 'Discount %',
        dataIndex: 'discount',
        key: 'discount',
        sorter: (a, b) => a.discount.localeCompare(b.discount),
    },
    {
        title: 'User Limit',
        dataIndex: 'userLimit',
        key: 'userLimit',
        sorter: (a, b) => a.userLimit - b.userLimit,
    },
    {
        title: 'Status',
        dataIndex: 'status',
        key: 'status',
        sorter: (a, b) => a.status.localeCompare(b.status),
        render: (text) =>
            text === 'active' ? (
                <Tag color="green" className="greenTag">
                    Active
                </Tag>
            ) : (
                <Tag color="yellow" className="yellowTag">
                    Inactive
                </Tag>
            ),
    },
];


const AllDiscountTable = () => {
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);

    const onSelectChange = (selectedRowKeys) => {
        setSelectedRowKeys(selectedRowKeys);
    };

    const rowSelection = {
        selectedRowKeys,
        onChange: onSelectChange,
    };
    return (
        <div className="stats-box">
            <Table
                rowSelection={{
                    type: 'checkbox',
                }}
                className={"p-0"}
                columns={columns}
                dataSource={data}
                bordered={false}
                pagination={true}
            />
        </div>
    )
}

export default AllDiscountTable