import React from 'react';
import { Tag } from 'antd';

const OfferDetailContent = () => {
    return (
        <div className="stats-box offer-detail-content">
            <h5 className='mb-3'>ABC Discount Offer</h5>
            <ul className='offerList'>
                <li className='mb-2'>
                    <p className='d-flex align-items-center'><strong>Code:</strong> 01</p>
                </li>
                <li className='mb-2'>
                    <p className='d-flex align-items-center'><strong>Treatment Plan:</strong> Tratment Plan 01</p>
                </li>
                <li className='mb-2'>
                    <p className='d-flex align-items-center'><strong>Start Date:</strong> 01/01/2022</p>
                </li>
                <li className='mb-2'>
                    <p className='d-flex align-items-center'><strong>End Date:</strong> 01/01/2022</p>
                </li>
                <li className='mb-2'>
                    <p className='d-flex align-items-center'><strong>Discount %:</strong> 50</p>
                </li>
            </ul>
            <div className="d-flex justify-content-end">
                <Tag className="greenTag">Active</Tag>
            </div>
        </div>
    )
}

export default OfferDetailContent