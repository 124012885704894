import React from 'react';
import { Breadcrumb } from 'antd';
import { NavLink } from 'react-router-dom';

const PatientDetailFilterBar = () => {
    return (
        <>
            <div className='filter-bar'>
                <Breadcrumb
                    items={[
                        {
                            title: <NavLink to="/user-and-location">User & Locations</NavLink>
                        },
                        {
                            title: "Patient Detail"
                        },
                    ]}
                />
                <h1 className='fw-bold'>Patient Details</h1>
            </div>
        </>
    )
}

export default PatientDetailFilterBar