import { React, useState } from "react";
import { Row, Col } from "react-bootstrap";
import { Dropdown, Drawer } from "antd"
import {
    Telephone,
    Envelope,
    Loc,
    UserIcon,
    CrossSquared
} from "../../constants/svg";
import EditPatientForm from "./EditPatientForm";
import ThemeModal from "../../components/ThemeModal"
import { NavLink } from "react-router-dom";

const PatientInfoCard = ({ patientdata }) => {
    const [showdelete, setShowDelete] = useState(false);
    const [contentModal, setContentModal] = useState(false);
    const [open, setOpen] = useState(false);
    const [placement, setPlacement] = useState('right');
    const showDrawer = () => {
        setOpen(true);
    };
    const onClose = () => {
        setOpen(false);
    };

    const items = [
        {
            key: '1',
            name: 'familyMemebers',
            label: (
                <p className='m-0' onClick={() => setContentModal(true)}>
                    Family Members
                </p>
            )
        },
        {
            key: '2',
            name: 'Edit',
            label: (
                <p className='m-0' onClick={showDrawer}>
                    Edit
                </p>
            )
        },
        {
            key: '3',
            name: 'deactivate',
            label: (
                <p className='m-0' onClick={() => setShowDelete(true)}>
                    Deactivate
                </p>
            )
        }
    ]

    return (
        <>

            <div className="patient-card-bg">
                <div className="patient-card-container">
                    <div className="d-flex justify-content-between">
                        <NavLink to={patientdata.link}>
                            <div>
                                <img className="img-fluid" src={patientdata.patientImg} />
                                <span className="patient-title">{patientdata.patientName}</span>
                            </div>
                        </NavLink>
                        <div className="three-dots-bg text-center mt-2">
                            <div className="user-button">
                                <Dropdown
                                    menu={{
                                        items,
                                    }}
                                    trigger={['click']}
                                    placement="bottomRight"
                                    overlayClassName="financeDropDown"
                                >
                                    <div className='dotted-btn'><UserIcon /></div>
                                </Dropdown>
                            </div>
                        </div>
                    </div>
                    <NavLink to={patientdata.link}>
                        <div className="d-flex justify-content-between mt_15">
                            <div className="patient-data">Amount Recieved: </div>
                            <div className="patient-data-info">{patientdata.amountRecieved}</div>
                        </div>
                        <div className="d-flex justify-content-between mt_10">
                            <div className="patient-data">Payment Remaining: </div>
                            <div className="patient-data-info">{patientdata.paymentRemaining}</div>
                        </div>
                        <div className="d-flex justify-content-between mt_10">
                            <div className="patient-data">Treatments: </div>
                            <div className="patient-data-info">{patientdata.treatments}</div>
                        </div>
                        <div className="d-flex justify-content-between mt_10">
                            <div className="patient-data">Appointments: </div>
                            <div className="patient-data-info">{patientdata.appointments}</div>
                        </div>
                        <hr></hr>
                        <Row>
                            <Col md={12} className="mb-2">
                                <div className="d-flex align-items-center">
                                    <span>
                                        <Telephone />
                                    </span>
                                    <span className="icon-text"> {patientdata.contactNo}</span>
                                </div>
                            </Col>
                            <Col md={12} className="mb-2">
                                <div className="d-flex align-items-center">
                                    <span>
                                        <Envelope />
                                    </span>
                                    <span className="icon-text">{patientdata.email}</span>
                                </div>
                            </Col>
                            <Col md={12} className="mb-0">
                                <div className="d-flex align-items-center">
                                    <span>
                                        <Loc />
                                    </span>
                                    <span className="icon-text">{patientdata.location}</span>
                                </div>
                            </Col>
                        </Row>
                    </NavLink>
                </div>
            </div>

            {/* Edit Patient Drawer */}
            <Drawer
                title={<h1 className='fw-bold'>Edit Patients</h1>}
                placement={placement}
                size={"large"}
                onClose={onClose}
                closeIcon={<CrossSquared />}
                open={open}
            >
                <EditPatientForm />
            </Drawer>
            {/* Delete Role MODAL */}
            <ThemeModal
                className="alertModal"
                title={"Alert"}
                content={"Are you sure you want to deactivate this patient?"}
                blueText={"Deactivate"}
                cancelText={"Cancel"}
                setShowDelete={setShowDelete}
                show={showdelete}
                onHide={() => setShowDelete(false)}
            />
        </>
    );
};

export default PatientInfoCard;
