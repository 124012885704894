
import { React } from "react";
import {  Row, Col } from "react-bootstrap";
import {
  EditIcon
} from "../../constants/svg";
import { Assets } from "../../constants/images";
const MyProfile = () => {
  return (
    <>
    <div className='profile-component'>
                    <Row>
                      
                      <Col md={8}>
                        <div className='profile-title'>
                          <span><img src={Assets.AvatarSetting}/></span><span className="profile-name">Alex Joe</span>
                        </div>
                        <div className='profile-details'>
                          <div className='d-flex'>
                          <div className="role">Role:</div><div className="admin">Admin</div>
                          </div>
                          <div className='d-flex mt_17'>
                          <div className="role">Email:</div><div className="admin">alex.doe@gmail.com</div>
                          </div>
                          <div className='d-flex mt_17'>
                          <div className="role">Phone No:</div><div className="admin">+1 675 346 23-10</div>
                          </div>
                          <div className='d-flex mt_17'>
                          <div className="role">Address:</div><div className="admin">2681 Jail Drive</div>
                          </div>
                          <div className='d-flex mt_17'>
                          <div className="role">ZIP Code:</div><div className="admin">90272</div>
                          </div>
                          <div className='d-flex mt_17'>
                          <div className="role">State</div><div className="admin">California</div>
                          </div>
                          <div className='d-flex mt_17'>
                          <div className="role">Country</div><div className="admin">United States of America</div>
                          </div>
                        </div>
                      </Col>
                      <Col md={4}>
                        <div className="text-end edit-icon">
                        <EditIcon className='edit-icon'/>
                        </div>
                      </Col>
                    </Row>
    </div>
    </>
  )
}

export default MyProfile