import React, {useState} from "react";
import { Button } from "antd";
import { PlusIcon } from "../../constants/svg";
import ContentModal from "../ContentModal";
import RejectRequestModal from "../Leads/RejectRequestModal";
import AddNewLocModal from "./AddNewLocModal";

const LocationFilterBar = () => {
  const [contentModal, setContentModal] = useState();
  return (
    <>
      <div className="filter-bar userLocationFilterBar">
        <div className="d-flex justify-content-between align-items-center">
          <h1 className="fw-bold m-0">Locations</h1>

          <Button
            type="primary"
            icon={<PlusIcon />}
            shape="round"
            size="large"
            onClick={() => {
              setContentModal(true);
            }}
          >
            <span className="ms-2">Add New</span>
          </Button>
        </div>

        <ContentModal
          title={"Add New Location"}
          content={<AddNewLocModal setContentModal={setContentModal}  toastMsg = "Location has been added!"/>}
          blueText={"Send"}
          show={contentModal}
          onHide={() => setContentModal(false)}
         
        />
      </div>
    </>
  );
};

export default LocationFilterBar;
