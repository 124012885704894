import React from 'react'
import LeadsFilterBar from '../../components/Leads/LeadsFilterBar'
import LeadsContent from './LeadsContent'

const LeadsPage = () => {
  return (
    <>
        <LeadsFilterBar/>
        <LeadsContent/>
    </>
  )
}

export default LeadsPage