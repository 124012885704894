import { useState } from "react";
import { Button, FormControl, InputGroup } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router";
import { toast, ToastContainer } from "react-toastify";
import { Eye, EyeClose } from "../../../constants/svg";

const Passwordconfirm = () => {
  const [showPassword, setShowPassword] = useState(false);
  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm({
    mode: "onChange",
  });
  const password = watch("formBasicPassword");

  const onSubmit = async (data) => {
    if (data.formBasicPassword === data.formBasicPassword2) {
      toast.success("Password sucessfully updated!", {
        position: toast.POSITION.TOP_RIGHT,
      });
      setTimeout(() => {
        navigate("/");
      }, 2000);
    } else {
      toast.error("Passwords do not match!", {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  const [pass, TogglePass] = useState(true);
  const [passw, TogglePassW] = useState(true);

  return (
    <>
      <ToastContainer />
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Form.Group className="input_main" controlId="formBasicPassword">
          <Form.Label>
            New Password <span className="form_star">*</span>
          </Form.Label>
          <InputGroup>
            <FormControl
              placeholder="Enter Password"
              autoComplete={"off"}
              {...register("formBasicPassword", {
                required: {
                  value: true,
                  message: "Password is required",
                },
                minLength: {
                  value: 8,
                  message: "Min length is 8",
                },
                maxLength: {
                  value: 255,
                  message: "Max length is 255",
                },
              })}
              type={pass ? "password" : "text"}
            />
            <InputGroup.Text id="basic-addon2" onClick={() => TogglePass(!pass)}>
              {pass ? <Eye /> : <EyeClose />}
            </InputGroup.Text>
          </InputGroup>

          {errors.formBasicPassword && (
            <span className={"validation-error"} role="alert">
              {errors.formBasicPassword.message}
            </span>
          )}
        </Form.Group>
        <Form.Group className="input_main mb_20" controlId="formBasicPassword2">
          <Form.Label>
            Confirm Password <span className="form_star">*</span>
          </Form.Label>
          <InputGroup>
            <FormControl
              placeholder="Enter Password"
              autoComplete={"off"}
              {...register("formBasicPassword2", {
                required: {
                  value: true,
                  message: "Password is required",
                },
                minLength: {
                  value: 8,
                  message: "Min length is 8",
                },
                maxLength: {
                  value: 255,
                  message: "Max length is 255",
                },
              })}
              type={passw ? "password" : "text"}
            />
            <InputGroup.Text id="basic-addon2" onClick={() => TogglePassW(!passw)}>
              {passw ? <Eye /> : <EyeClose />}
            </InputGroup.Text>
          </InputGroup>

          {errors.formBasicPassword2 && (
            <span className={"validation-error pr-5"} role="alert">
              {errors.formBasicPassword2.message}
            </span>
          )}
        </Form.Group>
        <div className="main_btn">
          <Button className="main_form_btn" type="submit">
            Update Password
          </Button>
        </div>
      </Form>
    </>

  );
};

export default Passwordconfirm;
