import { React, useState } from "react";
import { Modal, Container } from "react-bootstrap";
import { Button } from "antd";
function ContentModal(props) {
  return (
    <Modal
      show={props.show}
      onHide={props.onHide}
      size={props.size}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className={`customModal contentModal ${props?.className}`}
    >
      <div className="modal_main_content">
        <Modal.Header closeButton className="border-0">
          <Modal.Title id="contained-modal-title-vcenter">
            {props.title}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="user-modal">
            {props.content}
          </div>
        </Modal.Body>
      </div>
    </Modal>
  );
}
export default ContentModal;
