import React from 'react';
import { Progress } from 'antd';

const BillingProgressSection = () => {
    return (
        <div className='stats-box'>
            <div className="billingProgressWrapper">
                <div className="billingHeading">
                    Total Collection
                    <span>$245.50</span>
                </div>
                <div className="billingHeading">
                    To Pay
                    <span>$390.00</span>
                </div>
                <div className="billingHeading">
                    Paid
                    <span>$145.50</span>
                </div>
                <div className="billingHeading">
                    Payment Due
                    <span>$360.00</span>
                </div>
            </div>
            <div className="billing-progressBar-wrapper">
                <div className="billingProgressText d-flex justify-content-between">
                    <p>Total Collection: <span className='d-inline fw-bold'>$245.50</span></p>
                    <p>Total Remaining: <span className='d-inline fw-bold'>$360.00</span></p>
                </div>
                <div className="billingProgressBox">
                    <Progress percent={50} strokeColor={"#5FBF24"} />
                </div>
            </div>
        </div>
    )
}

export default BillingProgressSection