import React, { useState } from 'react';
import { Form, Input, Radio, DatePicker, Select, Button } from "antd";
import { Row, Col } from 'react-bootstrap';
import { ArrowDown, Close } from '../../../constants/svg';
import { toast, ToastContainer } from "react-toastify";

const { Option } = Select;

const EditMembershipPlan = (props) => {
    const [selectedState, setselectedState] = useState('P1');
    const [financingValue, setFinancingValue] = useState({})
    const [selectedTags, setSelectedTags] = useState([]);


    const handleStateChange = (value) => {
        setselectedState(value);
    };
    const onFinish = (values) => {
        console.log("Create Membershipplan Data: ", values);
        toast.success("Membership Plan created");
        setTimeout(() => {
            props?.setOpen(false)
        }, 1000)

    };
    //Payment plan options array
    const stateOptions = [
        { name: "P1", value: 'p1' },
        { name: "P2", value: 'p2' },
        { name: "P3", value: 'p3' },
    ];

    const financingPlan = [
        { name: "Lorem Ipsum", value: 100 },
        { name: "Lorem Ipsum", value: 200 },
        { name: "Lorem Ipsum", value: 300 },
    ];

    const options = [
        { value: "Dental Cleaning", label: "Dental Cleaning" },
        { value: "Teeth Whitening", label: "Teeth Whitening" },
        { value: "Dental Implants", label: "Dental Implants" },
        { value: "Root Canal Therapy", label: "Root Canal Therapy" },
        { value: "Dental Crowns", label: "Dental Crowns" },
        { value: "Dental Bridges", label: "Dental Bridges" },
        { value: "Dentures", label: "Dentures" },
        { value: "Invisalign", label: "Invisalign" },
        { value: "Braces", label: "Braces" },
        { value: "Gum Disease Treatment", label: "Gum Disease Treatment" },
        { value: "Oral Surgery", label: "Oral Surgery" },
        { value: "Pediatric Dentistry", label: "Pediatric Dentistry" },
        { value: "Emergency Dentistry", label: "Emergency Dentistry" },
    ];


    const handleChange = (value) => {
        console.log(`selected ${value}`);
        setSelectedTags(value);
    };


    return (
        <>
            <Form onFinish={onFinish}
                initialValues={{
                    planName: "Dental Plan",
                    paymentPlan: "P1",
                    financingPlan: "Lorem Ipsum",
                    planDescription: "Lorem, ipsum dolor sit amet consectetur adipisicing elit. Laborum dolorum velit distinctio aliquam voluptates repudiandae, nesciunt doloribus voluptatem perferendis modi.",
                    duration: "monthly",
                    services: ["Dentures", "Emergency Dentistry", "Oral Surgery"]
                }}>
                <Row>
                    <Col lg={6} sm={12}>
                        <Form.Item
                            label="Plan Name"
                            name="planName"
                            rules={[
                                {
                                    required: false,
                                    message: "Please input plan name",
                                },
                            ]}
                            labelCol={{ span: 24 }}
                            wrapperCol={{ span: 24 }}
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col lg={12} >
                        <Form.Item
                            label="Services"
                            name="services"
                            rules={[
                                {
                                    required: false,
                                    message: "Please input services",
                                },
                            ]}
                            labelCol={{ span: 24 }}
                            wrapperCol={{ span: 24 }}
                        >
                            <Select
                                mode="tags"
                                style={{
                                    width: '100%',
                                }}
                                placeholder="Select Services"
                                onChange={handleChange}
                                options={options}
                                suffixIcon={<ArrowDown />}
                            />
                        </Form.Item>
                    </Col>
                    <Col lg={6} sm={12}></Col>
                    <Col lg={12} sm={12}>
                        <Form.Item
                            label="Description"
                            name="planDescription"
                            labelCol={{ span: 24 }}
                            wrapperCol={{ span: 24 }}
                            rules={[
                                {
                                    required: true,
                                    message: "Please input your Description!",
                                },
                            ]}
                        >
                            <Input.TextArea rows={4} cols={4} />
                        </Form.Item>
                    </Col>
                    <Col lg={6} sm={12}>
                        <Form.Item
                            label="Payment Plan"
                            name="paymentPlan"
                            rules={[
                                {
                                    required: false,
                                    message: "Please select payment plan!",
                                },
                            ]}
                            labelCol={{ span: 24 }}
                            wrapperCol={{ span: 24 }}
                        >
                            <Select
                                style={{ width: "100%" }}
                                placeholder="Please select state"
                                onChange={handleStateChange}
                                suffixIcon={<ArrowDown />}
                                removeIcon={<Close />}
                                className={"form-dropdown"}
                            >
                                {stateOptions?.map((option, index) => (
                                    <Option key={index} value={option.value}>
                                        {option.name}
                                    </Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col lg={6} sm={12}>
                        <Form.Item
                            label="Financing Plan"
                            name="financingPlan"
                            rules={[
                                {
                                    required: false,
                                    message: "Please Select Financing Plan!",
                                },
                            ]}
                            labelCol={{ span: 24 }}
                            wrapperCol={{ span: 24 }}
                        >
                            <Select
                                style={{ width: "100%" }}
                                placeholder="Please Select Financing Plan"
                                onChange={handleStateChange}
                                suffixIcon={<ArrowDown />}
                                removeIcon={<Close />}
                                className={"form-dropdown"}
                            >
                                {financingPlan?.map((option, index) => (
                                    <Option key={index} value={option.value}>
                                        {option.name}
                                    </Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col lg={6} sm={12}>
                        <Form.Item
                            label="Duration"
                            name="duration"
                            rules={[
                                {
                                    required: false,
                                    message: "Please select duratiion",
                                },
                            ]}
                            labelCol={{ span: 24 }}
                            wrapperCol={{ span: 24 }}
                        >
                            <Radio.Group>
                                <Radio value="monthly">Monthly</Radio>
                                <Radio value="yearly">Yearly</Radio>
                            </Radio.Group>
                        </Form.Item>
                    </Col>

                    <Form.Item className='d-flex justify-content-end'>
                        <Button type="primary" htmlType="submit" shape="round" size='large'>
                            Create
                        </Button>
                    </Form.Item>
                </Row>
            </Form>
        </>
    )
}

export default EditMembershipPlan