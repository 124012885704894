import React from 'react'
import PayersContent from '../../components/EzPay/Payers/PayersContent'
import PayersFilterBar from '../../components/EzPay/Payers/PayersFilterBar'

const PayersPage = () => {
    return (
        <>
            <PayersFilterBar />
            <PayersContent />
        </>
    )
}

export default PayersPage