import { React } from "react";
import { Modal } from "react-bootstrap";
import { Button } from "antd";
import { NavLink } from "react-router-dom";

// Modal Component
function ThemeModal(props) {
  return (
    <Modal
      show={props.show}
      onHide={props.onHide}
      size={props.size}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className={`customModal ${props?.className}`}
    >
      <div className="modal_main_content">
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            {props.title}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="user-modal">
            <p>{props.content}</p>
            <div className="text-end">
              {props.btnLink && <NavLink to={props.btnLink}>
                <Button type="primary" shape="round" size='large'>
                  {props.btnLinkText}
                </Button>
              </NavLink>}
              {props.blueText && <Button type="primary" shape="round" size='large' onClick={() => { props.setShowDelete(false) }}>
                {props.blueText}
              </Button>}
              {props.cancelText && <Button size={"large"} className="border-0 shadow-none greyColor cancelBtn" onClick={() => { props.setShowDelete(false) }}>{props.cancelText}</Button>}
            </div>
          </div>
        </Modal.Body>
      </div>
    </Modal>
  );
}
export default ThemeModal;
