import React, { useState } from 'react';
import { Form, Input, Slider, Button, Space, Switch, Radio } from 'antd';
import { toast } from "react-toastify";

const EditFinancingForm = (props) => {
    const [value, setValue] = useState(1);
    const onChange = (e) => {
        console.log('radio checked', e.target.value);
        setValue(e.target.value);
    };

    const onFinish = (values) => {
        console.log('Slider value:', values);
        toast.success("Your plan has been created");

    }
    return (
        <>
            <Form
                onFinish={onFinish}
                initialValues={{
                    planName: "Finance Plan 1",
                    planDescription: "lorem ipsum dolor sit amet, lorem ipsum dolor sit amet, consectetur",
                    miniDownPayment: "50",
                    interestRate: "28",
                    discountEligibility: "28",
                    calcPaymentSchedule: 1,
                    insuranceClaim: "28",
                    extendedFinancing: "28",
                    latepayment: "$10",
                }}
            >
                <Form.Item
                    label="Plan Name"
                    name="planName"
                    labelCol={{ span: 24 }}
                    wrapperCol={{ span: 24 }}
                    rules={[
                        {
                            required: true,
                            message: 'Please input your Plan Name!',
                        },
                    ]}
                >
                    <Input />
                </Form.Item>

                <Form.Item
                    label="Description"
                    name="planDescription"
                    labelCol={{ span: 24 }}
                    wrapperCol={{ span: 24 }}
                    rules={[
                        {
                            required: true,
                            message: 'Please input your Description!',
                        },
                    ]}
                >
                    <Input.TextArea rows={4} cols={4} />
                </Form.Item>
                <Form.Item
                    label="Minimum Down Payment"
                    labelCol={{ span: 24 }}
                    wrapperCol={{ span: 24 }}
                >
                    <Space.Compact compact="true" className='border-0'>
                        <div className="sliderValueBar">
                            <p className='minValue'>0%</p>
                            <p className='maxValue'>100%</p>
                        </div>
                        <Form.Item
                            name="miniDownPayment"
                            rules={[{ required: true, message: 'Please input your minimum down payment', }]}
                        >
                            <Slider
                                min={0}
                                max={100}
                                tooltip={{
                                    open: true,
                                    formatter: (value) => `${value}%`,
                                    placement: 'bottom',
                                    getPopupContainer: () => document.querySelector('.modal-body'),
                                }}
                            />
                        </Form.Item>
                    </Space.Compact>

                </Form.Item>

                <Form.Item
                    label="Interest Rate"
                    labelCol={{ span: 24 }}
                    wrapperCol={{ span: 24 }}
                >
                    <Space.Compact compact="true" className='border-0'>
                        <div className="sliderValueBar">
                            <p className='minValue'>0%</p>
                            <p className='maxValue'>100%</p>
                        </div>
                        <Form.Item
                            name="interestRate"
                            rules={[{ required: true, message: 'Please input your interest rate', }]}
                        >
                            <Slider
                                min={0}
                                max={100}
                                tooltip={{
                                    open: true,
                                    formatter: (value) => `${value}%`,
                                    placement: 'bottom',
                                    getPopupContainer: () => document.querySelector('.modal-body'),
                                }}
                            />
                        </Form.Item>
                    </Space.Compact>
                </Form.Item>
                <Form.Item
                    label="Interest Rate Adjustment"
                    name="interestRateAdjustment"
                    labelCol={{ span: 24 }}
                    wrapperCol={{ span: 24 }}
                >
                    Off &nbsp; <Switch defaultChecked={true} size={true} /> &nbsp; On
                </Form.Item>
                <Form.Item
                    label="Discount Eligibility"
                    labelCol={{ span: 24 }}
                    wrapperCol={{ span: 24 }}
                >
                    <Space.Compact compact="true" className='border-0'>
                        <div className="sliderValueBar">
                            <p className='minValue'>0%</p>
                            <p className='maxValue'>100%</p>
                        </div>
                        <Form.Item
                            name="discountEligibility"
                            rules={[{ required: true, message: 'Please input your discount eligibility', }]}
                        >
                            <Slider
                                min={0}
                                max={100}
                                tooltip={{
                                    open: true,
                                    formatter: (value) => `${value}%`,
                                    placement: 'bottom',
                                    getPopupContainer: () => document.querySelector('.modal-body'),
                                }}
                            />
                        </Form.Item>
                    </Space.Compact>
                </Form.Item>


                <Form.Item
                    label="Calculation of payment schedule"
                    name="calcPaymentSchedule"
                    labelCol={{ span: 24 }}
                    wrapperCol={{ span: 24 }}
                    rules={[
                        {
                            required: true,
                            message: 'Please select this field'
                        },
                    ]}
                >
                    <Radio.Group onChange={onChange} value={value}>
                        <Radio value={1}>Monthly</Radio>
                        <Radio value={2}>Adhoc</Radio>
                    </Radio.Group>
                </Form.Item>



                <Form.Item
                    label="Late Payment Fees"
                    name="latepayment"
                    labelCol={{ span: 24 }}
                    wrapperCol={{ span: 24 }}
                    rules={[{ required: true, message: 'Please input your late payment fees!' }]}
                    className="addOnLabel"
                >
                    <Input
                        addonAfter={<span style={{ fontSize: 16, fontWeight: 'bold' }}>$</span>}
                    />
                </Form.Item>

                <Form.Item
                    label="Insurance claim processing fees"
                    labelCol={{ span: 24 }}
                    wrapperCol={{ span: 24 }}
                >
                    <Space.Compact compact="true" className='border-0'>
                        <div className="sliderValueBar">
                            <p className='minValue'>0%</p>
                            <p className='maxValue'>100%</p>
                        </div>
                        <Form.Item
                            name="insuranceClaim"
                            rules={[{ required: true, message: 'Please input your insurance claim processing fees', }]}
                        >
                            <Slider
                                min={0}
                                max={100}
                                tooltip={{
                                    open: true,
                                    formatter: (value) => `${value}%`,
                                    placement: 'bottom',
                                    getPopupContainer: () => document.querySelector('.modal-body'),
                                }}
                            />
                        </Form.Item>
                    </Space.Compact>
                </Form.Item>

                <Form.Item
                    label="Extended Financing"
                    labelCol={{ span: 24 }}
                    wrapperCol={{ span: 24 }}
                >
                    <Space.Compact compact="true" className='border-0'>
                        <div className="sliderValueBar">
                            <p className='minValue'>0%</p>
                            <p className='maxValue'>100%</p>
                        </div>
                        <Form.Item
                            name="extendedFinancing"
                            rules={[{ required: true, message: 'Please input your extended financing', }]}
                        >
                            <Slider
                                min={0}
                                max={100}
                                tooltip={{
                                    open: true,
                                    formatter: (value) => `${value}%`,
                                    placement: 'bottom',
                                    getPopupContainer: () => document.querySelector('.modal-body'),

                                }}
                            />
                        </Form.Item>
                    </Space.Compact>
                </Form.Item>


                <Form.Item className='d-flex justify-content-end'>
                    <Button type="primary" htmlType="submit" shape="round" size='large'>
                        Create
                    </Button>
                </Form.Item>
            </Form>
        </>
    )
}

export default EditFinancingForm