import { React, useState } from "react";
import { useNavigate } from "react-router";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Eye, EyeClose } from "../../../constants/svg";
import { NavLink } from "react-router-dom";
import { VALIDATIONS_TEXT, Patterns, maxBounds, minBounds, maxLength } from "../../../constants/constants";
import { useForm } from "react-hook-form";
import { Form, Row, Col, Button, InputGroup } from "react-bootstrap";

const Signin = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false); //To show and hide password
  const [passwordShow, setPasswordShow] = useState(false);
  const [disable, setDisable] = useState(false);

  function handleToggleShowPassword() { //To show and hide password
    setShowPassword(!showPassword);
  }

  const { register, handleSubmit, watch, formState: { errors } } = useForm({
    mode: "onChange",
    criteriaMode: "firstError",
    shouldFocusError: true,
  });

  const onSubmit = (data) => {
    console.log(data);
    toast.success("Login Successfully");
    setTimeout(() => {
      navigate("/dashboard");
    }, 3000);
  };

  return (
    <>
      <Form onSubmit={handleSubmit(onSubmit)} autoComplete={"off"}>
        <Row>
          <Col xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
            <Form.Group className="themeInputGroup mb-3" controlId="signInEmail">
              <Form.Label>Email Address<span>*</span></Form.Label>
              <Form.Control
                type="email"
                placeholder="Email address"
                autoComplete="off"
                role={"presentation"}
                className={errors.signInEmail ? "hasError" : ""}
                maxLength={maxBounds.EMAIL}
                {...register("signInEmail", {
                  required: {
                    value: true,
                    message: VALIDATIONS_TEXT.EMAIL_REQUIRED
                  },
                  pattern: {
                    value: Patterns.Email,
                    message: VALIDATIONS_TEXT.EMAIL_PATTERN
                  },
                  maxLength: {
                    value: maxBounds.EMAIL,
                    message: VALIDATIONS_TEXT.EMAIL_MAX
                  }
                })}
              />
              {errors.signInEmail && <Form.Text>{errors.signInEmail.message}</Form.Text>}
            </Form.Group>
          </Col>
          <Col xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
            <Form.Group className="themeInputGroup passwordInput mb-3" controlId="signInPassword">
              <Form.Label>Password<span>*</span></Form.Label>
              <InputGroup className={errors.signInPassword ? "hasError" : ""}>
                <Form.Control
                  type={passwordShow ? "text" : "password"}
                  placeholder="••••••••"
                  aria-describedby="passToggle"
                  className={"passwordControl"}
                  minLength={minBounds.PASSWORD}
                  maxLength={maxBounds.PASSWORD}
                  autoComplete="off"
                  role={"presentation"}
                  {...register("signInPassword", {
                    required: {
                      value: true,
                      message: VALIDATIONS_TEXT.PASSWORD_REQUIRED
                    },
                    pattern: {
                      value: Patterns.Password,
                      message: VALIDATIONS_TEXT.PASSWORD_PATTERN,
                    },
                    minLength: {
                      value: minBounds.PASSWORD,
                      message: VALIDATIONS_TEXT.PASSWORD_MIN
                    },
                    maxLength: {
                      value: maxBounds.PASSWORD,
                      message: VALIDATIONS_TEXT.PASSWORD_MAX
                    }
                  })}
                />
                <Button type={"button"} className={"btn-togglePassword"} variant="outline-secondary" id="passToggle" onClick={() => setPasswordShow(!passwordShow)}>
                  {
                    passwordShow ? <EyeClose /> : <Eye />
                  }
                </Button>
              </InputGroup>

              {errors.signInPassword && <Form.Text>{errors.signInPassword.message}</Form.Text>}
            </Form.Group>
          </Col>
          <Col xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
            <NavLink to="/forgotpassword" className="forgot_pass ">
              Forgot Password?
            </NavLink>
          </Col>
          <Col xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
            <Button type={"submit"} className={"w-100 btn-submit m-t-50 w-100"}>
              Sign In
            </Button>
          </Col>
        </Row>
      </Form>

      <div className="signupHere ">
        <p>Don't have an account?</p>
        <NavLink to="/signup">
          <h4>Sign up Here</h4>
        </NavLink>
      </div>

      <ToastContainer />
    </>
  );
};

export default Signin;
