import React from 'react'
import LocationContent from '../../components/Locations/LocationContent'
import LocationFilterBar from '../../components/Locations/LocationFilterBar'

const LocationPage = () => {
  return (
    <>
        <LocationFilterBar/>
        <LocationContent/>
    </>
  )
}

export default LocationPage