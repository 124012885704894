import React, { useState } from "react";
import { Button } from "antd";
import { PlusIcon } from "../../constants/svg";
import ContentModal from "../ContentModal";
import AddNewLocModal from "../Locations/AddNewLocModal";
import EditFinancingForm from "../FinancingPlan/EditFinancingForm";
const FinancingPlanFilterBar = () => {
  const [contentModal, setContentModal] = useState();
  return (
    <>
      <div className="filter-bar userLocationFilterBar">
        <div className="d-flex justify-content-between align-items-center">
          <h1 className="fw-bold m-0">Financing Plan</h1>

          <Button
            type="primary"
            icon={<PlusIcon />}
            shape="round"
            size="large"
            onClick={() => {
              setContentModal(true);
            }}
          >
            <span className="ms-2">Create Financing Plan</span>
          </Button>
        </div>

        <ContentModal
        title={"Create Financing Plan"}
        content={<EditFinancingForm setContentModal={setContentModal} />}
        blueText={"Send"}
        show={contentModal}
        onHide={() => setContentModal(false)}
      />
      </div>
    </>
  );
};

export default FinancingPlanFilterBar;
