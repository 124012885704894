import React from "react";
import { Table } from "antd";
import { Col, Row } from "react-bootstrap";
import { Assets } from "../../constants/images";
const LocationDetailContent = () => {
  const columns = [
    {
      title: "User ID",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "Full Name",
      dataIndex: "fullName",
      key: "fullName",
      sorter: (a, b) => a.fullName.localeCompare(b.fullName),
      sortDirections: ["ascend", "descend"],
    },
    {
      title: "Email Address",
      dataIndex: "email",
      key: "email",
      sorter: (a, b) => a.email.localeCompare(b.email),
      sortDirections: ["ascend", "descend"],
    },
    {
      title: "Role",
      dataIndex: "role",
      key: "role",
      sorter: (a, b) => a.role.localeCompare(b.role),
      sortDirections: ["ascend", "descend"],
    },
    {
      title: "Password",
      dataIndex: "password",
      key: "password",
      sorter: (a, b) => a.password.localeCompare(b.password),
      sortDirections: ["ascend", "descend"],
    },
  ];

  const data = [
    {
      id: "1",
      fullName: "John Doe",
      email: "johndoe@example.com",
      role: "Admin",
      password: "asdssd",
    },
    {
      id: "2",
      fullName: "Jane Smith",
      email: "janesmith@example.com",
      role: "User",
      password: "qwertryty",
    },
    {
      id: "3",
      fullName: "Bob Johnson",
      email: "bobjohnson@example.com",
      role: "User",
      password: "poiujhgfdswe",
    },
  ];

  return (
    <>
      <div className="locationDetailContent mb-3">
        <Row>
          <Col lg={5}>
            <Row className="mb-2">
              <Col lg={4}>
                <p className="m-0 boldGrey">Location:</p>
              </Col>
              <Col lg={8}>
                <p className="m-0">4702 Froe Street West Virginia</p>
              </Col>
            </Row>
            <Row className="mb-2">
              <Col lg={4}>
                <p className="m-0 boldGrey">Map View:</p>
              </Col>
              <Col lg={8}>
                <img src={Assets.mapMediumImg} alt="" />
              </Col>
            </Row>
            <Row className="mb-2">
              <Col lg={4}>
                <p className="m-0 boldGrey">No. of Employees:</p>
              </Col>
              <Col lg={8}>
                <p className="m-0">04</p>
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
      <div className="stats-box">
        <Table
          columns={columns}
          dataSource={data}
          bordered={false}
          scroll={{ x: "max-content" }}
          responsive={true}
          size={"large"}
          className={"p-0"}
        />
      </div>
    </>
  );
};

export default LocationDetailContent;
