import React from 'react'
import SubscriptionPlansFilterbar from '../../components/SubscriptionPlans/SubscriptionPlansFilterbar'
import SubscriptionPlanContent from './SubscriptionPlanContent'

const SubscriptionPlansPage = () => {
    return (
        <>
            <SubscriptionPlansFilterbar />
            <SubscriptionPlanContent />
        </>
    )
}

export default SubscriptionPlansPage