import { React } from "react";
import {  Row, Col } from "react-bootstrap";
import {
  MenuDots
} from "../../constants/svg";
import { NavLink } from "react-router-dom";
import DropdownButton from "react-bootstrap/DropdownButton";
import Dropdown from "react-bootstrap/Dropdown";
import { Button } from "react-bootstrap";
import Breadcrumb from "react-bootstrap/Breadcrumb";


const AppointmentPage = () => {

  return (
    <div className="user-management">
      <Row>
        <Col md={12}>
          <div className='breadcrumbs'>
          <Breadcrumb>
            <Breadcrumb.Item>
              <NavLink to="/dashboard">Dashboard</NavLink>
            </Breadcrumb.Item>
            <Breadcrumb.Item active>
              <NavLink to="/appointment">Appointment</NavLink>
            </Breadcrumb.Item>
          </Breadcrumb>
          </div>
        </Col>
      </Row>
      <Row>
        <Col md={6}>
          <h2>Appointment</h2>
        </Col>
        <Col md={6}>
          <div className="d-flex justify-content-end d-none">
            <div>
              <Button className="filter-btn">Filter</Button>
            </div>
            <div>
              <Button className="add-new-btn ms-3">+ Add New</Button>
            </div>
          </div>
        </Col>
      </Row>
      <Row>
        <Col md={12}>
          <div className="table-container">
            <div style={{ height: 650, width: "100%" }}>
              {/* Table */}
            </div>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default AppointmentPage;
