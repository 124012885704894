import React, { useState } from 'react';
import { Row, Col } from 'react-bootstrap';
import { Button, Drawer } from 'antd'
import { CrossSquared, EditSquare } from '../../constants/svg';
import ContentModal from '../ContentModal';
import DetailSelectEmailToSend from './DetailSelectEmailToSend/DetailSelectEmailToSend';
import DetailEditMembershipPlan from './DetailEditMembershipPlan/DetailEditMembershipPlan';

const MembershipPlanDetailContent = () => {
  const [open, setOpen] = useState(false);
  const [placement, setPlacement] = useState('right');
  const [contentModal, setContentModal] = useState(false);
  const showDrawer = () => {
    setOpen(true);
  };
  const onClose = () => {
    setOpen(false);
  };
  return (
    <>
      <div className="membershipPlanDetail stats-box">
        <Row>
          <Col lg={6}>
            <ul className="greyBlackList m-0">
              <li>
                <p>
                  <span className="title">Plan Name:</span>
                  <span className="text">Dental Plan</span>
                </p>
              </li>
              <li>
                <p>
                  <span className="title">Services:</span>
                  <span className="text">Root Canaling <br />Teeth Whitening</span>
                </p>
              </li>
              <li>
                <p>
                  <span className="title">Description:</span>
                  <span className="text">Lorem ipsum dolor sit amet, consectetur adipiscing<br />
                    elit. Sed felis dolor, elementum ut lectus eu, euismod posuere dolor.</span>
                </p>
              </li>
              <li>
                <p>
                  <span className="title">Duration:</span>
                  <span className="text">Monthly</span>
                </p>
              </li>
            </ul>
          </Col>
          <Col lg={6}>
            <div className="membershipDetailContentBox">
              <div className="btn-wrapper">
                <Button shape="round" size="large" className='border-btn' onClick={() => setContentModal(true)}>
                  Send
                </Button>
                <div className="editBtn" onClick={showDrawer}>
                  <EditSquare />
                </div>
              </div>
            </div>
          </Col>
        </Row>
        {/* Edit Membership Plan */}
        <Drawer
          title={<h1 className='fw-bold'>Edit Plan</h1>}
          placement={placement}
          size={"large"}
          onClose={onClose}
          closeIcon={<CrossSquared />}
          open={open}
        >
          <DetailEditMembershipPlan />
        </Drawer>
        <ContentModal
          title={"Send Plan"}
          content={<DetailSelectEmailToSend setOpen={setOpen} />}
          blueText={"Send"}
          show={contentModal}
          onHide={() => setContentModal(false)}
        />
      </div>
    </>
  )
}

export default MembershipPlanDetailContent