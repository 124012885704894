import React from 'react';
import { Table, Button } from 'antd';
import { Assets } from '../../constants/images';
import { NavLink } from 'react-router-dom';



const AgreementTable = (props) => {

    const downloadPdf = () => {
        fetch(Assets.filepdf) // Modify the path to your PDF file
            .then(response => response.blob())
            .then(blob => {
                const url = window.URL.createObjectURL(blob);
                const a = document.createElement('a');
                a.href = url;
                a.download = 'Agreement.pdf';
                document.body.appendChild(a);
                a.click();
                a.remove();
                window.URL.revokeObjectURL(url);
            });
    };

    const handleClick = () => {
        props.setContentModal(true)
    }
    console.log(props, "Agreement Table prop")

    const data = [
        {
            key: '1',
            createdDate: '21/02/2022',
            agreementName: 'Signature',
            agreementDocument: 'View Agreement',
        },
        {
            key: '2',
            createdDate: '25/02/2022',
            agreementName: 'Agreement',
            agreementDocument: 'View Agreement',
        },
        {
            key: '3',
            createdDate: '28/02/2022',
            agreementName: 'Signature',
            agreementDocument: 'View Agreement',
        },
        {
            key: '4',
            createdDate: '01/03/2022',
            agreementName: 'Agreement',
            agreementDocument: 'View Agreement',
        },
        {
            key: '5',
            createdDate: '03/03/2022',
            agreementName: 'Signature',
            agreementDocument: 'View Agreement',
        },
        {
            key: '6',
            createdDate: '01/03/2022',
            agreementName: 'Agreement',
            agreementDocument: 'View Agreement',
        },
        {
            key: '7',
            createdDate: '03/03/2022',
            agreementName: 'Signature',
            agreementDocument: 'View Agreement',
        },
        {
            key: '8',
            createdDate: '01/03/2022',
            agreementName: 'Agreement',
            agreementDocument: 'View Agreement',
        },
        {
            key: '9',
            createdDate: '03/03/2022',
            agreementName: 'Signature',
            agreementDocument: 'View Agreement',
        },
        // add more rows as needed
    ];

    const columns = [
        {
            title: 'Date',
            dataIndex: 'createdDate',
            sorter: (a, b) => a.createdDate.localeCompare(b.createdDate),
        },
        {
            title: 'Agreement Name',
            dataIndex: 'agreementName',
            sorter: (a, b) => a.agreementName.localeCompare(b.agreementName),
        },
        {
            title: 'Agreement Document',
            dataIndex: 'agreementDocument',
            sorter: (a, b) => a.agreementDocument.localeCompare(b.agreementDocument),
            render: (text, record) => {
                return (
                    <NavLink to="javascript:" onClick={downloadPdf} className="decoration-underline">
                        {text}
                    </NavLink>
                );
            },
        },
        {
            title: '',
            dataIndex: 'button',
            render: (text, record, props) => {
                return (
                    <div>
                        <Button shape="round" size="small" className='border-btn' onClick={handleClick}>
                            Send Agreement
                        </Button>
                    </div>
                );
            },
        },
    ];



    return (
        <div className="stats-box">
            <Table
                dataSource={data}
                columns={columns}
                pagination={true}
                rowSelection={{
                    type: 'checkbox',
                }}
                scroll={{ x: 'max-content' }}
                responsive={true}
                size={'large'}
                className={"p-0"}
            />
        </div>
    )
}

export default AgreementTable