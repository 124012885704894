import { React, useState } from "react";
import { Row, Col } from "react-bootstrap";




import { NavLink } from "react-router-dom";



import ThemeModal from "../../components/ThemeModal";
import { Button } from "react-bootstrap";

import Breadcrumb from "react-bootstrap/Breadcrumb";
import CreateNewPlan from "../../components/Modals/Administration/CreateNewPlan";
import EditPlan from "../../components/Modals/Administration/EditPlan";
import DeleteRecord from "../../components/Modals/RoleManagement/DeleteRecord";

const Administration = () => {

  const [showaddnew, setShowAddNew] = useState(false);


  const [showdelete, setShowDelete] = useState(false);

  // edit modal
  const [showeditnew, setShowEditNew] = useState(false);



  return (
    <div className="user-management">
      <Row>
        <Col md={12}>
          <div className="breadcrumbs">
            <Breadcrumb>
              <Breadcrumb.Item>
                <NavLink to="/dashboard">Dashboard</NavLink>
              </Breadcrumb.Item>
              <Breadcrumb.Item>
                <NavLink to="/EzPlan/">EZ Plan Dashboard</NavLink>
              </Breadcrumb.Item>
              <Breadcrumb.Item active>
                <NavLink to="/administration">Administration</NavLink>
              </Breadcrumb.Item>
            </Breadcrumb>
          </div>
        </Col>
      </Row>
      <Row>
        <Col md={6}>
          <h2>Subscribed Users</h2>
        </Col>
        <Col md={6}>
          <div className="d-flex justify-content-start justify-content-md-end mb-3 mb-md-3">
            <div>
              <Button className="filter-btn d-none">Filter</Button>
            </div>
            <div>
              <Button
                onClick={() => setShowAddNew(true)}
                className="add-new-btn "
              >
                + Create New Plan
              </Button>
            </div>
          </div>
        </Col>
      </Row>
      <Row>
        <Col md={12}>
          <div className="table-container">
            <div style={{ height: 650, width: "100%" }}>
              {/* Table */}
              </div>
          </div>
        </Col>
      </Row>
      {/* ADMINISTRATION MODAL STARTS HERE */}

      {/* ADD USER MODAL */}
      <div className="pages-modals">
        <ThemeModal
          className="ezfi-modal"
          title={"Add User"}
          content={<CreateNewPlan />}
          // footer={<LogoutModalButtons />}
          show={showaddnew}
          onHide={() => setShowAddNew(false)}
        />
      </div>
      {/* END OF ADD USER MODAL */}

      {/* Edit USER MODAL */}
      <ThemeModal
        className="ezfi-modal"
        title={"Edit User"}
        content={<EditPlan />}
        // footer={<LogoutModalButtons />}
        show={showeditnew}
        onHide={() => setShowEditNew(false)}
      />
      {/* END OF Edit USER MODAL */}

      {/* EDIT USER MODAL */}
      {/* END OF EDIT USER MODAL */}

      {/* Delete User MODAL */}
      <div className="pages-modals">
        <ThemeModal
          className="ezfi-modal"
          title={"Alert"}
          content={<DeleteRecord />}
          show={showdelete}
          onHide={() => setShowDelete(false)}
        />
      </div>
      {/* END OF Delete User MODAL */}
      {/* ADMINISTRATION MODAL ENDS HERE */}
    </div>
  );
};

export default Administration;
