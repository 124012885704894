import React from 'react';
import { Breadcrumb} from 'antd';
import { NavLink } from 'react-router-dom';
const MarketingCreateFilterbar = () => {


  return (
    <>
      <div className="d-flex ms-3 justify-content-between filter-bar">
        <div className="title-detail">
          <Breadcrumb
            items={[
              {
                title: <NavLink to="/discount-offers">Marketing Test</NavLink>,
              },
              {
                title: "Create Proposal",
              },
            ]}
          />
          <h1 className="fw-bold">Create Proposal</h1>
        </div>
      </div>
    </>
  );
};

export default MarketingCreateFilterbar;
