import React from "react";
import LocationDetailContent from "../../components/Locations/LocationDetailContent";
import LocationDetailFilterBar from "../../components/Locations/LocationDetailFilterBar";

const LocationsDetailPage = () => {
  return (
    <>
      <LocationDetailFilterBar />
      <LocationDetailContent />
    </>
  );
};

export default LocationsDetailPage;
