import React from 'react'

const SubscriptionPlansFilterbar = () => {
  return (
    <div className="filter-bar">
      <div className="filter-bar__title">
        <h1 className='fw-bold'>Subscription Plans</h1>
      </div>
    </div>
  )
}

export default SubscriptionPlansFilterbar