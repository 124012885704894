
export const VALIDATIONS = {
    ROLE_NAME: 50,
    PATIENT_NAME: 50,
    NAME: 50,
    EMAIL: 255,
    PASSWORD_MIN: 8,
    PASSWORD_MAX: 32,
    PHONE_MIN: 10,
    PHONE_MAX: 16,
    ADDRESS_MAX: 200,
    BIO_MAX: 255,

    COUNTRY_MAX: 200,
    CITY_MAX: 200,
    STATE_MAX: 55,
    ZIP_CODE: 18,

    VERIFICATION_CODE: 4,
    CARD_NUMBER: 16,
    CVV: 3,
}


/*
*  Maximum Error Bounds
* */
export const maxBounds = {
    USERNAME: 20,
    PHONE: 16,
    EMAIL: 255,
    PASSWORD: 255,
    BIO: 255,
    MESSAGE: 255,
    NAME: 25,
    CARDNUMBER: 16,
    CVV: 3,
    TEXTAREA: 1000
}

export const VALIDATIONS_TEXT = {
    NAME_REQUIRED: "Name is required",
    ROLE_NAME_REQUIRED: "Role Name is required",
    PATIENT_NAME_REQUIRED: "Patient Name is required",
    FIELD_IS_REQUIRED: "Field is required",

    NAME_MAX: "Max length is " + VALIDATIONS.NAME,
    ROLE_NAME_MAX: "Max length is " + VALIDATIONS.ROLE_NAME,
    PATIENT_NAME_MAX: "Max length is " + VALIDATIONS.PATIENT_NAME,


    EMAIL_REQUIRED: "Email is required",
    EMAIL_MAX: "Max length is " + VALIDATIONS.EMAIL,
    EMAIL_FORMAT: "Invalid email format",

    PASSWORD_REQUIRED: "Password is required",
    CONFIRM_PASSWORD_REQUIRED: "Confirm password is required",
    PASSWORD_MIN: "Min length is " + VALIDATIONS.PASSWORD_MIN,
    PASSWORD_MAX: "Max length is " + VALIDATIONS.PASSWORD_MAX,

    PHONE_REQUIRED: "Contact number is required",
    PHONE_MIN: "Min length is " + VALIDATIONS.PHONE_MIN,
    PHONE_MAX: "Max length is " + VALIDATIONS.PHONE_MAX,

    ADDRESS_REQUIRED: "Address is required",
    ADDRESS_MAX: "Max length is " + VALIDATIONS.ADDRESS_MAX,

    BIO_MAX: "Max length is " + VALIDATIONS.BIO_MAX,

    COUNTRY_MAX: "Max length is " + VALIDATIONS.COUNTRY_MAX,
    CITY_MAX: "Max length is " + VALIDATIONS.CITY_MAX,
    STATE_MAX: "Max length is " + VALIDATIONS.STATE_MAX,
    ZIP_CODE: "Max length is " + VALIDATIONS.ZIP_CODE,

    CARD_NUMBER_REQUIRED: "Card number is required",
    CARD_NUMBER_MIN: "Min length is " + VALIDATIONS.CARD_NUMBER,
    CARD_NUMBER_MAX: "Max length is " + VALIDATIONS.CARD_NUMBER,

    CVV_REQUIRED: "Cvv is requried",
    CVV_MIN: "Min length is " + VALIDATIONS.CVV,
    CVV_MAX: "Max length is " + VALIDATIONS.CVV,

    VERIFICATION_CODE: "Length is " + VALIDATIONS.VERIFICATION_CODE,
    INVALID_FORMAT: "Invalid format",

    NAME_PATTERN: "Name must contain letters only",
    EMAIL_PATTERN: "Invalid Email format",
    PASSWORD_PATTERN: "Minimum eight characters, at least one letter, one number and one special character",
}

export const REGEX = {
    NAME: /^[a-z0-9]+$/gi,
    EMAIL: /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/,
    PHONE: /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/,
    CARD_NUMBER: /^(?:4[0-9]{12}(?:[0-9]{3})?|[25][1-7][0-9]{14}|6(?:011|5[0-9][0-9])[0-9]{12}|3[47][0-9]{13}|3(?:0[0-5]|[68][0-9])[0-9]{11}|(?:2131|1800|35\d{3})\d{11})$/
}


/*
*  Minimum Error Bounds
* */
export const minBounds = {
    USERNAME: 3,
    PHONE: 10,
    PASSWORD: 8,
    VERIFICATION_CODE: 5,
    CARDNUMBER: 16,
    CVV: 3,
}

/*
*  Patterns based Validations
* */
export const Patterns = {
    Name: /^[A-Za-z][A-Za-z\s]*$/,
    Username: /^[a-zA-Z0-9_-]{3,20}$/,
    Email: /\S+@\S+\.\S+/,
    // Password:  /^(?=.*?[A-Z])(?=.*?[a-z]).{8,}$/,
    Password: /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
    Contact: /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/,
    ZipCode: /^[0-9]+$/,
    CreditCard: /^(?:4[0-9]{12}(?:[0-9]{3})?|[25][1-7][0-9]{14}|6(?:011|5[0-9][0-9])[0-9]{12}|3[47][0-9]{13}|3(?:0[0-5]|[68][0-9])[0-9]{11}|(?:2131|1800|35\d{3})\d{11})$/,
    Percentage: /^([1-9][0-9]?|[0-9])(\.[0-9]+)?%$/
}

export const SPECIAL_CHARACTER_ARRAY = [
    "~", "`", "!", "@", "#", "$", "%", "^", "&", "*", "(", ")", "-", "_", "+", "=", "{", "}", "[", "]", ";", ":", "'", '"', "\\", "|", "<", ",", ">", ".", "?", "/"
]

export const SPECIAL_CHARACTER_ARRAY_FOR_ADDRESS = [
    "~", "`", "!", "@", "#", "$", "%", "^", "&", "*", "(", ")", "_", "+", "=", "{", "}", "[", "]", ";", ":", "'", '"', "\\", "|", "<", ">", ".", "?", "/"
]

export const CHARACTER_ARRAY_FOR_NUMBER_INPUT = [
    "e", "E", "-", "+", "."
]
