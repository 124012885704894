import React from 'react'

const PaymentPlansFilterBar = () => {
    return (
        <div className="filter-bar">
            <div className="filter-bar__title">
                <h1 className='fw-bold'>Payment Plan</h1>
            </div>
        </div>
    )
}

export default PaymentPlansFilterBar