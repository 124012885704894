import { useState } from "react";

import {
  Form,
  Input,
  Radio,
  DatePicker,
  Select,
  Button,
  Space,
  Slider,
} from "antd";
import { Row, Col } from "react-bootstrap";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { NavLink, useNavigate } from "react-router-dom";
import {
  ArrowDown,
  BluePlusIcon,
  CameraIcon,
  Close,
  GreyCalendar,
  PlusIcon,
} from "../../constants/svg";
import { Assets } from "../../constants/images";
import EditFinancingForm from "../FinancingPlan/EditFinancingForm";
import AddAppointmentContent from "./AddAppointmentContent";
import ContentModal from "../ContentModal";
import CreatePaymentPlanContent from "./CreatePaymentPlanContent";
import CreateFinancingPlan from "./CreateFinancingPlan";

const { Option } = Select;

const TreatmentCreateContent = () => {
  const navigate = useNavigate();
  const [patientData, setPatientData] = useState({});
  const [contentModal, setContentModal] = useState(false);
  const [paymentModal, setPaymentModal] = useState(false);
  const [financeModal, setFinanceModal] = useState(false);
  const [profilePic, setProfilePic] = useState({
    file: null,
    file_url: null,
  });
  const [video, setVideo] = useState({
    file: null,
    file_url: null,
  });
  const onFinish = (values) => {
    setPatientData(values);
    console.log("Patient Data: ", values);
    navigate("/treatment-plans");
    toast.success("Treatment plan has been created!", {
      position: "bottom-right",
    });
  };

  const [selectedState, setselectedState] = useState("New York");
  const handleStateChange = (value) => {
    setselectedState(value);
  };
  const tostmessages = {
    success: {
      message: "Success",
    },
  };

  const handleCreatePayment = () => {
    setPaymentModal(true);
  };
  const handleCreateFinance = () => {
    setFinanceModal(true);
  };


  const onSubmit = (data) => {};
  return (
    <>
      <div className="stats-box">
        <Form
          onFinish={onFinish}
          initialValues={{
            treatmentplanname: "Treatment Plan 1",
            miniDownPayment: "16",
            patientplan: "plan 1",
            paymentplan: "plan 1",
            financeplan: "Plan 1",
            treatmentcost: "$200",
            discount: "$200",
            insurance: "$200",
            agreementname: "$200",
            durationTreatment: "$200",
            totalappointment: "$200",
            gender: "male",
            email: "joedoe@gmail.com",
            planDescription:
              "lorem ipsum dolor sit amet, lorem ipsum dolor sit amet, consectetur",

            patientName: "Paul Doe",
            height: "165",
            occupation: "Designer",
            weight: "65",
            phoneNumber: "+01-34885485",
            address: "Street 2, New york city",
            zip: "0000",
            city: "New York",
            state: "New York",
            country: "United States",
            fullName: "John Doe",
            mobile: "+01-34885485",
          }}
        >
          <Row>
            <Col xs={8}>
              <h3 className="fw-bold mb-3">Treatment Plan Details</h3>
              <Row>
                <Col lg={6} sm={12}>
                  <Form.Item
                    label="Treatment Plan Name"
                    name="treatmentplanname"
                    rules={[
                      {
                        required: false,
                        message: "Please input treatment plan name!",
                      },
                      {
                        type: "text",
                        message: "Please input valid treatment plane name.",
                      },
                    ]}
                    labelCol={{ span: 24 }}
                    wrapperCol={{ span: 24 }}
                  >
                    <Input />
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col lg={6} sm={12}>
                  <Form.Item
                    label="Duration"
                    labelCol={{ span: 24 }}
                    wrapperCol={{ span: 24 }}
                  >
                    <Space.Compact compact="true" className="border-0">
                      <div className="sliderValueBar">
                        <p className="minValue"></p>
                        <p className="maxValue">48</p>
                      </div>
                      <Form.Item
                        name="miniDownPayment"
                        rules={[
                          {
                            required: true,
                            message: "Please input your minimum down payment",
                          },
                        ]}
                      >
                        <Slider
                          min={0}
                          max={48}
                          tooltip={{
                            open: true,
                            formatter: (value) => `${value} Months`,
                            placement: "bottom",
                            getPopupContainer: () =>
                              document.querySelector(".modal-body"),
                          }}
                        />
                      </Form.Item>
                    </Space.Compact>
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col lg={6} sm={12}></Col>
              </Row>
              <Row>
                <Col lg={6} sm={12}>
                  <Form.Item
                    label="Description"
                    name="planDescription"
                    labelCol={{ span: 24 }}
                    wrapperCol={{ span: 24 }}
                    rules={[
                      {
                        required: false,
                        message: "Please input your Description!",
                      },
                    ]}
                  >
                    <Input.TextArea rows={4} cols={4} />
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col lg={6} sm={12} className="custom-mb-20">
                  <label htmlFor="eimg-upload" className="file-upload-label">
                    Treatment Image
                  </label>
                  <div className="file-upload-container">
                    <input
                      type="file"
                      accept=".png, .jpg, .jpeg"
                      id="img-upload" // add id here
                      name="avatar"
                      className="file-upload-input"
                      onChange={(e) =>
                        setProfilePic({
                          file: e.target.files[0],
                          file_url: URL.createObjectURL(e.target.files[0]),
                        })
                      }
                    />
                    <label
                      htmlFor="img-upload"
                      className="file-upload-button"
                      onClick={(e) => {
                        e.stopPropagation(); // prevent click event from bubbling up to the label
                      }}
                    ></label>
                    <div
                      className="upload-img-div"
                      onClick={() => {
                        document.getElementById("img-upload").click();
                      }}
                    >
                      <img
                        src={
                          profilePic.file_url
                            ? profilePic.file_url
                            : Assets.uploadImage
                        }
                        alt=""
                        className="img-upload"
                      />
                    </div>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col lg={6} sm={12} className="custom-mb-20">
                  <label htmlFor="video-upload" className="file-upload-label">
                    Treatment Video
                  </label>
                  <div className="file-upload-container">
                    <input
                      type="file"
                      accept=".mp4, .webm, .ogg"
                      id="video-upload"
                      name="video"
                      className="file-upload-input"
                      onChange={(e) =>
                        setVideo({
                          file: e.target.files[0],
                          file_url: URL.createObjectURL(e.target.files[0]),
                        })
                      }
                    />
                    <label
                      htmlFor="video-upload"
                      className="file-upload-button"
                      onClick={(e) => {
                        e.stopPropagation();
                      }}
                    ></label>
                    <div
                      className="upload-img-div"
                      onClick={() => {
                        document.getElementById("video-upload").click();
                      }}
                    >
                      {video.file_url ? (
                        <video
                          src={video.file_url}
                          className="img-upload"
                          controls
                        />
                      ) : (
                        <img
                          src={Assets.uploadVideo}
                          className="img-upload"
                          alt="placeholder"
                        />
                      )}
                    </div>
                  </div>
                </Col>
              </Row>

              <h3 className="fw-bold mb-3">Cost of Services</h3>
              <Button
                type="primary"
                className="border-btn custom-mb-20"
                icon={<BluePlusIcon />}
                shape="round"
                size="large"
                onClick={() => setContentModal(true)}
              >
                <span className="ms-2 ">Add Appointment</span>
              </Button>

              <div className="custom-form-btn">
                <h3 className="fw-bold mb-0">Payment Plan</h3>
                <div className="addNewBtn" onClick={handleCreatePayment}>
                  <PlusIcon /> <p className="m-0 blueColor">Create New</p>
                </div>
              </div>

              <div className="custom-form-btn">
                <h3 className="fw-bold mb-0">Finance Plan</h3>
                <div className="addNewBtn" onClick={handleCreateFinance}>
                  <PlusIcon /> <p className="m-0 blueColor">Create New</p>
                </div>
              </div>

              <Row>
                <Col lg={12} sm={12}>
                  <Form.Item
                    label="Reasons you may need the proposed treatment"
                    name="planDescription"
                    labelCol={{ span: 24 }}
                    wrapperCol={{ span: 24 }}
                    rules={[
                      {
                        required: false,
                        message: "Please input your Description!",
                      },
                    ]}
                  >
                    <Input.TextArea rows={4} cols={4} />
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col lg={12} sm={12}>
                  <Form.Item
                    label="Findings and Diagnosis"
                    name="planDescription"
                    labelCol={{ span: 24 }}
                    wrapperCol={{ span: 24 }}
                    rules={[
                      {
                        required: false,
                        message: "Please input your Description!",
                      },
                    ]}
                  >
                    <Input.TextArea rows={4} cols={4} />
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col lg={12} sm={12}>
                  <Form.Item
                    label="Pre-op and Post-op instructions"
                    name="planDescription"
                    labelCol={{ span: 24 }}
                    wrapperCol={{ span: 24 }}
                    rules={[
                      {
                        required: false,
                        message: "Please input your Description!",
                      },
                    ]}
                  >
                    <Input.TextArea rows={4} cols={4} />
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col lg={12} sm={12}>
                  <Form.Item
                    label="What to expect the day of procedure"
                    name="planDescription"
                    labelCol={{ span: 24 }}
                    wrapperCol={{ span: 24 }}
                    rules={[
                      {
                        required: false,
                        message: "Please input your Description!",
                      },
                    ]}
                  >
                    <Input.TextArea rows={4} cols={4} />
                  </Form.Item>
                </Col>
              </Row>
            </Col>

            <div className="form-btn-div">
              <Form.Item className="d-flex justify-content-end">
                <Button
                  type="primary"
                  htmlType="submit"
                  shape="round"
                  size="large"
                >
                  Create
                </Button>
              </Form.Item>
            </div>
          </Row>
        </Form>
        <ToastContainer />
        <ContentModal
          title={"Add Appointment"}
          content={<AddAppointmentContent setContentModal={setContentModal} />}
          blueText={"Save"}
          show={contentModal}
          onHide={() => setContentModal(false)}
        />
        <ContentModal
          title={"Create Payment Plan"}
          content={
            <CreatePaymentPlanContent setPaymentModal={setPaymentModal} />
          }
          blueText={"Create"}
          show={paymentModal}
          onHide={() => setPaymentModal(false)}
        />
        <ContentModal
          title={"Create Financing Plan"}
          content={
            <CreateFinancingPlan setPaymentModal={setFinanceModal} />
          }
          blueText={"Create"}
          show={financeModal}
          onHide={() => setFinanceModal(false)}
        />
      </div>
    </>
  );
};

export default TreatmentCreateContent;
