import React, { useState } from 'react'
import { Tabs } from 'antd';
import DiscountOfferFilterbar from '../../components/DiscountOffers/DiscountOfferFilterbar';
import AllDiscountTable from '../../components/DiscountOffers/AllDiscount/AllDiscountTable';
import HistoryDiscountTable from '../../components/DiscountOffers/HistoryDiscount/HistoryDiscountTable';
import AvailedDiscountTable from '../../components/DiscountOffers/AvailedDiscount/AvailedDiscountTable';
const items = [
    {
        key: '1',
        label: `All`,
        children: <AllDiscountTable />,
    },
    {
        key: '2',
        label: `History`,
        children: <HistoryDiscountTable />,
    },
    {
        key: '3',
        label: `Availed`,
        children: <AvailedDiscountTable />,
    }
];

const DiscountOfferPage = () => {
    const [activeKey, setActiveKey] = useState('1');

    const onChange = (key) => {
        setActiveKey(key);
    };
    return (
        <>
            <DiscountOfferFilterbar activeKey={activeKey} />
            <Tabs defaultActiveKey="1" items={items} onChange={onChange} />
        </>
    )
}

export default DiscountOfferPage