import React from 'react';
import { NavLink } from 'react-router-dom';
import { Table } from 'antd';

const columns = [
    {
        title: 'Careprovider',
        dataIndex: 'careprovider',
        render: (text, record) => <NavLink to={`/careproviders/${record.id}`} className='decoration-underline'>{text}</NavLink>,
    },
    {
        title: 'Procedure',
        dataIndex: 'procedure',
        sorter: (a, b) => a.procedure.localeCompare(b.procedure),
    },
    {
        title: 'CDT code',
        dataIndex: 'cdtCode',
        sorter: (a, b) => a.cdtCode.localeCompare(b.cdtCode),
    },
    {
        title: 'CDT Name',
        dataIndex: 'cdtName',
        sorter: (a, b) => a.cdtName.localeCompare(b.cdtName),
    },
    {
        title: 'Date',
        dataIndex: 'date',
        sorter: (a, b) => a.date.localeCompare(b.date),
    },
    {
        title: 'Time',
        dataIndex: 'time',
        sorter: (a, b) => a.time.localeCompare(b.time),
    },
];

const data = [
    {
        id: 1,
        careprovider: 'John Doe',
        procedure: 'Root Canal',
        cdtCode: 'D3330',
        cdtName: 'Endodontic therapy',
        date: '30/03/2023',
        time: '10:30 AM',
    },
    {
        id: 2,
        careprovider: 'Jane Smith',
        procedure: 'Filling',
        cdtCode: 'D2330',
        cdtName: 'Resin-based composite',
        date: '31/03/2023',
        time: '02:00 PM',
    },
    {
        id: 3,
        careprovider: 'Bob Johnson',
        procedure: 'Extraction',
        cdtCode: 'D7140',
        cdtName: 'Tooth removal',
        date: '01/04/2023',
        time: '11:15 AM',
    },
    {
        id: 4,
        careprovider: 'Mary Lee',
        procedure: 'Cleaning',
        cdtCode: 'D1110',
        cdtName: 'Prophylaxis',
        date: '02/04/2023',
        time: '09:45 AM',
    },
];


const AppointmentTable = () => {
    return (
        <>
            <div className="stats-box p-0 ps-4 pe-4">
                <Table
                    columns={columns}
                    dataSource={data}
                    pagination={false}
                    rowSelection={{
                        type: 'checkbox',
                    }}
                    scroll={{ x: 'max-content' }}
                    responsive={true}
                    size={'large'}
                    className={"p-0"}
                />
            </div>
        </>
    )
};

export default AppointmentTable;
