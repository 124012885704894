import React, { useState } from "react";
import {
  Form,
  Input,
  Slider,
  Button,
  Space,
  Switch,
  Radio,
  Select,
} from "antd";
import { ArrowDown, BluePlusIcon, Close, PlusIcon } from "../../constants/svg";
import { useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";

const CreateFinancingPlan = () => {
  const navigate = useNavigate();
  const [value, setValue] = useState(1);
  const [contentModal, setContentModal] = useState(false);
  const onChange = (e) => {
    console.log("radio checked", e.target.value);
    setValue(e.target.value);
  };

  const onFinish = (values) => {
    navigate("/treatment-plans");
    toast.success("Appointment has been added!", {
      position: "bottom-right",
    });
  };
  const [selectedState, setselectedState] = useState("New York");
  const handleStateChange = (value) => {
    setselectedState(value);
  };
  return (
    <Form
      onFinish={onFinish}
      initialValues={{
        financeName: "Finance Plan 1",
        planDescription:
          "lorem ipsum dolor sit amet, lorem ipsum dolor sit amet, consectetur",
        downPayment: "28",
        interestRate: "28",
        paymentschedule: "adhoc",
        discounteligibility: "28",
        processingfee: "28",
        extendedfinancing: "28",
      }}
    >
      <Form.Item
        label="Finance Name"
        name="financeName"
        labelCol={{ span: 24 }}
        wrapperCol={{ span: 24 }}
        rules={[
          {
            required: true,
            message: "Please input finance name!",
          },
          {
            type: "text",
            message: "Please input valid finance name.",
          },
        ]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        label="Description"
        name="planDescription"
        labelCol={{ span: 24 }}
        wrapperCol={{ span: 24 }}
        rules={[
          {
            required: true,
            message: "Please input your Description!",
          },
        ]}
      >
        <Input.TextArea rows={4} cols={4} />
      </Form.Item>
      <Form.Item
        label="Minimum Down Payment"
        labelCol={{ span: 24 }}
        wrapperCol={{ span: 24 }}
      >
        <Space.Compact compact="true" className="border-0">
          <div className="sliderValueBar">
            <p className="minValue"></p>
            <p className="maxValue">100%</p>
          </div>
          <Form.Item
            name="downPayment"
            rules={[
              {
                required: true,
                message: "Please input your minimum down payment",
              },
            ]}
          >
            <Slider
              min={0}
              max={48}
              tooltip={{
                open: true,
                formatter: (value) => `${value} %`,
                placement: "bottom",
                getPopupContainer: () => document.querySelector(".modal-body"),
              }}
            />
          </Form.Item>
        </Space.Compact>
      </Form.Item>
      <Form.Item
        label="Interest Rate"
        labelCol={{ span: 24 }}
        wrapperCol={{ span: 24 }}
      >
        <Space.Compact compact="true" className="border-0">
          <div className="sliderValueBar">
            <p className="minValue"></p>
            <p className="maxValue">100%</p>
          </div>
          <Form.Item
            name="interestRate"
            rules={[
              {
                required: true,
                message: "Please input your minimum interest rate",
              },
            ]}
          >
            <Slider
              min={0}
              max={48}
              tooltip={{
                open: true,
                formatter: (value) => `${value} %`,
                placement: "bottom",
                getPopupContainer: () => document.querySelector(".modal-body"),
              }}
            />
          </Form.Item>
        </Space.Compact>
      </Form.Item>
      <Form.Item
        label="Interest Rate Adjustment"
        name="interestRateAdjustment"
        labelCol={{ span: 24 }}
        wrapperCol={{ span: 24 }}
      >
        Off &nbsp; <Switch defaultChecked={true} size={true} /> &nbsp; On
      </Form.Item>
      <Form.Item
        label="Discount Eligibility"
        labelCol={{ span: 24 }}
        wrapperCol={{ span: 24 }}
      >
        <Space.Compact compact="true" className="border-0">
          <div className="sliderValueBar">
            <p className="minValue"></p>
            <p className="maxValue">100%</p>
          </div>
          <Form.Item
            name="discounteligibility"
            rules={[
              {
                required: true,
                message: "Please input your minimum discount eligibility",
              },
            ]}
          >
            <Slider
              min={0}
              max={48}
              tooltip={{
                open: true,
                formatter: (value) => `${value} %`,
                placement: "bottom",
                getPopupContainer: () => document.querySelector(".modal-body"),
              }}
            />
          </Form.Item>
        </Space.Compact>
      </Form.Item>

      <Form.Item
        label="Calculation of payment schedule"
        name="paymentschedule"
        rules={[
          {
            required: true,
            message: "Please select payment schedule",
          },
        ]}
        labelCol={{ span: 24 }}
        wrapperCol={{ span: 24 }}
      >
        <Radio.Group>
          <Radio value="monthly">Monthly</Radio>
          <Radio value="adhoc">Adhoc</Radio>
        </Radio.Group>
      </Form.Item>

      <Form.Item
        label="Late Payment Fees"
        name="latePaymentFees"
        labelCol={{ span: 24 }}
        wrapperCol={{ span: 24 }}
        rules={[
          { required: true, message: "Please input your late payment fees!" },
        ]}
        className="addOnLabel"
      >
        <Input
          addonAfter={
            <span style={{ fontSize: 16, fontWeight: "bold" }}>$</span>
          }
        />
      </Form.Item>

      <Form.Item
        label="Insurance claim processing fees"
        labelCol={{ span: 24 }}
        wrapperCol={{ span: 24 }}
      >
        <Space.Compact compact="true" className="border-0">
          <div className="sliderValueBar">
            <p className="minValue"></p>
            <p className="maxValue">100%</p>
          </div>
          <Form.Item
            name="processingfee"
            rules={[
              {
                required: true,
                message: "Please input your minimum discount eligibility",
              },
            ]}
          >
            <Slider
              min={0}
              max={48}
              tooltip={{
                open: true,
                formatter: (value) => `${value} %`,
                placement: "bottom",
                getPopupContainer: () => document.querySelector(".modal-body"),
              }}
            />
          </Form.Item>
        </Space.Compact>
      </Form.Item>
      <Form.Item
        label="Extended Financing"
        labelCol={{ span: 24 }}
        wrapperCol={{ span: 24 }}
      >
        <Space.Compact compact="true" className="border-0">
          <div className="sliderValueBar">
            <p className="minValue"></p>
            <p className="maxValue">100%</p>
          </div>
          <Form.Item
            name="processingfee"
            rules={[
              {
                required: true,
                message: "Please input your minimum exteneded financing",
              },
            ]}
          >
            <Slider
              min={0}
              max={48}
              tooltip={{
                open: true,
                formatter: (value) => `${value} %`,
                placement: "bottom",
                getPopupContainer: () => document.querySelector(".modal-body"),
              }}
            />
          </Form.Item>
        </Space.Compact>
      </Form.Item>

      <Form.Item className="d-flex justify-content-end">
        <Button type="primary" htmlType="submit" shape="round" size="large">
          Create
        </Button>
      </Form.Item>
    </Form>
  );
};

export default CreateFinancingPlan;
