import React from 'react'
import PaymentPlanContent from '../../components/PaymentPlans/PaymentPlanContent'
import PaymentPlansFilterBar from '../../components/PaymentPlans/PaymentPlansFilterBar'

const PaymentPlanPage = () => {
  return (
    <>
      <PaymentPlansFilterBar />
      <PaymentPlanContent />
    </>
  )
}

export default PaymentPlanPage