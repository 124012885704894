import { React } from "react";
import { Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import { MainLogo, RoundedLeft } from "../../constants/svg";
import WomanImg from "../../assets/images/onboarding-img.png";
import { useLocation, useNavigate } from 'react-router-dom';
import parse from 'html-react-parser';

const NewComponent = (props) => {
  const location = useLocation();
  const navigate = useNavigate();
  function handleArrowClick() {
    navigate(-1);
  }
  return (
    <>
      <section className="authenticationLayout">
        <div className="wdth_1366 bg_color_img " style={{ backgroundImage: `url(${WomanImg})` }}>
          <div className="authLeftBox">
            <Row className="w-100">
              <Col md={8} xl={4} sm={8} lg={5}>
                <div className="logo-wrapper d-flex align-items-center">
                  {location.pathname === '/' ? null : <div className="roudnedBtn me-3" onClick={handleArrowClick}><RoundedLeft /></div>}
                  <div className="logo">
                    <Link to="/">
                      <MainLogo />
                    </Link>
                  </div>
                </div>
                <div className="main_form">
                  <h1>{props.heading}</h1>
                  <p>{parse(props.description)}</p>
                </div>
                <div>
                  {props.children}
                </div>
              </Col>
            </Row>
          </div>
        </div>
      </section>
    </>
  );
};

export default NewComponent;
