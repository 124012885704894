import React from 'react';
import { Table } from 'antd';
import { NoDataShow } from '../../../../constants/svg';

const PaymentScheduleTable = () => {
    const columns = [
        // Your table columns definition
    ];

    const data = []; // Your table data

    return (
        <div className="stats-box">
            <h5 className="fw-bold">Payment Schedule</h5>
            {data.length ? (
                <Table bordered={false} columns={columns} dataSource={data} />
            ) : (
                <div className="no-data-icon">
                    <NoDataShow />
                </div>
            )}
        </div>
    );
};

export default PaymentScheduleTable;
