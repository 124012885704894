import React, { useState } from "react";
import { Breadcrumb, Button, Drawer } from "antd";
import { NavLink } from "react-router-dom";
import { EditSquare, PlusIcon, CrossSquared } from "../../constants/svg";
import DetailCreateMembershipPlan from "./DetailCreateMembershipPlan/DetailCreateMembershipPlan";

const MembershipPlanDetailFilterPlan = (props) => {
  const [open, setOpen] = useState(false);
  const [placement, setPlacement] = useState('right');
  const showDrawer = () => {
    setOpen(true);
  };
  const onClose = () => {
    setOpen(false);
  };
  return (
    <>
      <div className="d-flex justify-content-between filter-bar">
        <div className="title-detail">
          <Breadcrumb
            items={[
              {
                title: <NavLink to="/membership-plan">Membership Plan</NavLink>,
              },
              {
                title: "Dental Plan",
              },
            ]}
          />
          <h1 className="fw-bold">Dental Plan</h1>
        </div>
        <Button
          type="primary"
          icon={<PlusIcon />}
          shape="round"
          size="large"
          onClick={showDrawer}
        >
          <span className="ms-2">Create New</span>
        </Button>
      </div>
      <Drawer
        title={<h1 className='fw-bold'>Create New Plan</h1>}
        placement={placement}
        size={"large"}
        onClose={onClose}
        closeIcon={<CrossSquared />}
        open={open}
      >
        <DetailCreateMembershipPlan setOpen={setOpen} />
      </Drawer>
    </>
  );
};

export default MembershipPlanDetailFilterPlan;
