import React, { useState } from 'react';
import dayjs from 'dayjs';
import { Form, Select, Input, DatePicker, Button } from 'antd';
import { ArrowDown, Close, GreyCalendar } from '../../../../constants/svg';
import { Row, Col } from 'react-bootstrap';
import 'dayjs/locale/en';

const { Option } = Select;
const dateFormat = 'DD/MM/YY';

const BillingHistoryForm = () => {
    const options = [
        { label: 'INV-2022-001', value: 'INV-2022-001' },
        { label: 'INV-2022-002', value: 'INV-2022-002' },
        { label: 'INV-2022-003', value: 'INV-2022-003' },
    ];

    const transactionTypeOptions = [
        { label: 'Type 1', value: 'type1' },
        { label: 'Type 2', value: 'type2' },
        { label: 'Type 3', value: 'type3' },
    ];

    const [selectedOption, setSelectedOption] = useState('INV-2022-002');
    const [transactionId, setTransactionId] = useState('');
    const [selectedTransactionType, setSelectedTransactionType] = useState('Credit Card');

    const onFinish = (values) => {
        console.log('Form values:', values);
    };

    return (
        <div className="stats-box">
            <Form
                initialValues={{
                    transactionId: '234',
                    fromDate: dayjs('21/05/22', dateFormat),
                    toDate: dayjs('21/05/22', dateFormat),
                    transactionType: 'type1',
                }}
                onFinish={onFinish}
            >
                <Row>
                    <Col xs={12} md={4} >
                        <Form.Item
                            name="invoiceNumber"
                            label="Invoice Number"
                            rules={[{ required: false, message: 'Please select the invoice number' }]}
                            labelCol={{ span: 24 }}
                            wrapperCol={{ span: 24 }}
                        >
                            <Select
                                value={selectedOption}
                                defaultValue={selectedOption}
                                onChange={(value) => setSelectedOption(value)}
                                placeholder="Select Invoice Number"
                                suffixIcon={<ArrowDown />}
                                removeIcon={<Close />}
                                className={"form-dropdown"}
                            >
                                {options.map((option) => (
                                    <Option key={option.value} value={option.value}>
                                        {option.label}
                                    </Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col xs={12} md={4} >
                        <Form.Item
                            name="trasactionType"
                            label="Transaction Type"
                            rules={[{ required: false, message: 'Please select the transaction type' }]}
                            labelCol={{ span: 24 }}
                            wrapperCol={{ span: 24 }}
                        >
                            <Select
                                value={selectedTransactionType}
                                defaultValue={selectedTransactionType}
                                onChange={(value) => setSelectedTransactionType(value)}
                                placeholder="Select transaction type"
                                suffixIcon={<ArrowDown />}
                                removeIcon={<Close />}
                                className={"form-dropdown"}
                            >
                                {transactionTypeOptions.map((option) => (
                                    <Option key={option.value} value={option.value}>
                                        {option.label}
                                    </Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col xs={12} md={4} >
                        <Form.Item
                            name="transactionId"
                            label="Transaction ID"
                            rules={[
                                { required: false, message: 'Please input transaction ID' },
                                { pattern: /^\d+$/, message: 'Transaction ID must be a number' },
                            ]}
                            labelCol={{ span: 24 }}
                            wrapperCol={{ span: 24 }}
                        >
                            <Input
                                value={transactionId}
                                onChange={(event) => setTransactionId(event.target.value)}
                                placeholder="Transaction ID"
                            />
                        </Form.Item>
                    </Col>
                    <Col xs={12} md={4} >
                        <Form.Item
                            label="From"
                            name="fromDate"
                            labelCol={{ span: 24 }}
                            wrapperCol={{ span: 24 }}
                            rules={[
                                {
                                    required: false,
                                    message: "Please select date",
                                },
                            ]}
                        >
                            <DatePicker
                                format={dateFormat}
                                disabledDate={(current) => current && current < dayjs('1900-01-01')}
                                suffixIcon={<GreyCalendar />}
                                iconRender={(current) => (current ? <GreyCalendar /> : '')}
                            />
                        </Form.Item>
                    </Col>
                    <Col xs={12} md={4} >
                        <Form.Item
                            label="To"
                            name="toDate"
                            labelCol={{ span: 24 }}
                            wrapperCol={{ span: 24 }}
                            rules={[
                                {
                                    required: false,
                                    message: "Please select date",
                                },
                            ]}
                        >
                            <DatePicker
                                format={dateFormat}
                                disabledDate={(current) => current && current < dayjs('1900-01-01')}
                                suffixIcon={<GreyCalendar />}
                                iconRender={(current) => (current ? <GreyCalendar /> : '')}
                            />
                        </Form.Item>
                    </Col>
                    <Col xs={12} md={4} >
                        <Form.Item className='d-flex justify-content-start align-items-center h-100'>
                            <Button type="primary" htmlType="submit" shape="round" size='medium'>
                                View
                            </Button>
                        </Form.Item>
                    </Col>

                </Row>


            </Form>
        </div>


    )
}

export default BillingHistoryForm