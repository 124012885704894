import React, { useState } from 'react';
import { Avatar, Drawer } from 'antd';
import { Row, Col } from 'react-bootstrap';
import { Envelope, Loc, Telephone, BlueUser, EditSquare, CrossSquared } from '../../../constants/svg';
import EditInfoPatientForm from '../EditInfoPatientForm';

const PatientInfoDetailLarge = (props) => {
    const [open, setOpen] = useState(false);
    const [placement, setPlacement] = useState('right');
    const showDrawer = () => {
        setOpen(true);
    };
    const onClose = () => {
        setOpen(false);
    };
    return (
        <>
            <div className="patient-wrap stats-box position-relative">
                <Row>
                    <Col lg={12}>
                        <Row>
                            <Col lg={2}>
                                <Avatar src={props.ImgSrc} size={{ xs: 120, sm: 110, md: 100, lg: 100, xl: 120, xxl: 180 }} />
                            </Col>
                            <Col lg={5}>
                                <Row>
                                    <Col lg={6}>
                                        <div className="patient-detail-box">
                                            <h3 className='name mb-2'>Peter Paul</h3>
                                            <p className='detail-heading'>Personal Details</p>
                                            <ul className="patient-detail-list">
                                                <li className="patient-item">
                                                    <span className="patient-icon"><Envelope /></span>
                                                    <span className="patient-item-value">test@gmail.com</span>
                                                </li>
                                                <li className="patient-item">
                                                    <span className="patient-icon"><Telephone /></span>
                                                    <span className="patient-item-value">+912656348455</span>
                                                </li>
                                                <li className="patient-item">
                                                    <span className="patient-icon"><Loc /></span>
                                                    <span className="patient-item-value">abc uigdhye</span>
                                                </li>
                                            </ul>
                                        </div>
                                    </Col>
                                    <Col lg={6}>
                                        <div className="patient-detail-box">
                                            <h3 className='name mb-2'>&nbsp;</h3>
                                            <p className='detail-heading'>Emergency Contact</p>
                                            <ul className="patient-detail-list">
                                                <li className="patient-item">
                                                    <span className="patient-item-value">Ashish</span>
                                                </li>
                                                <li className="patient-item">
                                                    <span className="patient-item-value">1 (900) 454-4695</span>
                                                </li>
                                            </ul>
                                        </div>
                                    </Col>
                                </Row>

                            </Col>
                        </Row>
                    </Col>
                </Row>
                <div className="square-edit-btn" onClick={showDrawer}>
                    <EditSquare />
                </div>
            </div>
            <Drawer
                title={<h1 className='fw-bold'>Edit Patient</h1>}
                placement={placement}
                size={"large"}
                onClose={onClose}
                closeIcon={<CrossSquared />}
                open={open}
            >
                <EditInfoPatientForm />
            </Drawer>
        </>

    )
}

export default PatientInfoDetailLarge