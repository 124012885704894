import React from 'react'
import MembershipPlanContent from '../../components/MemebershipPlan/MembershipPlanContent'
import MembershipPlanFilterbar from '../../components/MemebershipPlan/MembershipPlanFilterbar'

const MembershipPlanPage = () => {
  return (
    <>
      <MembershipPlanFilterbar />
      <MembershipPlanContent />
    </>
  )
}

export default MembershipPlanPage