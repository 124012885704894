import { React, useState } from "react";
import { Row, Col } from "react-bootstrap";

import { Table, Avatar, Badge, Button, Tag, Dropdown } from "antd";
import { Assets } from "../../constants/images";
import { UserIcon } from "../../constants/svg";
import { NavLink } from "react-router-dom";
import "dayjs/locale/en";
import ContentModal from "../../components/ContentModal";

import RequestDetail from "../../components/Leads/RequestDetail";
import RejectRequestModal from "../../components/Leads/RejectRequestModal";
import ThemeModal from "../../components/ThemeModal";

const LeadsContent = () => {
  const [contentModal, setContentModal] = useState();
  const [selectedRow, setSelectedRow] = useState(null);
  const [showdelete, setShowDelete] = useState(false);
  const [rejectModal, setRejectModal] = useState(false);

  console.log(selectedRow);

  const items =
    selectedRow && selectedRow.proposalStatus[0] === "Accepted"
      ? [
        {
          key: "1",
          label: (
            <div>
              <NavLink to="/marketing/create-proposal" className="m-0 black">
                Send a Proposal
              </NavLink>
            </div>
          ),
        },
        {
          key: "2",
          label: (<p className='m-0 black' onClick={() => setShowDelete(true)}>
            Delete
          </p>),
        },
      ]
      : [
        {
          key: "2",
          label: (<p className='m-0' onClick={() => setShowDelete(true)}>
            Delete
          </p>),
        },
      ];
  const columns = [
    {
      title: null,
      width: 70,
      dataIndex: "avatar",
      key: "avatar",
      fixed: "left",
      align: "center",
      render: (avatarUrl) => <Avatar src={avatarUrl} />,
    },
    {
      title: "Name",
      width: 150,
      dataIndex: "clientName",
      key: "clientName",
      render: (text, record) => (
        <div
          className="decoration-underline blueColor cursor-pointer"
          onClick={() => {
            setContentModal(true);
            setSelectedRow(record);
          }}
        >
          {record.clientName}
        </div>
      ),
    },
    {
      title: "Contact no",
      dataIndex: "contactNo",
      key: "contactNo",
      width: 200,
      sorter: (a, b) => a.contactNo.localeCompare(b.contactNo),
      render: (text, record) => <div>{record.contactNo}</div>,
    },
    {
      title: "Email ID",
      dataIndex: "email",
      key: "email",
      width: 250,
      sorter: (a, b) => a.email.localeCompare(b.email),
    },
    {
      title: "Inquiry",
      dataIndex: "inquiry",
      key: "inquiry",
      width: 250,
      sorter: (a, b) => a.inquiry.localeCompare(b.inquiry),
    },

    {
      title: "Proposal Status",
      dataIndex: "proposalStatus",
      key: "proposalStatus",
      width: 150,
      sorter: (a, b) => a.proposalStatus.localeCompare(b.proposalStatus),

      render: (proposalStatus) => (
        <span>
          {proposalStatus.map((tag) => {
            let color =
              tag.toLowerCase() === "accepted"
                ? "green"
                : tag.toLowerCase() === "pending"
                  ? "yellow"
                  : tag.toLowerCase() === "rejected"
                    ? "red"
                    : "";

            return (
              <Tag
                color={color}
                key={tag}
                className="mb-2 text-capitalize fw-bold"
              >
                {tag}
              </Tag>
            );
          })}
        </span>
      ),
    },

    {
      title: "Action",
      key: "operation",
      fixed: "right",
      width: 90,
      align: "center",
      render: (record) => (
        <Dropdown
          menu={{
            items,
          }}
          trigger={["click"]}
          placement="bottomRight"
        >
          <Button
            className="dotted-btn"
            onClick={() => {
              setSelectedRow(record);
            }}
          >
            <UserIcon />
          </Button>
        </Dropdown>
      ),
    },
    ,
  ];

  const data = [
    {
      key: "1",
      avatar: Assets.PatientImg,
      clientName: "Paul Doe",
      contactNo: "91 (900) 454-4695",
      email: "ashdhenge2503@gmail.com",
      inquiry: "Lorem ipsum dolor sit amet consectetur adipiscing elit",
      proposalStatus: ["Accepted"],
      reason:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
    },
    {
      key: "2",
      avatar: Assets.PatientImg,
      clientName: "Ashneer",
      contactNo: "91 (900) 454-4695",
      email: "johndoe2503@gmail.com",
      inquiry: "Lorem ipsum dolor sit  adipiscing elit",
      proposalStatus: ["Pending"],
      reason:
        "Lorem ipsum dolor sit amet, consectetur  ipsum dolor sit amet, consectetur adipiscing elit.",
    },
    {
      key: "3",
      avatar: Assets.PatientImg,
      clientName: "John",
      contactNo: "91 (900) 454-4695",
      email: "ashishdhenge2503@gmail.com",
      inquiry: "Lorem ipsum dolor sit amet consectetur elit",
      proposalStatus: ["Rejected"],
      reason:
        "Lorem ipsum consectetur adipiscing elit. Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
    },
    {
      key: "4",
      avatar: Assets.PatientImg,
      clientName: "Thomas Doe",
      contactNo: "91 (900) 454-4695",
      email: "ashishdhenge2503@gmail.com",
      inquiry: "Lorem ipsum dolor sit amet consectetur adipiscing elit",
      proposalStatus: ["Accepted"],
      reason:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
    },
  ];

  return (
    <>
      <Row>
        <Col md={12}>
          {/* <div className="stats-box">
            <div className="badges-list">
              <div className="badges-item">
                <Badge color={"orange"} text={"Created (1)"} />
              </div>
              <div className="badges-item">
                <Badge color={"blue"} text={"Sent (5)"} />
              </div>
              <div className="badges-item">
                <Badge color={"magenta"} text={"Seen (5)"} />
              </div>
              <div className="badges-item">
                <Badge color={"geekblue"} text={"Sales Rep Conneected (5)"} />
              </div>
              <div className="badges-item">
                <Badge color={"green"} text={"Lead Converted (5)"} />
              </div>
              <div className="badges-item">
                <Badge color={"cyan"} text={"Signed (5)"} />
              </div>
              <div className="badges-item">
                <Badge color={"purple"} text={"Accepted (5)"} />
              </div>
            </div>
          </div> */}
          <Table
            rowSelection={{
              type: "checkbox",
            }}
            columns={columns}
            dataSource={data}
            scroll={false}
            bordered={false}
            pagination={{ pageSize: 10 }}
          />
        </Col>
        <ThemeModal
          className="alertModal"
          title={"Alert"}
          content={"Are you sure you want to delete this plan?"}
          blueText={"Delete"}
          cancelText={"Cancel"}
          setShowDelete={setShowDelete}
          show={showdelete}
          onHide={() => setShowDelete(false)}
        />
        <ContentModal
          title={"Request Details"}
          content={
            <RequestDetail
              setContentModal={setContentModal}
              selectedRow={selectedRow}
              setRejectModal={setRejectModal}
            />
          }
          blueText={"Send"}
          show={contentModal}
          onHide={() => setContentModal(false)}
        />
        <ContentModal
          title={"Reason of Rejection"}
          content={<RejectRequestModal setContentModal={setRejectModal} />}
          blueText={"Send"}
          show={rejectModal}
          onHide={() => setRejectModal(false)}
        />
      </Row>
    </>
  );
};

export default LeadsContent;
