import React, { useState } from 'react';
import { Row, Col } from 'react-bootstrap';
import { GridViewIcon, ListViewIcon } from '../../constants/svg';
import { Switch } from 'antd';
import PatientInfoCard from './PatientInfoCard';
import { Assets } from '../../constants/images';

const PatientContent = () => {
    const [active, setActive] = useState(false);
    const [listView, setListView] = useState(false);

    const handleClick = () => {
        setActive(!active);
        setListView(!listView);
    };
    const onChange = (checked) => {
        console.log(`switch to ${checked}`);
    };

    const PatientCardData = [
        {
            id: 1,
            patientImg: Assets.PatientImg,
            patientName: "John Doe",
            amountRecieved: "$500",
            paymentRemaining: "$250",
            treatments: "Dental Cleaning",
            appointments: "3",
            contactNo: "123-456-7890",
            email: "john.doe@example.com",
            location: "123 Main St, Anytown, USA",
            link: "/patients/patient-detail"
        },
        {
            id: 2,
            patientImg: Assets.PatientImg,
            patientName: "Jane Smith",
            amountRecieved: "$750",
            paymentRemaining: "$0",
            treatments: "Braces",
            appointments: "6",
            contactNo: "456-789-1234",
            email: "jane.smith@example.com",
            location: "456 Elm St, Anytown, USA",
            link: "/patients/patient-detail"
        },
        {
            id: 3,
            patientImg: Assets.PatientImg,
            patientName: "Bob Johnson",
            amountRecieved: "$1000",
            paymentRemaining: "$500",
            treatments: "Root Canal",
            appointments: "2",
            contactNo: "789-123-4567",
            email: "bob.johnson@example.com",
            location: "789 Oak St, Anytown, USA",
            link: "/patients/patient-detail"
        },
        {
            id: 4,
            patientImg: Assets.PatientImg,
            patientName: "Sara Lee",
            amountRecieved: "$1250",
            paymentRemaining: "$250",
            treatments: "Teeth Whitening",
            appointments: "4",
            contactNo: "234-567-8901",
            email: "sara.lee@example.com",
            location: "234 Maple St, Anytown, USA",
            link: "/patients/patient-detail"
        },
        {
            id: 5,
            patientImg: Assets.PatientImg,
            patientName: "David Brown",
            amountRecieved: "$1500",
            paymentRemaining: "$1000",
            treatments: "Fillings",
            appointments: "1",
            contactNo: "567-890-1234",
            email: "david.brown@example.com",
            location: "567 Pine St, Anytown, USA",
            link: "/patients/patient-detail"
        },
        {
            id: 6,
            patientImg: Assets.PatientImg,
            patientName: "Emily Wilson",
            amountRecieved: "$2000",
            paymentRemaining: "$500",
            treatments: "Extraction",
            appointments: "5",
            contactNo: "890-123-4567",
            email: "emily.wilson@example.com",
            location: "890 Cedar St, Anytown, USA",
            link: "/patients/patient-detail"
        }
    ];

    return (
        <>
            <div className="stats-box">
                <Row className='justify-content-between'>
                    <Col lg={6} className="p-0">
                        <p className='m-0 d-flex align-items-center greyColor'>Show EzPlan Patients only <Switch defaultChecked onChange={onChange} className="ms-2" /></p>
                    </Col>
                    <Col lg={6} className="p-0">
                        <div className="view-iconlist">
                            <div className={`view-iconItem ${active ? '' : 'active'}`} onClick={handleClick}>
                                <GridViewIcon />
                            </div>

                            <div className={`view-iconItem ${active ? 'active' : ''}`} onClick={handleClick}>
                                <ListViewIcon />
                            </div>
                        </div>
                    </Col>
                </Row>
            </div>
            <Row gutter={[16, 16]}>
                {PatientCardData.map((data, index) => {
                    return (
                        <Col xs={12} md={6} lg={4} xl={4} className={` pe-2 ps-2 ${listView ? 'w-100 listView' : ''}`} key={index}>
                            <PatientInfoCard patientdata={data} />
                        </Col>
                    )
                })}
            </Row>
        </>
    )
}

export default PatientContent