import React, { useState } from 'react';
import { Row, Col } from 'react-bootstrap';
import { ArrowDown, GridViewIcon, ListViewIcon } from '../../constants/svg';
import { Form, Select, Input, Slider, Tooltip } from 'antd'
import PaymentCard from '../PaymentCard';

const { Option } = Select;

const locationOptions = ['New York', 'Los Angeles', 'Chicago', 'Houston'];
const providerOptions = ['Pharmacy A', 'Pharmacy B', 'Pharmacy C', 'Pharmacy D'];

const TreatmentPlanContent = () => {
    const [location, setLocation] = useState(null);
    const [provider, setProvider] = useState(null);
    const [active, setActive] = useState(false);
    const [listView, setListView] = useState(false);

    const handleClick = () => {
        setActive(!active);
        setListView(!listView);
    };

    const handleLocationChange = (value) => {
        setLocation(value);
        console.log(`Selected location: ${value}`);
    };

    const handleProviderChange = (value) => {
        setProvider(value);
        console.log(`Selected care provider: ${value}`);
    };
    const PaymentCardData = [
        {
            id: 1,
            planName: "Orthodontic Treatment",
            discription: "lorem ipsum dolor sit amet, lorem ipsum dolor sit amet, consectetur adipisicing elit sed do eiusmod tempor incididunt ut labore ",
            planDetail: [
                {
                    id: 1,
                    planField: "Payment Source:",
                    planFieldValue: "Third Party",
                },
                {
                    id: 2,
                    planField: "Amount Source:",
                    planFieldValue: "Treatment Plan",
                },
                {
                    id: 3,

                    planField: "Payment Mode:",
                    planFieldValue: "Automatic",
                },
                {
                    id: 4,
                    planField: " Additional Discount Eligibility:",
                    planFieldValue: "10%",
                },
            ]
        },
        {
            id: 2,
            planName: "Dental Plan",
            discription: "lorem ipsum dolor sit amet, lorem ipsum dolor sit amet, consectetur adipisicing elit sed do eiusmod tempor incididunt ut labore ",
            planDetail: [
                {
                    id: 1,
                    planField: "Payment Source:",
                    planFieldValue: "Third Party",
                },
                {
                    id: 2,
                    planField: "Amount Source:",
                    planFieldValue: "Treatment Plan",
                },
                {
                    id: 3,

                    planField: "Payment Mode:",
                    planFieldValue: "Automatic",
                },
                {
                    id: 4,
                    planField: " Additional Discount Eligibility:",
                    planFieldValue: "10%",
                },
            ]
        },
        {
            id: 3,
            planName: "ENT Plan",
            discription: "lorem ipsum dolor sit amet, lorem ipsum dolor sit amet, consectetur adipisicing elit sed do eiusmod tempor incididunt ut labore ",
            planDetail: [
                {
                    id: 1,
                    planField: "Payment Source:",
                    planFieldValue: "Third Party",
                },
                {
                    id: 2,
                    planField: "Amount Source:",
                    planFieldValue: "Treatment Plan",
                },
                {
                    id: 3,

                    planField: "Payment Mode:",
                    planFieldValue: "Automatic",
                },
                {
                    id: 4,
                    planField: " Additional Discount Eligibility:",
                    planFieldValue: "10%",
                },
            ]
        },
        {
            id: 4,
            planName: "Orthodontic Treatment",
            discription: "lorem ipsum dolor sit amet, lorem ipsum dolor sit amet, consectetur adipisicing elit sed do eiusmod tempor incididunt ut labore ",
            planDetail: [
                {
                    id: 1,
                    planField: "Payment Source:",
                    planFieldValue: "Third Party",
                },
                {
                    id: 2,
                    planField: "Amount Source:",
                    planFieldValue: "Treatment Plan",
                },
                {
                    id: 3,

                    planField: "Payment Mode:",
                    planFieldValue: "Automatic",
                },
                {
                    id: 4,
                    planField: " Additional Discount Eligibility:",
                    planFieldValue: "10%",
                },
            ]
        },
        {
            id: 5,
            planName: "Dental Plan",
            discription: "lorem ipsum dolor sit amet, lorem ipsum dolor sit amet, consectetur adipisicing elit sed do eiusmod tempor incididunt ut labore ",
            planDetail: [
                {
                    id: 1,
                    planField: "Payment Source:",
                    planFieldValue: "Third Party",
                },
                {
                    id: 2,
                    planField: "Amount Source:",
                    planFieldValue: "Treatment Plan",
                },
                {
                    id: 3,

                    planField: "Payment Mode:",
                    planFieldValue: "Automatic",
                },
                {
                    id: 4,
                    planField: " Additional Discount Eligibility:",
                    planFieldValue: "10%",
                },
            ]
        },
        {
            id: 6,
            planName: "ENT Plan",
            discription: "lorem ipsum dolor sit amet, lorem ipsum dolor sit amet, consectetur adipisicing elit sed do eiusmod tempor incididunt ut labore ",
            planDetail: [
                {
                    id: 1,
                    planField: "Payment Source:",
                    planFieldValue: "Third Party",
                },
                {
                    id: 2,
                    planField: "Amount Source:",
                    planFieldValue: "Treatment Plan",
                },
                {
                    id: 3,

                    planField: "Payment Mode:",
                    planFieldValue: "Automatic",
                },
                {
                    id: 4,
                    planField: " Additional Discount Eligibility:",
                    planFieldValue: "10%",
                },
            ]
        }
    ]
    return (
        <>
            <div className="stats-box">
                <Row>
                    <Col lg={4}>
                        <Form.Item
                            label="Location"
                            name="location"
                            rules={[
                                {
                                    required: false,
                                    message: "Please select location",
                                },
                            ]}
                            labelCol={{ span: 24 }}
                            wrapperCol={{ span: 24 }}
                            className="m-0"
                        >
                            <Select
                                placeholder="Select Location"
                                onChange={handleLocationChange}
                                value={location}
                                suffixIcon={<ArrowDown />}
                            >
                                {locationOptions.map((location) => (
                                    <Option key={location} value={location}>
                                        {location}
                                    </Option>
                                ))}
                            </Select>

                        </Form.Item>
                    </Col>
                    <Col lg={4}>
                        <Form.Item
                            label="Select Care Provider"
                            name="careProvider"
                            rules={[
                                {
                                    required: false,
                                    message: "Please select location",
                                },
                            ]}
                            labelCol={{ span: 24 }}
                            wrapperCol={{ span: 24 }}
                            className="m-0"
                        >
                            <Select
                                placeholder="Select Care Provider"
                                onChange={handleProviderChange}
                                value={provider}
                                suffixIcon={<ArrowDown />}
                            >
                                {providerOptions.map((provider) => (
                                    <Option key={provider} value={provider}>
                                        {provider}
                                    </Option>
                                ))}
                            </Select>

                        </Form.Item>
                    </Col>
                    <Col lg={4}>
                        <div className="view-iconlist">
                            <div className={`view-iconItem ${active ? '' : 'active'}`} onClick={handleClick}>
                                <GridViewIcon />
                            </div>

                            <div className={`view-iconItem ${active ? 'active' : ''}`} onClick={handleClick}>
                                <ListViewIcon />
                            </div>
                        </div>
                    </Col>
                </Row>
            </div>
            <Row gutter={[16, 16]}>
                {PaymentCardData.map((data, index) => {
                    return (
                        <Col xs={12} md={6} lg={4} xl={4} className={`mb-4 pe-2 ps-2 ${listView ? 'w-100 listView' : ''}`} key={index}>
                            <PaymentCard PaymentCatdData={data} incDropdown={false} />
                        </Col>
                    )
                })}
            </Row>
        </>
    )
}

export default TreatmentPlanContent